import React from 'react'
import { Field } from 'react-final-form'

import { RemoteMarkdown } from 'piconetworks/pkg-remote-markdown'
import Form from 'piconetworks/pkg-form'

const Markdown = ({
    fieldContainerProps,
    description = null,
    required,
    url = 'https://assets.hype.co/gadget/kitchen-sink.md',
    onClickLink = () => {},
    options = {},
    linkColor = '',
    ...rest
} = {}) => (
    <Form.Field
        {...fieldContainerProps}
    >
        <p>{description}</p>

        <RemoteMarkdown
            url={url}
            onClickLink={onClickLink}
            linkColor={linkColor}
            options={{
                breaks: true,
                ...options,
            }}
        />

        <Field
            component={Form.Input}
            type="hidden"
            {...(required && {
                validate: value => (value ? undefined : 'Required')
            })}
            {...rest}
        />
    </Form.Field>
)

export default Markdown
