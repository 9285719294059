import React from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'

import Form from 'piconetworks/pkg-form'

const Radio = ({
    fieldContainerProps,
    description = null,
    required,
    input,
    options = [],
    ...rest
} = {}) => {
    return (
        <Form.Field {...fieldContainerProps}>
            <p>{description}</p>
            {options.map(({ label }, idx) => {
                return (
                    <Field
                        key={idx}
                        component={Form.Radio}
                        type="radio"
                        text={label}
                        value={label}
                        {...(required && {
                            validate: (value) => (value ? undefined : 'Required')
                        })}
                        {...rest}
                    />
                )
            })}
        </Form.Field>
    )
}

Radio.propTypes = {
    fieldContainerProps: PropTypes.any,
    description: PropTypes.string,
    required: PropTypes.bool,
    previewMode: PropTypes.bool,
    input: PropTypes.any
}

export default Radio
