import React from 'react'
import { Field } from 'react-final-form'

import Form from 'piconetworks/pkg-form'

const CA = {
    name: 'Canada',
    Component: ({ name, required, ...rest }) => (
        <>
            <Form.Field
                label="Address Line 1"
                required={required}
            >
                <Field
                    name={`${name}.address1`}
                    component={Form.Input}
                    type="text"
                    {...(required && {
                        validate: value => (value ? undefined : 'Required')
                    })}
                    {...rest}
                />
            </Form.Field>
            <Form.Field
                label="Address Line 2"
            >
                <Field
                    name={`${name}.address2`}
                    component={Form.Input}
                    type="text"
                    {...rest}
                />
            </Form.Field>
            <Form.Field
                label="City"
                required={required}
            >
                <Field
                    name={`${name}.city`}
                    component={Form.Input}
                    type="text"
                    {...(required && {
                        validate: value => (value ? undefined : 'Required')
                    })}
                    {...rest}
                />
            </Form.Field>
            <Form.Field
                label="Province"
                required={required}
            >
                <Field
                    name={`${name}.province`}
                    component={Form.Input}
                    type="text"
                    {...(required && {
                        validate: value => (value ? undefined : 'Required')
                    })}
                    {...rest}
                />
            </Form.Field>
            <Form.Field
                label="Postal Code"
                required={required}
            >
                <Field
                    name={`${name}.post_code`}
                    component={Form.Input}
                    type="number"
                    {...(required && {
                        validate: value => (value ? undefined : 'Required')
                    })}
                    {...rest}
                />
            </Form.Field>
        </>
    ),
}

export default CA
