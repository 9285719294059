import React, { useEffect } from 'react'
import { Field, useForm } from 'react-final-form'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { priceText } from 'piconetworks/pkg-functions'

import { format } from 'piconetworks/pkg-currency'
import Form from 'piconetworks/pkg-form'
import { RemoteJsonText } from 'piconetworks/pkg-remote-markdown'
import style from './scss/SelectGroup.module.scss'

const SelectGroup = ({
    billingStatus,
    fieldContainerProps,
    required,
    name,
    linkColor = '',
    contrastColor = '',
    product = {},
    className,
    customFeaturesUrl = '',
    groupIsPaid,
    setCode,
    ...rest
}) => {
    const form = useForm()
    const conditions = product.conditions || []

    const codeCondition = conditions.filter((c) => c.type === 'code')
    const sizeCondition = conditions.filter((c) => c.type === 'size')

    const memberLimit = sizeCondition?.[0]?.value ?? null
    const hasCode = !!codeCondition.length

    const isPaid = groupIsPaid || billingStatus === 'PAID'

    const formattedOptions =
        (product.options || []).reduce(
            (
                selections,
                {
                    currency: currencyCode,
                    id: value,
                    interval,
                    per_seat: perSeat,
                    price: amount,
                    recurring
                }
            ) => {

                const price = priceText(format({
                    currencyCode,
                    amount,
                    symbol: false
                }), currencyCode)

                let text = price
                if (perSeat) {
                    text += ' / member'
                }
                if (recurring) {
                    text += ` / ${interval}`
                }

                selections[interval] = {
                    amount,
                    currencyCode,
                    interval,
                    perSeat,
                    price,
                    recurring,
                    text,
                    value
                }

                return selections
            },
            {}
        ) || {}

    const selectedPlan = formattedOptions[Object.keys(formattedOptions)[0]] || {}

    const { text, price, value, interval, perSeat, recurring } = selectedPlan || {}

    const codeFormValue = form.getState().values.code

    const disabled = (hasCode && isPaid) ? !codeFormValue : false

    const onEnterCode = (e) => {
        const code = e.target.value
        if (e.key === 'Enter' && e.target.name === 'code') {
            setCode(code)
        }
    }

    useEffect(() => () => {
        window.removeEventListener('keydown', onEnterCode)
    }, [])

    return (
        <Form.Field {...fieldContainerProps}>
            <div className={classNames(style.container, className)}>
                <div className={style.dflex}>
                    <div className={style.col6}>
                        {customFeaturesUrl && (
                            <RemoteJsonText
                                url={customFeaturesUrl}
                                style={style}
                                linkColor={linkColor}
                            />
                        )}
                    </div>
                    <div className={style.col6}>
                        {!isPaid && (
                            <span className={style.members}>
                                {memberLimit ? (
                                    <>
                                        <span
                                            style={{
                                                textAlign: 'right'
                                            }}
                                        >
                                            up to {memberLimit}
                                            <small>members</small>
                                        </span>
                                        <span
                                            style={{
                                                textAlign: 'right'
                                            }}
                                        >
                                            {price}
                                            {recurring && <small>per {interval}</small>}
                                            {perSeat && <small>per member</small>}
                                        </span>
                                    </>
                                ) : (
                                    <span
                                        style={{
                                            textAlign: 'right'
                                        }}
                                    >
                                        Unlimited
                                        <small>members</small>
                                    </span>
                                )}
                            </span>
                        )}
                        {isPaid && hasCode && (
                            <Field
                                className={style.formInput}
                                component={Form.Input}
                                label="Group Code"
                                name="code"
                                type="text"
                                onFocus={() => {
                                    window.addEventListener('keydown', onEnterCode)
                                }}
                            />
                        )}
                        <div className={style.dflex}>
                            <Field
                                {...(required && {
                                    validate: (value) =>
                                        value ? undefined : 'Required'
                                })}
                                name={name}
                                render={(props) => (
                                    <Form.Radio
                                        {...props}
                                        className={classNames('btn', style.btn, {
                                            [style['btn--disabled']]: disabled
                                        })}
                                        style={{
                                            color: contrastColor
                                        }}
                                    />
                                )}
                                value={value}
                                text={isPaid ? 'Join' : text}
                                type="radio"
                                disabled={disabled}
                                {...rest}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Form.Field>
    )
}

SelectGroup.propTypes = {
    fieldContainerProps: PropTypes.shape({
        label: PropTypes.string,
        required: PropTypes.bool
    }),
    billingStatus: PropTypes.string,
    className: PropTypes.string,
    contrastColor: PropTypes.string,
    customFeaturesUrl: PropTypes.string,
    linkColor: PropTypes.string,
    name: PropTypes.string,
    product: PropTypes.object,
    required: PropTypes.bool,
    groupIsPaid: PropTypes.bool,
    setCode: PropTypes.func,
}

export default SelectGroup
