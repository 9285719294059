import React from 'react'
import 'react-dates/initialize'
import { SingleDatePicker } from 'react-dates'
import moment from 'moment'
import cx from 'classnames'
import PropTypes from 'prop-types'
import Icon from 'piconetworks/pkg-icon'
import Form from 'piconetworks/pkg-form'
import style from './Date.module.scss'

const leftArrow = <Icon.Default.ArrowLeft height={24} width={24} color="#7A34FF" />

const rightArrow = <Icon.Default.ArrowRight height={24} width={24} color="#7A34FF" />

class SingleDate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            date: Array.isArray(props.input.value) ? moment(): moment(props.input && props.input.value),
            focused: null
        }
    }

    componentDidMount(){
        const {input} = this.props
        if(Array.isArray(input.value)){
            input.onChange(moment())
        }
    }

    handleChangeValue = (date) => {
        const { input } = this.props
        this.setState({ date }, () => input && input.onChange(moment(date)))
    }

    render() {
        const { date, focused } = this.state
        const { meta, label, required, id, input, formFieldClassName, className, disabled, datePickerProps, fieldProps, ...args } = this.props

        return (
            <Form.Field label={label} required={required} meta={meta} className={formFieldClassName} input={input} {...fieldProps}>
                <div className={cx(style.datePicker, { [style.error]: meta && meta.error }, className)} {...args}>
                    <SingleDatePicker
                        navPrev={leftArrow}
                        navNext={rightArrow}
                        displayFormat={'MM / DD / YYYY'}
                        dayAriaLabelFormat={'MM / DD / YYYY'}
                        hideKeyboardShortcutsPanel
                        isOutsideRange={() => false}
                        customInputIcon={<Icon.Default.Calendar2 width={24} height={24} />}
                        numberOfMonths={1}
                        date={date}
                        id={id}
                        onDateChange={(date) => this.handleChangeValue(date)}
                        focused={focused}
                        onFocusChange={({focused}) => this.setState({ focused })}
                        disabled={disabled}
                        {...datePickerProps}
                    />
                </div>
            </Form.Field>
        )
    }
}
SingleDate.defaultProps = {
    disabled: false,
    id: 'Single_date_picker'
}

SingleDate.propTypes = {
    className: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
    datePickerProps: PropTypes.object,
    disabled: PropTypes.bool,
    fieldProps: PropTypes.object,
    /** This will be deprecated eventually. Instead pass className to the fieldProps */
    formFieldClassName: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
    id: PropTypes.string,
    input: PropTypes.object,
    label: PropTypes.node,
    meta: PropTypes.object,
    required: PropTypes.bool
}

export default SingleDate
