import React from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'

import Form from 'piconetworks/pkg-form'
import style from './scss/SignupForm.module.scss'

const SignupForm = ({
    fieldContainerProps,
    description = null,
    required,
    ...rest
} = {}) => (
    <Form.Field
        {...fieldContainerProps}
    >
        <div className={style.btn}>
            <Field
                component={Form.Input}
                type="submit"
                {...(required && {
                    validate: value => (value ? undefined : 'Required')
                })}
                {...rest}
            />
        </div>
    </Form.Field>
)

SignupForm.propTypes = {
    description: PropTypes.string,
    fieldContainerProps: PropTypes.shape({
        label: PropTypes.string,
        required: PropTypes.boolean
    }),
    required: PropTypes.func,
}

export default SignupForm
