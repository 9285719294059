import React from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'
import { SingleDate } from 'piconetworks/pkg-date-picker'
import Form from 'piconetworks/pkg-form'

const Date = ({
    description = null,
    fieldContainerProps,
    previewMode = false,
    required,
    ...rest
} = {}) => {
    const date = typeof window !== 'undefined' ? new window.Date() : null

    return (
        <Form.Field {...fieldContainerProps}>
            <p>{description}</p>
            <Field
                render={(field) => (
                    <SingleDate
                        disabled={previewMode}
                        {...field}
                        input={{
                            ...field.input,
                            value:
                                !field.input.value ||
                                Array.isArray(field.input.value)
                                    ? date
                                    : field.input.value

                        }}
                    />
                )}
                type="text"
                {...(required && {
                    validate: (value) => (value ? undefined : 'Required')
                })}
                {...rest}
            />
        </Form.Field>
    )
}

Date.propTypes = {
    description: PropTypes.string,
    fieldContainerProps: PropTypes.any,
    previewMode: PropTypes.bool,
    required: PropTypes.bool
}

export default Date
