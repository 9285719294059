import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Otp from 'piconetworks/pkg-field-otp'

import PhoneField from './PhoneField'

import styles from './TextSignup.module.scss'

const TextSignup = ({
    checkPhone,
    children,
    confirmCode,
    contrastColor,
    description,
    fieldContainerProps,
    isReturningUser,
    linkColor = '',
    oneTimeCodeError,
    oneTimeCodeSent = false,
    onSubmitCallback = () => { },
    phoneNumber,
    previewMode = false,
    subtitle,
    title,
    username,
    userPhoneMismatchError,
    setAuthFlow = () => { },
    authFlow,
} = {}) => {
    const [enterPhone, setEnterPhone] = useState(!oneTimeCodeSent || !phoneNumber || !authFlow)

    const requestNewCode = () => {
        setEnterPhone(true)
        setAuthFlow(null)
    }

    const enterOTP = () => {
        setEnterPhone(false)
    }

    const showPhoneInput = !!userPhoneMismatchError || previewMode || enterPhone

    return (
        <section className={cx({ [styles.isPreview]: previewMode })}>
            {description && (<p>{description}</p>)}
            <>
                <div className={styles.container}>
                    {!oneTimeCodeSent && title && <h2 className={styles.title}>{title}</h2>}
                    {!oneTimeCodeSent && subtitle && <p className={styles.subtitle}>{subtitle}</p>}
                    <div style={{ display: `${showPhoneInput ? 'block' : 'none'}` }}>
                        <PhoneField
                            checkPhone={checkPhone}
                            contrastColor={contrastColor}
                            enterOTP={enterOTP}
                            enterPhone={enterPhone}
                            fieldContainerProps={fieldContainerProps}
                            isReturningUser={isReturningUser}
                            oneTimeCodeError={oneTimeCodeError}
                            onSubmitCallback={onSubmitCallback}
                            previewMode={previewMode}
                            userPhoneMismatchError={userPhoneMismatchError}
                        />
                    </div>
                    <div style={{ display: `${showPhoneInput ? 'none' : 'block'}` }}>
                        <Otp
                            checkPhone={checkPhone}
                            confirmCode={confirmCode}
                            contrastColor={contrastColor}
                            enterOTP={enterOTP}
                            fieldContainerProps={fieldContainerProps}
                            linkColor={linkColor}
                            oneTimeCodeError={oneTimeCodeError}
                            oneTimeCodeSent={oneTimeCodeSent}
                            phoneNumber={phoneNumber}
                            requestNewCode={requestNewCode}
                        />
                    </div>
                    {children}
                </div>
                <div className={styles.terms}>
                    By clicking &ldquo;Continue,&rdquo; you agree to <a href="https://hype.co/terms/user-agreement" target='_blank' rel='noopener noreferrer'>Hype&rsquo;s Terms</a>{' '}
                    and acknowledge the <a href="https://hype.co/terms/privacy-policy" target='_blank' rel='noopener noreferrer'>Privacy Policy</a>.{' '}
                    You also agree that {username} may contact the phone number provided with marketing materials by text message or phone (including via an automated system). 
                    Consent is not a condition of purchase. Message & data rates may apply.
                </div>
            </>
        </section>
    )
}

TextSignup.propTypes = {
    authFlow: PropTypes.string,
    checkPhone: PropTypes.func,
    children: PropTypes.object,
    confirmCode: PropTypes.func,
    contrastColor: PropTypes.string,
    description: PropTypes.string,
    fieldContainerProps: PropTypes.any,
    isReturningUser: PropTypes.bool,
    linkColor: PropTypes.string,
    oneTimeCodeError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    oneTimeCodeSent: PropTypes.bool,
    onSubmitCallback: PropTypes.func,
    phoneNumber: PropTypes.string,
    previewMode: PropTypes.bool,
    setAuthFlow: PropTypes.func,
    subtitle: PropTypes.string,
    title: PropTypes.string,
    username: PropTypes.string,
    userPhoneMismatchError: PropTypes.string,
}

const MemoizedTextSignup = React.memo(TextSignup)

export default MemoizedTextSignup
