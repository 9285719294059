import React from 'react'
import PropTypes from 'prop-types'
import { PlaidLink } from 'react-plaid-link'

const PlaidDecorator = ({
    env,
    publicKey,
    clientName,
    product,
    onSuccess,
    className,
    style,
    selectAccount,
    children: topLevelChildren,
    ...rest
}) => (
    React.cloneElement(topLevelChildren, {
        PlaidLink: ({ children }) => (
            <PlaidLink
                env={env}
                publicKey={publicKey}
                clientName={clientName}
                product={product}
                onSuccess={onSuccess}
                style={style}
                selectAccount={selectAccount}
                className={className}
                {...rest}
            >
                {children}
            </PlaidLink>
        ),
    })
)

PlaidDecorator.defaultProps = {
    children: () => {},
    product: ['auth'],
    onSuccess: () => {},
    style: {},
    className: null,
    selectAccount: true,
}

PlaidDecorator.propTypes = {
    env: PropTypes.string.isRequired,
    publicKey: PropTypes.string.isRequired,
    clientName: PropTypes.string.isRequired,
    product: PropTypes.arrayOf(PropTypes.string),
    onSuccess: PropTypes.func,
    style: PropTypes.object,
    className: PropTypes.string,
    selectAccount: PropTypes.bool,
}

export default PlaidDecorator
