/* eslint-disable react/prop-types */
/* eslint-disable no-empty-pattern */

import React, { useState, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Auth from 'modules/auth'
import Account from 'modules/account'
import Me from 'modules/me'
import { useRouter } from 'lib/RouterAdapterDecorator'
import qs from 'qs'

import Client from 'modules/client'
import LandingPage from 'modules/landing-page'
import Loader from 'modules/loader'
import FeatureFlags from 'modules/feature-flags'

import observers from 'lib/observers'
import { getSignupPaymentUrl } from 'lib/helpers'

import Address from 'piconetworks/pkg-field-address'
import Date from 'piconetworks/pkg-field-date'
import Dropdown from 'piconetworks/pkg-field-dropdown'
import TextSignup from 'piconetworks/pkg-field-text-signup'
import GiftForm from 'piconetworks/pkg-field-gift-form'
import Markdown from 'piconetworks/pkg-field-markdown'
import Newsletters from 'piconetworks/pkg-field-newsletters'
import Number from 'piconetworks/pkg-field-number'
import Radio from 'piconetworks/pkg-field-radio'
import Boolean from 'piconetworks/pkg-field-boolean'
import Checkbox from 'piconetworks/pkg-field-checkbox'

import SelectDonation from 'piconetworks/pkg-field-select-donation'
import SelectPass from 'piconetworks/pkg-field-select-pass'
import SelectPledge from 'piconetworks/pkg-field-select-pledge'
import SelectSubscription from 'piconetworks/pkg-field-select-subscription'
import SelectTrial from 'piconetworks/pkg-field-select-trial'
import SelectGroup from 'piconetworks/pkg-field-select-group'

import SignupForm from 'piconetworks/pkg-field-signup-form'
import SocialLogin from 'piconetworks/pkg-field-social-login'
import Text from 'piconetworks/pkg-field-text'
import Link from 'piconetworks/pkg-field-link'
import SocialLink from 'piconetworks/pkg-field-social-link'
import Payment from 'piconetworks/pkg-field-payment'

import { format } from 'piconetworks/pkg-currency'

import {
    DonationExistingSwitch,
    DonationNewOneTime,
    DonationNewRecurring,
    GiftExistingConvert,
    GiftNewPledge,
    GiftNewRegular,
    GroupNewRegular,
    PassNewRegular,
    SubscriptionExistingSwitch,
    SubscriptionNewPledge,
    SubscriptionNewRegular,
} from 'piconetworks/pkg-order-summary'

import dayjs from 'dayjs'
import logger from 'lib/logger'

// add in :
// dynamic registry pattern
// service discovery e.g. EmailPasswordLogin() = typeMap.EmailPasswordLogin
// namespacing
// locale text copy int'l

import EmailPassWordSocialNewslettersLogin from './fields/EmailPassWordSocialNewslettersLogin'
import SignupWrapper from './fields/SignupWrapper'
import { Field } from 'redux-form'

const useDecoratedCurrentProduct = () => {
    const _currentProductOptionId = useSelector(Loader.selectors.currentProductOptionId)
    const _currentPurchaseCanceled = useSelector(Loader.selectors.currentPurchaseCanceled)
    const currentProductOptionId = (!_currentPurchaseCanceled && _currentProductOptionId)
    const trialIneligible = (_currentPurchaseCanceled || _currentProductOptionId)

    return {
        currentProductOptionId,
        trialIneligible,
    }
}

// compares fields array and form values
export const isCustomFieldsValid = (fields = [], values = {}) => {
    const requiredFields = fields.filter((field) => field?.required)

    const isValid = requiredFields.every(({ id }) => {
        const value = values[id]

        let isValueValid = false

        // address requires country / address line 1 / state|province|reigon / city / zip
        if (id === 'address') {
            isValueValid = !!(value?.address1
                && value?.city
                && value?.country
                && (value?.state || value?.province || value?.region)
                && value?.post_code)
        } else if (id === 'birthday') {
            isValueValid = !!value
        } else if (typeof value === 'object') {
            isValueValid = Object.keys(value).length
        } else {
            isValueValid = typeof value === 'number'
                ? value !== null
                : !!value
        }

        return isValueValid
    })
    return isValid
}

const { priceText } = Loader.selectors

const giftCta = ({
    optionId,
    getOutboundClaim,
    redirectToCheckout,
}) => {
    const router = useRouter()
    const { wizardName, company_slug, short_code } = router?.query || {}

    const query = {
        ...(wizardName && {
            id: wizardName,
        }),
        ...(optionId && {
            option_id: optionId,
        }),
        ...(short_code && {
            short_code: short_code
        })
    }

    if (redirectToCheckout) {
        const redirectString = qs.stringify({
            ...(getOutboundClaim && { claim: getOutboundClaim }),
            gift: 'gift',
        })

        window.location.href = `${process.env.CHECKOUT_APP_URL}/${optionId}?${redirectString}`

    } else {
        router.push({
            pathname: '/[company_slug]/checkout-with-gift',
            query,
        }, {
            pathname: `/${company_slug}/checkout-with-gift`,
            query,
        })
    }
}
const NewslettersInjected = ({
    name,
    label,
    description,
    required,
    defaultValue,
    linkColor,
    newsletterFilter,
    newsletters = [],
}) => (
    <Newsletters
        fieldContainerProps={{
            label,
            required,
        }}
        name={name}
        description={description}
        required={required}
        defaultValue={defaultValue}
        linkColor={linkColor}
        newsletterFilter={newsletterFilter}
        newsletters={newsletters}
    />
)

const typeMap = {
    Payment: ({
        existingPaymentMethods = [],
        changeCompletionStatus,
        isComplete,
        enableStripe = true,
        enablePlaid = false,
        sendExternalLink,
        linkColor,
        publisherName,
        defaultPaymentMethod = false,
        CardElement = () => null,
        deleteCard = () => { },
    }) => (
        <Payment
            changeCompletionStatus={changeCompletionStatus}
            isComplete={isComplete}
            enableStripe={enableStripe}
            enablePlaid={enablePlaid}
            sendExternalLink={sendExternalLink}
            existingPaymentMethods={existingPaymentMethods}
            defaultPaymentMethod={defaultPaymentMethod}
            linkColor={linkColor}
            publisherName={publisherName}
            CardElement={CardElement}
            deleteCard={deleteCard}
        />
    ),
    DonationExistingSwitch: ({
        state,
        option,
        product,
        customPrice,
        switchNow,
    }) => {
        const orderSummaryData = LandingPage.selectors.orderSummary(
            state,
            {
                optionId: option?.id,
                productId: product?.id,
                customPrice,
            },
        )
        const nextSubscriptionEnd = Loader.selectors.nextSubscriptionEnd(state)
        const prorateAmount = switchNow?.prorate_amount
            ? priceText(format({
                amount: switchNow?.prorate_amount,
                symbol: false,
            }), option.currency)
            : null

        const productTitle = orderSummaryData?.productTitle
        const optionTypeAndInterval = orderSummaryData?.optionTypeAndInterval
        const renewalDate = switchNow?.switchNowStatus
            ? dayjs().format('LL')
            : dayjs(nextSubscriptionEnd).format('LL')
        const optionPrice = option?.price
            ? orderSummaryData?.optionPrice
            : orderSummaryData?.optionCustomPrice
        const optionInterval = orderSummaryData?.optionInterval
        const oldOptionPrice = orderSummaryData?.oldOptionPrice

        return (
            <DonationExistingSwitch
                productTitle={productTitle}
                optionTypeAndInterval={optionTypeAndInterval}
                renewalDate={renewalDate}
                optionPrice={optionPrice}
                optionInterval={optionInterval}
                switchNow={switchNow}
                oldOptionPrice={oldOptionPrice}
                prorateAmount={prorateAmount}
            />
        )
    },
    SubscriptionNewRegular: ({
        state,
        option,
        product,
    }) => {
        const orderSummaryData = LandingPage.selectors.orderSummary(
            state,
            {
                option,
                product,
                optionId: option?.id,
                productId: product?.id,
            },
        )

        const productTitle = orderSummaryData?.productTitle
        const optionTypeAndInterval = orderSummaryData?.optionTypeAndInterval
        const renewalDate = orderSummaryData?.renewalDate
        const optionPrice = orderSummaryData?.optionPrice
        const optionInterval = orderSummaryData?.optionInterval

        return (
            <SubscriptionNewRegular
                productTitle={productTitle}
                optionTypeAndInterval={optionTypeAndInterval}
                renewalDate={renewalDate}
                optionPrice={optionPrice}
                optionInterval={optionInterval}
            />
        )
    },
    SubscriptionNewPledge: ({
        state,
        option,
        product,
        customPrice,
    }) => {
        const orderSummaryData = LandingPage.selectors.orderSummary(
            state,
            {
                option,
                product,
                optionId: option?.id,
                productId: product?.id,
                customPrice,
            },
        )

        const productTitle = orderSummaryData?.productTitle
        const optionTypeAndInterval = orderSummaryData?.optionTypeAndInterval
        const renewalDate = orderSummaryData?.renewalDate
        const optionPrice = orderSummaryData?.optionCustomPrice || orderSummaryData?.optionPrice
        const optionInterval = orderSummaryData?.optionInterval
        const trialPrice = orderSummaryData?.trialPrice
        const trialDuration = orderSummaryData?.trialDuration
        const trialFinishDate = orderSummaryData?.trialFinishDate
        const isFree = orderSummaryData?.isFree

        return (
            <SubscriptionNewPledge
                productTitle={productTitle}
                optionTypeAndInterval={optionTypeAndInterval}
                renewalDate={renewalDate}
                optionPrice={optionPrice}
                optionInterval={optionInterval}
                trialPrice={trialPrice}
                trialDuration={trialDuration}
                trialFinishDate={trialFinishDate}
                isFree={isFree}
            />
        )
    },
    SubscriptionExistingSwitch: ({
        state,
        option,
        product,
        switchNow,
        customPrice,
    }) => {
        const orderSummaryData = LandingPage.selectors.orderSummary(
            state,
            {
                optionId: option?.id,
                productId: product?.id,
                customPrice,
            },
        )
        const nextSubscriptionEnd = Loader.selectors.nextSubscriptionEnd(state)
        const currentSubscriptionEnd = Loader.selectors.currentSubscriptionEnd(state)
        const isTrial = Loader.selectors.currentSubscriptionIsTrial(state)

        const prorateAmount = switchNow?.prorate_amount
            ? priceText(format({
                amount: switchNow?.prorate_amount,
                symbol: false,
            }), option.currency)
            : null

        const productTitle = orderSummaryData?.productTitle
        const optionTypeAndInterval = orderSummaryData?.optionTypeAndInterval
        const renewalDate = switchNow?.switchNowStatus
            ? dayjs().format('LL')
            : dayjs(isTrial ? currentSubscriptionEnd : nextSubscriptionEnd).format('LL')
        const optionPrice = orderSummaryData?.trialPrice
            || orderSummaryData?.optionCustomPrice
            || orderSummaryData?.optionPrice
        const optionInterval = orderSummaryData?.optionInterval
        const oldOptionPrice = orderSummaryData?.oldOptionPrice

        return (
            <SubscriptionExistingSwitch
                productTitle={productTitle}
                optionTypeAndInterval={optionTypeAndInterval}
                renewalDate={renewalDate}
                optionPrice={optionPrice}
                optionInterval={optionInterval}
                oldOptionPrice={oldOptionPrice}
                switchNow={switchNow}
                prorateAmount={prorateAmount}
            />
        )
    },
    GiftNewRegular: ({
        state,
        option,
        product,
    }) => {
        const orderSummaryData = LandingPage.selectors.orderSummary(
            state,
            {
                optionId: option?.id,
                productId: product?.id,
            },
        )

        const productTitle = orderSummaryData?.productTitle
        const optionTypeAndInterval = orderSummaryData?.optionTypeAndInterval
        const optionPrice = orderSummaryData?.optionPrice
        const optionInterval = orderSummaryData?.optionInterval

        return (
            <GiftNewRegular
                productTitle={productTitle}
                optionTypeAndInterval={optionTypeAndInterval}
                optionPrice={optionPrice}
                optionInterval={optionInterval}
            />
        )
    },
    GiftNewPledge: ({
        state,
        option,
        product,
        customPrice,
    }) => {
        const orderSummaryData = LandingPage.selectors.orderSummary(
            state,
            {
                optionId: option?.id,
                productId: product?.id,
                customPrice,
            },
        )

        const productTitle = orderSummaryData?.productTitle
        const optionTypeAndInterval = orderSummaryData?.optionTypeAndInterval
        const optionPrice = orderSummaryData?.optionCustomPrice || orderSummaryData?.optionPrice
        const optionInterval = orderSummaryData?.optionInterval

        return (
            <GiftNewPledge
                productTitle={productTitle}
                optionTypeAndInterval={optionTypeAndInterval}
                optionPrice={optionPrice}
                optionInterval={optionInterval}
            />
        )
    },
    GiftExistingConvert: ({
        state,
        option,
        product,
    }) => {
        const orderSummaryData = LandingPage.selectors.orderSummary(
            state,
            {
                optionId: option?.id,
                productId: product?.id,
            },
        )

        const productTitle = orderSummaryData?.productTitle
        const optionTypeAndInterval = orderSummaryData?.optionTypeAndInterval
        const renewalDate = orderSummaryData?.renewalDate
        const optionPrice = orderSummaryData?.optionPrice
        const optionInterval = orderSummaryData?.optionInterval

        return (
            <GiftExistingConvert
                productTitle={productTitle}
                optionTypeAndInterval={optionTypeAndInterval}
                renewalDate={renewalDate}
                optionPrice={optionPrice}
                optionInterval={optionInterval}
            />
        )
    },
    DonationNewRecurring: ({
        state,
        option,
        product,
        customPrice,
    }) => {
        const orderSummaryData = LandingPage.selectors.orderSummary(
            state,
            {
                optionId: option?.id,
                productId: product?.id,
                customPrice,
            },
        )

        const productTitle = orderSummaryData?.productTitle
        const optionTypeAndInterval = orderSummaryData?.optionTypeAndInterval
        const renewalDate = orderSummaryData?.renewalDate
        const optionPrice = orderSummaryData?.optionCustomPrice || orderSummaryData?.optionPrice
        const optionInterval = orderSummaryData?.optionInterval

        return (
            <DonationNewRecurring
                productTitle={productTitle}
                optionTypeAndInterval={optionTypeAndInterval}
                renewalDate={renewalDate}
                optionPrice={optionPrice}
                optionInterval={optionInterval}
            />
        )
    },
    DonationNewOneTime: ({
        state,
        option,
        product,
        customPrice,
    }) => {
        const orderSummaryData = LandingPage.selectors.orderSummary(
            state,
            {
                optionId: option?.id,
                productId: product?.id,
                customPrice,
            },
        )
        const productTitle = orderSummaryData?.productTitle
        const optionPrice = orderSummaryData?.optionCustomPrice || orderSummaryData?.optionPrice

        return (
            <DonationNewOneTime
                productTitle={productTitle}
                optionPrice={optionPrice}
            />
        )
    },
    PassNewRegular: ({
        state,
        option,
        product,
    }) => {
        const orderSummaryData = LandingPage.selectors.orderSummary(
            state,
            {
                optionId: option?.id,
                productId: product?.id,
            },
        )

        const productTitle = orderSummaryData?.productTitle
        const optionPrice = orderSummaryData?.optionPrice
        const passDuration = orderSummaryData?.passDuration

        return (
            <PassNewRegular
                productTitle={productTitle}
                optionPrice={optionPrice}
                passDuration={passDuration}
            />
        )
    },
    GroupNewRegular: ({
        state,
        option,
        product,
    }) => {
        const orderSummaryData = LandingPage.selectors.orderSummary(state, {
            optionId: option?.id,
            productId: product?.id,
        })
        const {
            optionInterval,
            optionPrice,
            optionTypeAndInterval,
            productTitle,
            renewalDate,
            optionPerSeat,
            optionRecurring,
        } = orderSummaryData || {}
        return (
            <GroupNewRegular
                optionInterval={optionInterval}
                optionPrice={optionPrice}
                optionTypeAndInterval={optionTypeAndInterval}
                productTitle={productTitle}
                renewalDate={renewalDate}
                optionPerSeat={optionPerSeat}
                optionRecurring={optionRecurring}
            />
        )
    },
    GiftForm: ({
        name,
        label,
        description,
        required,
        defaultValue,
    }) => (
        <GiftForm
            fieldContainerProps={{
                label,
                required,
            }}
            name={name}
            description={description}
            required={required}
            defaultValue={defaultValue}
        />
    ),
    Markdown: ({
        name,
        label,
        description,
        required,
        defaultValue,
        url,
        onClickLink,
        options,
    }) => {
        const linkColor = useSelector(Client.selectors.linkColor)

        return (
            <Markdown
                fieldContainerProps={{
                    label,
                    required,
                }}
                name={name}
                linkColor={linkColor}
                description={description}
                required={required}
                defaultValue={defaultValue}
                url={url}
                onClickLink={onClickLink}
                options={options}
            />
        )
    },
    Signup: SignupWrapper,
    EmailPassWordSocialNewslettersLogin,
    TextSignup: ({
        name,
        label,
        description,
        required,
        children,
        isReturningUser,
        popupId,
        onSubmitCallback = () => { },
        title,
        subtitle,
        setAuthFlow = () => { },
        authFlow,
    }) => {
        const dispatch = useDispatch()
        const router = useRouter()
        const [userPhoneMismatchError, setUserPhoneMismatchError] = useState(null)

        const wizardName = router?.query?.id
        const shortCode = router?.query?.short_code

        const queryString = qs.stringify({
            ...(wizardName && {
                id: wizardName,
            }),
            ...(shortCode && {
                short_code: shortCode,
            }),
        })

        const linkColor = useSelector(Client.selectors.linkColor)
        const contrastColor = useSelector(Client.selectors.contrastColor)
        const publisherId = useSelector(Client.selectors.id)
        const username = useSelector(Client.selectors.username)
        const company_slug = router?.query?.company_slug || `@${username}`
        const userId = useSelector((state) => state?.loader?.guest?.user?.id)
        const access_token = useSelector((state) => state?.loader?.guest?.token)
        const utm = LandingPage.selectors.getUTM()

        const originUrl = typeof window !== 'undefined' && window?.location?.origin

        const redirectUrl = `${originUrl}/${company_slug}/login/token?${queryString}`
        const origin = getSignupPaymentUrl({ shortCode, username })
        const oneTimeCodeSent = useSelector(Account.selectors.oneTimeCodeSent)
        const oneTimeCodeError = useSelector(Account.selectors.oneTimeCodeError)
        const phoneNumber = useSelector(Account.selectors.getPhoneNumber)
        const userPhone = useSelector(Me.selectors.userPhone)
        const publisherName = useSelector(Client.selectors.name)

        return (
            <TextSignup
                authFlow={authFlow}
                setAuthFlow={setAuthFlow}
                title={title}
                subtitle={subtitle}
                fieldContainerProps={useMemo(() => ({
                    label,
                    required,
                }), [label, required])}
                name={name}
                userId={userId}
                access_token={access_token}
                isReturningUser={isReturningUser}
                utm={utm}
                description={description}
                required={required}
                linkColor={linkColor}
                contrastColor={contrastColor}
                origin={origin}
                publisherId={publisherId}
                username={publisherName}
                phoneNumber={phoneNumber}
                oneTimeCodeSent={oneTimeCodeSent}
                oneTimeCodeError={oneTimeCodeError}
                userPhoneMismatchError={userPhoneMismatchError}
                checkPhone={async ({
                    phone,
                }) => {
                    if (!phone) {
                        throw new Error('No phone number provided')
                    }

                    const lpUtm = LandingPage.selectors.getUTM()

                    if (userPhone && userPhone !== phone) {
                        setUserPhoneMismatchError('Please use the phone number that is linked to your Hype account.')
                        return
                    }

                    try {
                        await observers.requestOneTimeCode({
                            dispatch,
                            payload: {
                                phone,
                                publisherId,
                                redirectUrl,
                                origin,
                                popupId,
                                utm: lpUtm,
                            },
                        })
                        setUserPhoneMismatchError(null)
                        setAuthFlow('sms')
                    } catch (error) {
                        logger.error('requestOneTimeCode', {}, error)
                        if (error?.message) {
                            throw error
                        } else {
                            throw new Error('an error has occurred')
                        }
                    }
                }}
                confirmCode={async ({
                    code, phone,
                }) => {
                    if (!code) {
                        throw new Error('No code provided')
                    }
                    const lpUtm = LandingPage.selectors.getUTM()

                    try {
                        const confirmOneTimeCode = await observers.confirmOneTimeCode({
                            dispatch,
                            payload: {
                                code,
                                phone,
                                publisherId,
                                redirectUrl,
                                origin,
                                popupId,
                                utm: lpUtm,
                            },
                        })

                        // If we have a claim, get tokens
                        if (confirmOneTimeCode.claim !== null) {
                            const response = await observers.exchangeClaim({
                                dispatch,
                                payload: {
                                    claim: confirmOneTimeCode.claim,
                                    scope: 'user',
                                    publisherId,
                                },
                            })

                            dispatch(Auth.creators.tokenLogin({
                                accessToken: response?.tokens?.token,
                                refreshToken: response?.tokens?.refresh_token,
                            }))

                            dispatch(Me.creators.getCurrentUser({
                                payload: {
                                    publisherId,
                                },
                            }))
                        }
                    } catch (error) {
                        logger.error('confirmOneTimeCode', {}, error)
                        if (error?.message) {
                            throw new Error(error?.message)
                        } else {
                            throw new Error('an error has occurred')
                        }
                    }
                }}
                onSubmitCallback={onSubmitCallback}
            >
                {children}
            </TextSignup>
        )
    },
    SocialLogin: ({
        name,
        label,
        description,
        required,
        defaultValue,
        disabled,
        popupId,
        customProperties,
        newsletters,
        authenticationMethods,
    }) => {
        const dispatch = useDispatch()

        const publisherId = useSelector((state) => Client.selectors.publisherId(state))
        const userId = useSelector((state) => state?.loader?.guest?.user?.id)
        const access_token = useSelector((state) => state?.loader?.guest?.token)
        const utm = LandingPage.selectors.getUTM()

        return (
            <SocialLogin
                authenticationMethods={authenticationMethods}
                fieldContainerProps={{
                    label,
                    required,
                }}
                origin={origin}
                // referer={referer}
                params={{
                    custom_properties: customProperties,
                    form_id: popupId,
                    newsletter_ids: newsletters,
                    utmParams: utm,
                }}
                name={name}
                description={description}
                required={required}
                defaultValue={defaultValue}
                onLogin={(response) => {
                    dispatch(Auth.creators.socialLogin({
                        accessToken: response.token,
                        refreshToken: response.refresh_token,
                    }))
                }}
                publisherId={publisherId}
                userId={userId}
                access_token={access_token}
                disabled={disabled}
            />
        )
    },
    SignupForm: ({
        name,
        label,
        description,
        required,
        defaultValue,
    }) => {
        const linkColor = useSelector(Client.selectors.linkColor)

        return (
            <SignupForm
                fieldContainerProps={{
                    label,
                    required,
                }}
                name={name}
                description={description}
                required={required}
                defaultValue={defaultValue}
                linkColor={linkColor}
            />
        )
    },
    SelectDonation: ({
        name,
        required,
        product,
        isGift,
        setProductOptionId,
        setCustomPrice,
        currentPurchaseOptionId,
        isSwitch,
        onChangePatch = false,
        onChange,
    }) => {
        const linkColor = useSelector(Client.selectors.linkColor)
        const contrastColor = useSelector(Client.selectors.contrastColor)
        const isGroupMember = useSelector(Loader.selectors.isGroupMember)
        const currentSubscriptionType = useSelector(Loader.selectors.currentSubscriptionType)
        const customFeaturesUrl = product?.custom_text?.file_name || product?.custom_text?.filename
        const contributionType = useSelector((state) => state?.client?.contribution_type)

        return (
            <SelectDonation
                name={name}
                required={required}
                product={product}
                linkColor={linkColor}
                contrastColor={contrastColor}
                className='block'
                isGift={isGift}
                setProductOptionId={setProductOptionId}
                setCustomPrice={setCustomPrice}
                currentPurchaseOptionId={currentPurchaseOptionId}
                customFeaturesUrl={customFeaturesUrl}
                isGroupMember={isGroupMember}
                currentSubscriptionType={currentSubscriptionType}
                isSwitch={isSwitch}
                contributionLanguage={contributionType === 'tip' ? 'tip': 'donate'}
                // input={{
                //     ...(onChangePatch && {onChange})
                // }}
            />
        )
    },
    SelectPass: ({
        name,
        required,
        product,
        isGift,
    }) => {
        const isPageConsolidationEnabled = useSelector((state) => FeatureFlags.selectors.getFeatureFlagIsEnabled(state, 'page-consolidation'))
        const _getOutboundClaim = useSelector((state) => state?.claim?.outboundClaim)
        const linkColor = useSelector(Client.selectors.linkColor)
        const contrastColor = useSelector(Client.selectors.contrastColor)

        const customFeaturesUrl = product?.custom_text?.file_name || product?.custom_text?.filename

        return (
            <SelectPass
                name={name}
                required={required}
                product={product}
                className='block'
                linkColor={linkColor}
                contrastColor={contrastColor}
                isGift={isGift}
                giftCta={(optionId) => giftCta({
                    optionId,
                    getOutboundClaim: _getOutboundClaim,
                    redirectToCheckout: isPageConsolidationEnabled,
                })}
                customFeaturesUrl={customFeaturesUrl}
            />
        )
    },
    SelectSubscription: ({
        name,
        required,
        product,
        isGift,
    }) => {
        const isPageConsolidationEnabled = useSelector((state) => FeatureFlags.selectors.getFeatureFlagIsEnabled(state, 'page-consolidation'))
        const _getOutboundClaim = useSelector((state) => state?.claim?.outboundClaim)
        const linkColor = useSelector(Client.selectors.linkColor)
        const contrastColor = useSelector(Client.selectors.contrastColor)

        const customFeaturesUrl = product?.custom_text?.file_name || product?.custom_text?.filename

        return (
            <SelectSubscription
                name={name}
                required={required}
                product={product}
                linkColor={linkColor}
                contrastColor={contrastColor}
                className='block'
                isGift={isGift}
                giftCta={(optionId) => giftCta({
                    optionId,
                    getOutboundClaim: _getOutboundClaim,
                    redirectToCheckout: isPageConsolidationEnabled,
                })}
                customFeaturesUrl={customFeaturesUrl}
            />
        )
    },
    SelectPledge: ({
        name,
        required,
        product,
        isGift,
    }) => {
        const isPageConsolidationEnabled = useSelector((state) => FeatureFlags.selectors.getFeatureFlagIsEnabled(state, 'page-consolidation'))
        const _getOutboundClaim = useSelector((state) => state?.claim?.outboundClaim)
        const linkColor = useSelector(Client.selectors.linkColor)
        const contrastColor = useSelector(Client.selectors.contrastColor)

        const customFeaturesUrl = product?.custom_text?.file_name

        return (
            <SelectPledge
                name={name}
                required={required}
                product={product}
                className='block'
                linkColor={linkColor}
                contrastColor={contrastColor}
                isGift={isGift}
                giftCta={(optionId) => giftCta({
                    optionId,
                    getOutboundClaim: _getOutboundClaim,
                    redirectToCheckout: isPageConsolidationEnabled,
                })}
                customFeaturesUrl={customFeaturesUrl}
            />
        )
    },
    SelectTrial: ({
        name,
        required,
        product,
    }) => {
        const isPageConsolidationEnabled = useSelector((state) => FeatureFlags.selectors.getFeatureFlagIsEnabled(state, 'page-consolidation'))
        const _getOutboundClaim = useSelector((state) => state?.claim?.outboundClaim)
        const linkColor = useSelector(Client.selectors.linkColor)
        const contrastColor = useSelector(Client.selectors.contrastColor)
        const { currentProductOptionId, trialIneligible } = useDecoratedCurrentProduct()

        const customFeaturesUrl = product?.custom_text?.file_name || product?.custom_text?.filename

        return (
            <SelectTrial
                name={name}
                required={required}
                product={product}
                className='block'
                linkColor={linkColor}
                contrastColor={contrastColor}
                giftCta={(optionId) => giftCta({
                    optionId,
                    getOutboundClaim: _getOutboundClaim,
                    redirectToCheckout: isPageConsolidationEnabled,
                })}
                customFeaturesUrl={customFeaturesUrl}
                currentProductOptionId={currentProductOptionId}
                trialIneligible={trialIneligible}
            />
        )
    },
    SelectGroup: ({
        name,
        required,
        product,
        groupIsPaid,
        setCode,
    }) => {
        const linkColor = useSelector(Client.selectors.linkColor)
        const contrastColor = useSelector(Client.selectors.contrastColor)
        const customFeaturesUrl = product?.custom_text?.file_name
        const router = useRouter()

        const shortCode = router?.query?.short_code
        const landingPage = useSelector((state) => (
            LandingPage.selectors.getLandingPageByShortCode(state, shortCode)
        ))
        const { billing_status: billingStatus } = landingPage

        return (
            <SelectGroup
                billingStatus={billingStatus}
                contrastColor={contrastColor}
                customFeaturesUrl={customFeaturesUrl}
                className='block'
                linkColor={linkColor}
                name={name}
                product={product}
                required={required}
                groupIsPaid={groupIsPaid}
                setCode={setCode}
            />
        )
    },
    Date: ({
        name,
        label,
        description,
        required,
    }) => (
        <Date
            fieldContainerProps={{
                label,
                required,
            }}
            name={name}
            description={description}
            required={required}
        />
    ),
    Dropdown: ({
        name,
        label,
        description,
        required,
        defaultValue,
        options,
    }) => (
        <Dropdown
            fieldContainerProps={{
                label,
                required,
            }}
            name={name}
            description={description}
            required={required}
            defaultValue={defaultValue}
            options={options}
        />
    ),
    Address: ({
        name,
        label,
        description,
        required,
        defaultValue,
    }) => (
        <Address
            fieldContainerProps={{
                label,
                required,
            }}
            name={name}
            description={description}
            required={required}
            defaultValue={defaultValue}
        />
    ),
    Text: ({
        name,
        label,
        description,
        required,
        defaultValue,
    }) => (
        <Text
            fieldContainerProps={{
                label,
                required,
            }}
            name={name}
            description={description}
            required={required}
            defaultValue={defaultValue}
        />
    ),
    Link: ({
        name,
        description,
        required,
        linkBlocks,
        linkColor,
        onLinkClink,
        richMediaData,
    }) => (
        <Link
            fieldContainerProps={{
                required,
            }}
            name={name}
            description={description}
            linkBlocks={linkBlocks}
            linkBlockClassName='block'
            linkColor={linkColor}
            onLinkClink={onLinkClink}
            richMediaData={richMediaData}
        />
    ),
    SocialLink: ({
        name,
        description,
        required,
        links,
        onClick,
    }) => (
        <SocialLink
            fieldContainerProps={{
                required,
            }}
            name={name}
            description={description}
            links={links}
            onClick={onClick}
        />
    ),
    Number: ({
        name,
        label,
        description,
        required,
        defaultValue,
    }) => (
        <Number
            fieldContainerProps={{
                label,
                required,
            }}
            name={name}
            description={description}
            required={required}
            defaultValue={defaultValue}
        />
    ),
    Radio: ({
        name,
        label,
        description,
        required,
        options,
    }) => (
        <Radio
            fieldContainerProps={{
                label,
                required,
            }}
            name={name}
            description={description}
            required={required}
            options={options}
        />
    ),
    Boolean: ({
        name,
        label,
        description,
        required,
        options,
    }) => (
        <Boolean
            fieldContainerProps={{
                label,
                required,
            }}
            name={name}
            description={description}
            required={required}
            options={options}
        />
    ),
    Checkbox: ({
        name,
        label,
        description,
        required,
        options,
    }) => (
        <Checkbox
            fieldContainerProps={{
                label,
                required,
            }}
            name={name}
            description={description}
            required={required}
            options={options}
        />
    ),
    Newsletters: NewslettersInjected,
}

const getFields = (fields, prefix = '') => (
    fields
        .filter(({
            type,
            id,
        }) => (
            !!type
            && !!typeMap[type]
            && !!id
        ))
        .sort(({ order: a }, { order: b }) => a - b)
        .map(({
            type,
            id,
            title,
            ...rest
        }) => {
            const Field = typeMap[type]

            return (props) => (
                <Field
                    {...{
                        name: `${prefix ? `${prefix}.` : ''}${id}`,
                        label: title,
                    }}
                    {...rest}
                    {...props}
                />
            )
        })
)

export default getFields

export {
    NewslettersInjected as Newsletters,
    typeMap,
}
