import React, { useState, useEffect } from 'react'
import { Field } from 'react-final-form'
import classNames from 'classnames'

import { H3 } from 'piconetworks/pkg-heading'
import { P } from 'piconetworks/pkg-text'
import { format } from 'piconetworks/pkg-currency'
import Form from 'piconetworks/pkg-form'
import { AnnualPercentDiscount } from 'piconetworks/pkg-functions'
import { RemoteJsonText } from 'piconetworks/pkg-remote-markdown'

import style from './scss/SelectSubscription.module.scss'

class ErrorBoundary extends React.Component {

    static getDerivedStateFromError(error) {
        console.log('getDerivedStateFromError', error)
        return { hasError: true }
    }

    constructor(props){
        super(props)
        this.state = { hasError: false }
    }

    componentDidCatch(error, errorInfo) {
        console.error(error.message, errorInfo)
    }

    render() {
        const { hasError } = this.state
        const { children, RenderError = () => (<div className='errorBoundaree'></div>) } = this.props

        if (hasError) {
            return (
                <RenderError />
            )
        }

        return children
    }
}

const SelectSubscription = (props) => {
    const {
        fieldContainerProps,
        required,
        popup,
        name,
        linkColor = '',
        contrastColor = '',
        isGift = false,
        product = {},
        className,
        customFeaturesUrl = '',
        giftCta = () => { },
        ...rest
    } = props
    const [isAnnualChecked, toggleAnnualChecked] = useState(false)

    useEffect(() => {
        const annual = product.options.some(option => option.default_option && option.interval === 'year')
        toggleAnnualChecked(annual)
    }, Array.isArray(product.options) ? product.options : [])

    const title = product?.title
    const formattedOptions = product?.options
        ?.reduce((selections, {
            id: value,
            interval,
            price: amount,
            currency: currencyCode,
        }) => {
            const price = format({
                currencyCode,
                amount,
                symbol: true,
            })

            const text = isGift
                ? `Gift the first ${interval}`
                : 'Subscribe'

            selections[interval] = {
                value,
                text,
                price,
                amount,
                interval
            }

            return selections
        }, {}) || []

    const showPlanIntervalSwitch = Object.keys(formattedOptions).length > 1

    const selectedPlan = showPlanIntervalSwitch
        ? isAnnualChecked
            ? formattedOptions.year
            : formattedOptions.month
        : formattedOptions[Object.keys(formattedOptions)[0]]

    const text = selectedPlan?.text
    const price = selectedPlan?.price
    const value = selectedPlan?.value
    const interval = selectedPlan?.interval
    let savings
    if (showPlanIntervalSwitch) {
        const monthlyPrice = formattedOptions?.month?.amount
        const annualPrice = formattedOptions?.year?.amount
        savings = (annualPrice < monthlyPrice * 12) && AnnualPercentDiscount(monthlyPrice, annualPrice)
    }

    return (
        <ErrorBoundary>
            <Form.Field
                {...fieldContainerProps}
            >
                <div className={classNames(style.container, className)}>
                    {title && (
                        <div className={style.header}>
                            <H3 className={style.title}>{title}</H3>
                            <div className={style.priceContainer}>
                                <H3 className={style.price}>{price}</H3>
                                <small>per {interval}</small>
                            </div>
                        </div>
                    )}

                    <div className={style.dflex}>
                        <div className={style.col6}>
                            <div className={style.features}>
                                {customFeaturesUrl && <RemoteJsonText url={customFeaturesUrl} style={style} linkColor={linkColor} />}
                            </div>
                            {showPlanIntervalSwitch && (
                                <div className={style.radios}>
                                    <div onClick={() => toggleAnnualChecked(false)} className={classNames(style.tab, { [style.checked]: interval === 'month' })}>
                                        <p>Monthly</p>
                                    </div>
                                    <div onClick={() => toggleAnnualChecked(true)} className={classNames(style.tab, { [style.checked]: interval === 'year' })}>
                                        <p> {`Annual${savings ? ` (Save ${savings}%)` : ''}`}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={style.col6}>
                            <div className={style.actions}>
                                <div className={style.buttons}>
                                    {!isGift && (
                                        <P
                                            className={classNames(style['link-text'], style.gift)}
                                            onClick={() => giftCta(value)}
                                        >
                                            <span className="icon-icon_gift"></span>
                                            <span>Gift</span>
                                        </P>
                                    )}
                                    <Field
                                        {...(required && {
                                            validate: value => (value ? undefined : 'Required')
                                        })}
                                        name={name}
                                        render={(props) => (
                                            <Form.Radio
                                                {...props}
                                                className={classNames('btn',
                                                    style.btn, style.hidden,
                                                )}
                                                fieldProps={{
                                                    className: classNames('btn',
                                                        style.btn,
                                                    )
                                                }}
                                            />
                                        )}
                                        text={text}
                                        value={value}
                                        type="radio"
                                        {...rest}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Form.Field>
        </ErrorBoundary>
    )
}

export default SelectSubscription
