import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-final-form'

import EmailPasswordSocialNewslettersLogin from './EmailPasswordSocialNewslettersLogin'
import TextSignup from './TextSignup'
import CustomFields from './CustomFields'
import Newsletters from './Newsletters'
import Success from './Success'
import { SMSToggle, SMSIconToggle, EmailToggle, EmailIconToggle} from './icons'

import cx from 'classnames'
import styles from './scss/Signup.module.scss'

class ErrorBoundary extends React.Component {

    static getDerivedStateFromError(error) {
        console.log('getDerivedStateFromError', error)
        return { hasError: true }
    }

    constructor(props){
        super(props)
        this.state = { hasError: false }
    }

    componentDidCatch(error, errorInfo) {
        console.error(error.message, errorInfo)
    }

    render() {
        const { hasError } = this.state
        const { children, RenderError = () => (<div className='errorBoundaree'></div>) } = this.props

        if (hasError) {
            return (
                <RenderError />
            )
        }

        return children
    }
}

const TabIcons = {
    email: EmailIconToggle,
    sms: SMSIconToggle
}

const TabIconsWithText = {
    email: EmailToggle,
    sms: SMSToggle
}

const Signup = ({
    checkPhone,
    children,
    confirmCode,
    contrastColor,
    fieldContainerProps,
    isReturningUser,
    linkColor = '',
    onSubmitCallback = () => { },
    phoneNumber,
    previewMode = false,
    oneTimeCodeError,
    oneTimeCodeSent = false,
    subtitle,
    username,
    userPhoneMismatchError,
    access_token,
    authenticationMethods = [],
    checkEmail,
    customFieldsValid = () => true,
    email,
    isPaymentLandingPage,
    isNewRegistration,
    name = 'signup',
    newsletters = [],
    onSocialLogin,
    origin,
    popupId,
    publisherId,
    resendOneTimeCode,
    resetOneTimeCode,
    showError = false,
    showTerms = false,
    onSkipOtp,
    updateAccountError = {},
    userId,
    utm,
    defaultType,
    customFields,
    isAuthed = {
        email: false,
        sms: false
    },
    setFlow = () => { },
    markDown = () => null,
    errorMessage = {},
    loginRedirect,
    loginPhone,
    userEmail = '',
    userPhone = '',
    manageAccount = () => { },
    showSignupIcons,
} = {}) => {
    const form = useForm()

    const values = form.getState().values

    const isCustomFieldsValid = (customFieldsValid() || isAuthed.email || isAuthed.sms) ?? false

    const isAuthenticated = isAuthed?.email || isAuthed?.sms

    const tabs = [
        ...(authenticationMethods.includes('sms')
            ? ['sms']
            : []),
        ...(authenticationMethods.includes('email')
            || authenticationMethods.includes('facebook')
            || authenticationMethods.includes('twitter')
            || authenticationMethods.includes('github')
            || authenticationMethods.includes('linkedin')
            || authenticationMethods.includes('google')
            ? ['email']
            : []),
    ]

    const [currTab, setCurrTab] = useState(defaultType?.flow || defaultType?.type || tabs[0])

    useEffect(() => {
        if (previewMode) {
            setCurrTab(defaultType?.type || tabs[0])
        }
    }, [JSON.stringify(tabs), previewMode, defaultType?.type])

    const getErrorText = (text) => {
        const texts = {
            login: 'Please log in instead.',
            signup: 'Please sign up instead.',
        }

        let result = text

        // replacing instances of Please log in or sign up with HTML element
        const type =
            text.includes(texts['login'])
                ? 'login'
                : text.includes(texts['signup'])
                    ? 'signup'
                    : null

        if (loginRedirect && type) {
            const splitText = result.split(texts[type])[0]
            result = (
                <span>
                    {splitText}
                    {'Please '}
                    <span
                        className={styles.redirectText}
                        onClick={() => loginRedirect(type)}
                    >
                        {type === 'login' ? 'log in' : 'sign up'}
                    </span>
                    {' instead.'}
                </span>
            )
        }

        return result
    }

    const NewsletterSignupProps = {
        access_token,
        authenticationMethods,
        checkEmail,
        confirmCode,
        contrastColor,
        isCustomFieldsValid,
        values,
        email,
        fieldContainerProps,
        isPaymentLandingPage,
        isNewRegistration,
        isReturningUser,
        linkColor,
        name,
        newsletters,
        oneTimeCodeError,
        oneTimeCodeSent,
        onSocialLogin,
        onSubmitCallback,
        origin,
        popupId,
        previewMode,
        publisherId,
        resendOneTimeCode,
        resetOneTimeCode,
        showError,
        showTerms,
        onSkipOtp,
        updateAccountError,
        userId,
        username,
        utm,
        setFlow,
        errorMessage: errorMessage?.email,
        getErrorText,
        isAuthenticated,
    }

    const TextSignupProps = {
        checkPhone,
        confirmCode,
        contrastColor,
        fieldContainerProps,
        isReturningUser,
        linkColor,
        oneTimeCodeError,
        oneTimeCodeSent,
        onSubmitCallback,
        phoneNumber,
        previewMode,
        username,
        userPhoneMismatchError,
        values,
        defaultType,
        isCustomFieldsValid,
        setFlow,
        resetOneTimeCode,
        errorMessage: errorMessage?.phone,
        getErrorText,
        loginPhone,
        isAuthenticated,
    }

    if (!tabs.length) {
        return null
    }

    const richTextRegex = /(.*?)customtext(.*?)pico.tools(.*?)+/g

    const isSubTitleRichText = !!(subtitle || '').match(richTextRegex)

    const hasTabs = tabs.length > 1

    const hasNewsletters = !!newsletters.length
    const hasCustomFields = !!customFields.length

    return (
        <ErrorBoundary>
            <div className={styles.container}>
                {/* Only render the header when there are tabs, titles, newsletters, or custom fields, when not on otp screen */}
                {/* Only the rich text / subtitle should display when on otp screen */}
                {(!!((hasTabs || subtitle || hasNewsletters || hasCustomFields) && !oneTimeCodeSent)
                    || !!(subtitle && oneTimeCodeSent)) &&
                    <div className={styles.header_container}>
                        {!isSubTitleRichText && subtitle && <p className={styles.subtitle}>{subtitle}</p>}
                        {(isSubTitleRichText && subtitle) && markDown?.()}
                        {/* if one time password code sent do unvisible the custom fields and newsletters */}
                        <div
                            className={cx(styles.unvisible, {
                                [styles.visible]: !oneTimeCodeSent,
                            })}
                        >
                            <>
                                {hasCustomFields && !isReturningUser && !isAuthenticated &&
                                    <CustomFields fields={customFields} linkColor={linkColor} />
                                }
                                {hasNewsletters && !isReturningUser && !isAuthenticated && (
                                    <Newsletters
                                        linkColor={linkColor}
                                        previewMode={previewMode}
                                        newsletters={newsletters}
                                    />
                                )}
                                {hasTabs && (
                                    <div
                                        className={cx(styles.tabs, {
                                            [styles['tabs--authed']]: isAuthenticated,
                                            [styles['showSignupIcons']]: showSignupIcons,
                                        })}
                                        data-testid="toggle"
                                    >
                                        {tabs.map((tab, idx) => (
                                            <div
                                                key={`${tab}-${idx}`}
                                                className={cx(styles.tab, {
                                                    [styles['tab--active']]:
                                                        currTab === tab,
                                                })}
                                                onClick={() => setCurrTab(tab)}
                                            >
                                                {showSignupIcons ? (
                                                    TabIcons[tab]()
                                                ) : (
                                                    <div className={styles.iconWithText}>
                                                        {TabIconsWithText[tab]()}
                                                    </div>
                                                )}
                                                {currTab === tab &&
                                                    <div className={styles.triangle} />
                                                }
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </>
                        </div>
                    </div>
                }
                <div className={cx(styles.signup_container, {
                    [styles.otp_container]: oneTimeCodeSent,
                    [styles.showSignupIcons]: showSignupIcons
                })}>
                    <div
                        className={cx(styles.unvisible, {
                            [styles.visible]: currTab === 'email'
                        })}
                    >
                        {isAuthed?.email
                            ? <Success
                                email={userEmail}
                                contrastColor={contrastColor}
                                onClick={manageAccount}
                            />
                            : <EmailPasswordSocialNewslettersLogin
                                {...NewsletterSignupProps}
                            />
                        }
                    </div>
                    <div
                        className={cx(styles.unvisible, {
                            [styles.visible]: currTab === 'sms'
                        })}
                    >
                        {isAuthed?.sms
                            ? <Success
                                phone={userPhone}
                                contrastColor={contrastColor}
                                onClick={manageAccount}
                            />
                            : <TextSignup {...TextSignupProps} />
                        }
                    </div>
                </div>
                {children && !isAuthenticated &&
                    <div className={styles.loginText}>
                        {children}
                    </div>
                }
            </div>
        </ErrorBoundary>
    )
}

Signup.defaultProps = {
    customFields: []
}

Signup.propTypes = {
    access_token: PropTypes.string,
    authenticationMethods: PropTypes.array,
    checkEmail: PropTypes.func,
    checkPhone: PropTypes.func,
    children: PropTypes.object,
    confirmCode: PropTypes.func,
    contrastColor: PropTypes.string,
    customFields: PropTypes.array,
    customFieldsValid: PropTypes.func,
    defaultType: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    description: PropTypes.string,
    email: PropTypes.string,
    errorMessage: PropTypes.object,
    fieldContainerProps: PropTypes.any,
    hasUserLoader: PropTypes.bool,
    isAuthed: PropTypes.object,
    isNewRegistration: PropTypes.bool,
    isPaymentLandingPage: PropTypes.bool,
    isReturningUser: PropTypes.bool,
    linkColor: PropTypes.string,
    loginPhone: PropTypes.any,
    loginRedirect: PropTypes.func,
    manageAccount: PropTypes.func,
    markDown: PropTypes.func,
    name: PropTypes.string,
    newsletters: PropTypes.array,
    oneTimeCodeError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    oneTimeCodeSent: PropTypes.bool,
    onSkipOtp: PropTypes.func,
    onSocialLogin: PropTypes.func,
    onSubmitCallback: PropTypes.func,
    origin: PropTypes.string,
    phoneNumber: PropTypes.string,
    popupId: PropTypes.string,
    previewMode: PropTypes.bool,
    publisherId: PropTypes.string,
    required: PropTypes.bool,
    resendOneTimeCode: PropTypes.func,
    resetOneTimeCode: PropTypes.func,
    setFlow: PropTypes.func,
    showError: PropTypes.bool,
    showSignupIcons: PropTypes.bool,
    showTerms: PropTypes.bool,
    subtitle: PropTypes.string,
    title: PropTypes.string,
    updateAccountError: PropTypes.object,
    userEmail: PropTypes.string,
    userId: PropTypes.string,
    username: PropTypes.string,
    userPhone: PropTypes.string,
    userPhoneMismatchError: PropTypes.string,
    utm: PropTypes.string,
}

const MemoizedSignup = React.memo(Signup)

MemoizedSignup.displayName = 'FieldSignup'

export default MemoizedSignup
