import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Field, useForm } from 'react-final-form'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Boutton from 'piconetworks/pkg-boutton'
import Form from 'piconetworks/pkg-form'
import Spacing from 'piconetworks/pkg-spacing'
import Newsletters from 'piconetworks/pkg-field-newsletters'
import SocialLogin from 'piconetworks/pkg-field-social-login'
import SocialLoginButton from 'piconetworks/pkg-social-login-button'
import Otp from 'piconetworks/pkg-field-otp'
import { PICO_API_URL } from 'piconetworks/pkg-endpoints'

import style from './EmailPasswordSocialNewslettersLogin.module.scss'

const testEmail = (email) => {
    if (!email) {
        return false
    }

    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(email)
}

const fieldValidations = {
    email: (value) =>
        value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value.trim())
            ? 'Invalid email address'
            : undefined,
}

const composeValidators = (...validators) => (value) =>
    validators.reduce(
        (error, validator) => error || validator(value),
        undefined
    )

const EmailPasswordSocialNewslettersLogin = ({
    access_token,
    authenticationMethods = [],
    checkEmail,
    children,
    confirmCode,
    contrastColor = '',
    customFieldsValid = true,
    customProperties,
    description = null,
    email,
    fieldContainerProps,
    isPaymentLandingPage,
    isNewRegistration,
    isReturningUser,
    linkColor = '',
    name,
    newsletters,
    oneTimeCodeError,
    oneTimeCodeSent = false,
    onSocialLogin,
    onSubmitCallback = () => { },
    origin,
    popupId,
    previewMode = false,
    publisherId,
    resendOneTimeCode,
    resetOneTimeCode,
    shouldUpdateEmail = false,
    showError = false,
    showTerms = false,
    onSkipOtp,
    subtitle = '',
    title = '',
    updateAccountError = {},
    userId,
    username,
    utm,
    setAuthFlow = () => { },
    authFlow,
} = {}) => {
    const [errorMessage, setErrorMessage] = useState(null)
    const [selectedSSO, setSelectedSSO] = useState(null)
    const [showNewsletters, setShowNewsletters] = useState(previewMode)
    const [enterEmail, setEnterEmail] = useState(!oneTimeCodeSent || !email || !authFlow)
    const form = useForm()
    const values = form.getState().values
    const emailInputRef = useRef(null)
    const requestNewCode = useCallback(() => {
        setEnterEmail(true)
        resetOneTimeCode()
        setAuthFlow(null)
    }, [])

    useEffect(() => {
        if (showNewsletters && emailInputRef?.current && !selectedSSO && !previewMode) {
            emailInputRef.current.focus()
        }
    }, [selectedSSO, showNewsletters])

    useEffect(() => {
        setErrorMessage(updateAccountError.message)
    }, [updateAccountError])

    const processEmail = async (email) => {
        email = email && email.trim()
        try {
            if (testEmail(email)) {
                await checkEmail({
                    email,
                    customProperties,
                    selectedNewsletters: values?.newsletters || []
                })
            }
            setEnterEmail(false)
        } catch (error) {
            console.error(error)
            setErrorMessage(error.message)
        }
    }

    const onEnterEmail = (e, emailAddress) => {
        if (e.key === 'Enter' && customFieldsValid && !previewMode) {
            e.preventDefault()
            processEmail(emailAddress)
        }
    }

    const emailInputOnFocus = () => {
        setShowNewsletters(true)
        setSelectedSSO(null)
    }

    const handleDummySSOClick = (provider) => {
        setSelectedSSO(provider)
    }

    const shouldRenderNewsletters = !isReturningUser

    const disabled = !customFieldsValid

    const renderSignupButton = () => (
        <Boutton
            variant="primary"
            onClick={(e) => {
                e.preventDefault()
                const emailValue = emailInputRef?.current?.value
                if (!previewMode) {
                    if (!isReturningUser) {
                        onSubmitCallback({
                            end_user_email: emailValue,
                            sign_up_method: selectedSSO || 'email',
                        })
                    }
                    processEmail(emailValue)
                }
            }}
            disabled={(
                !previewMode &&
                ((typeof emailInputRef?.current?.value === 'undefined' ||
                    emailInputRef?.current?.value === '') && !selectedSSO) ||
                disabled
            )}
            style={{
                color: contrastColor
            }}
            className={cx(style.signupButton, {
                [style.loginButton]: isReturningUser,
                [style.updateButton]: shouldUpdateEmail
            })}
        >
            {!isReturningUser ? `Sign up${selectedSSO ? ` with ${selectedSSO}` : ''}` : 'Log in'}
        </Boutton>
    )

    const socialLoginProps = {
        authenticationMethods,
        fieldContainerProps,
        origin,
        params: {
            custom_properties: customProperties,
            form_id: popupId,
            newsletter_ids: values?.newsletters,
            utmParams: utm,
        },
        name,
        onLogin: onSocialLogin,
        publisherId,
        userId,
        access_token,
        scope: 'user',
        linkColor,
    }

    const hasEmail = authenticationMethods.includes('email')
    const showEmailInput = previewMode || enterEmail
    const allowSkipOtp = isNewRegistration && isPaymentLandingPage

    return (
        <section className={cx({ [style.isPreview]: previewMode })}>
            {description && (<p>{description}</p>)}
            <>
                <div className={style.container}>
                    {!oneTimeCodeSent && title && <h2 className={style.title}>{title}</h2>}
                    {!oneTimeCodeSent && subtitle && <p className={style.subtitle}>{subtitle}</p>}
                    {hasEmail &&
                        <>
                            {showEmailInput && (
                                <div className={style.field}>
                                    <label className={style.label}>
                                        Enter your email
                                    </label>
                                    <Field
                                        {...fieldContainerProps}
                                        name={`${name}.emailAddress`}
                                        type="text"
                                        placeholder="name@domain.com"
                                        validate={composeValidators(
                                            fieldValidations.email
                                        )}
                                    >
                                        {({ meta, input, ...rest }) => (
                                            <div className={style.emailInput}>
                                                <Form.Input
                                                    {...rest}
                                                    meta={meta}
                                                    ref={emailInputRef}
                                                    input={input}
                                                    onFocus={emailInputOnFocus}
                                                    onKeyDown={(e) => onEnterEmail(e, e.target.value)}
                                                />
                                            </div>
                                        )}
                                    </Field>
                                </div>
                            )}
                            {!showEmailInput && (
                                <Otp
                                    checkEmail={checkEmail}
                                    confirmCode={confirmCode}
                                    contrastColor={contrastColor}
                                    customProperties={customProperties}
                                    email={email}
                                    fieldContainerProps={fieldContainerProps}
                                    isNewRegistration={isNewRegistration}
                                    linkColor={linkColor}
                                    newsletters={values?.newsletters || []}
                                    oneTimeCodeError={oneTimeCodeError}
                                    oneTimeCodeSent={oneTimeCodeSent}
                                    onSkipOtp={onSkipOtp}
                                    requestNewCode={requestNewCode}
                                    resendOneTimeCode={resendOneTimeCode}
                                    allowSkipOtp={allowSkipOtp}
                                />
                            )}
                        </>
                    }
                    {!shouldUpdateEmail && showEmailInput && !!(authenticationMethods.filter(method => method !== 'email').length) && (
                        <div className={style.social}>
                            <Spacing.HorizontalDivider withLine>
                                {hasEmail && 'OR '}
                                {isReturningUser ? 'LOG IN ' : 'SIGN UP '}
                                WITH
                            </Spacing.HorizontalDivider>
                            <SocialLogin
                                {...fieldContainerProps}
                                {...socialLoginProps}
                                handleClick={handleDummySSOClick}
                                previewMode={!isReturningUser || previewMode}
                                selected={selectedSSO}
                            />
                        </div>
                    )}
                    <div
                        className={cx(style.newsletters_container, {
                            [style['newsletters_container--visible']]: showNewsletters || selectedSSO
                        })}
                    >
                        {shouldRenderNewsletters && showEmailInput && !!newsletters.length && (
                            <>
                                <label>Select newsletters</label>
                                <Newsletters
                                    name={'newsletters'}
                                    linkColor={linkColor}
                                    newsletterFilter={'preselected'}
                                    newsletters={newsletters}
                                    previewMode={previewMode}
                                />
                            </>
                        )}
                        {selectedSSO
                            ? (
                                <SocialLoginButton
                                    {...socialLoginProps}
                                    type={selectedSSO.toLowerCase()}
                                    api_url={PICO_API_URL()}
                                    button={renderSignupButton()}
                                    onClick={() => !isReturningUser && onSubmitCallback({
                                        sign_up_method: selectedSSO,
                                    })}
                                    previewMode={previewMode}
                                />
                            ) : showEmailInput
                                ? renderSignupButton() : null
                        }
                    </div>
                    {children}
                </div>
                {!!errorMessage && showError && (
                    <div className={style.error}>
                        {errorMessage}
                    </div>
                )}
                {showTerms && (
                    <div className={style.terms}>
                        By signing up, you agree to{' '}
                        <a href="https://hype.co/terms/user-agreement" target='_blank' rel='noopener noreferrer'>Hype&rsquo;s Terms</a>
                        {' '} and acknowledge the <a href="https://hype.co/terms/privacy-policy" target='_blank' rel='noopener noreferrer'>Privacy Policy</a>.
                        {' '} You also agree that {username} may send you marketing emails.
                    </div>
                )}
            </>
        </section>
    )
}

EmailPasswordSocialNewslettersLogin.propTypes = {
    access_token: PropTypes.string,
    authenticationMethods: PropTypes.array,
    authFlow: PropTypes.string,
    checkEmail: PropTypes.func,
    children: PropTypes.object,
    confirmCode: PropTypes.func,
    contrastColor: PropTypes.string,
    customFieldsValid: PropTypes.bool,
    customProperties: PropTypes.object,
    description: PropTypes.string,
    email: PropTypes.string,
    fieldContainerProps: PropTypes.any,
    hasUserLoader: PropTypes.bool,
    isReturningUser: PropTypes.bool,
    linkColor: PropTypes.string,
    name: PropTypes.string,
    newsletters: PropTypes.array,
    oneTimeCodeError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    oneTimeCodeSent: PropTypes.bool,
    onSocialLogin: PropTypes.func,
    onSubmitCallback: PropTypes.func,
    origin: PropTypes.string,
    popupId: PropTypes.string,
    previewMode: PropTypes.bool,
    publisherId: PropTypes.string,
    required: PropTypes.bool,
    setAuthFlow: PropTypes.func,
    setShowNewsletters: PropTypes.func,
    shouldUpdateEmail: PropTypes.bool,
    showError: PropTypes.bool,
    showNewsletters: PropTypes.bool,
    showTerms: PropTypes.bool,
    subtitle: PropTypes.string,
    title: PropTypes.string,
    updateAccountError: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    userId: PropTypes.string,
    username: PropTypes.string,
    utm: PropTypes.string,
}

const MemoizedEmailPasswordSocialNewslettersLogin = React.memo(EmailPasswordSocialNewslettersLogin)

export default MemoizedEmailPasswordSocialNewslettersLogin
