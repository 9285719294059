import React from 'react'
import PropTypes from 'prop-types'

import Boutton from 'piconetworks/pkg-boutton'

import styles from '../scss/Success.module.scss'

const Icon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="none"
        viewBox="0 0 40 40"
    >
        <path
            fill="#28BF6F"
            fillRule="evenodd"
            d="M20 40c11.046 0 20-8.954 20-20S31.046 0 20 0 0 8.954 0 20s8.954 20 20 20zM8.637 17.747a2.32 2.32 0 00.002 3.195l6.98 7.393c.854.89 2.222.883 3.07 0l12.677-13.452c.84-.874.85-2.32.003-3.208-.853-.895-2.215-.898-3.057-.022l-11.159 11.87-5.44-5.771a2.118 2.118 0 00-3.076-.005z"
            clipRule="evenodd"
        ></path>
    </svg>
)

export const Success = ({
    onClick = () => { },
    email = '',
    phone = '',
    contrastColor,
}) => (
    <div className={styles.container}>
        <Icon />
        <p>
            You&apos;re signed up for {email ? 'email' : 'text'} updates at
            <br />
            {email || phone}.
        </p>
        <Boutton
            variant="primary"
            onClick={(e) => {
                e.preventDefault()
                onClick()
            }}
            style={{
                color: contrastColor
            }}
            className={styles.button}
        >
            Manage Account
        </Boutton>
    </div>
)

Success.propTypes = {
    contrastColor: PropTypes.string,
    email: PropTypes.string,
    onClick: PropTypes.func,
    phone: PropTypes.string,
}

const MemoizedSuccess = React.memo(Success)

MemoizedSuccess.displayName = 'FieldSignupSuccess'

export default MemoizedSuccess
