import React from 'react'

import styles from '../scss/CustomFields.module.scss'

// interface Props {
//     fields: React.ReactNode[]
//     linkColor: string
// }

export const CustomFields = ({ fields = [], linkColor = '' }) => (
    <div className={styles.container}>
        {fields.map((Field, idx) => (
            <Field key={idx} linkColor={linkColor} />
        ))}
    </div>
)

const MemoizedCustomFields = React.memo(CustomFields)

MemoizedCustomFields.displayName = 'FieldSignupCustomFields'

export default MemoizedCustomFields
