import React from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'

import Form from 'piconetworks/pkg-form'

const Number = ({
    fieldContainerProps,
    description = null,
    required,
    previewMode = false,
    input,
    ...rest
} = {}) => {
    const TextField = (
        <Form.Input
            input={{
                ...input,
                ...(previewMode && { readOnly: true })
            }}
        />
    )

    return (
        <Form.Field {...fieldContainerProps}>
            <p>{description}</p>
            <Field
                component={!previewMode ? Form.Input : () => TextField}
                type="number"
                {...(required && {
                    validate: (value) => (value ? undefined : 'Required')
                })}
                {...rest}
            />
        </Form.Field>
    )
}

Number.propTypes = {
    fieldContainerProps: PropTypes.any,
    description: PropTypes.string,
    required: PropTypes.bool,
    previewMode: PropTypes.bool,
    input: PropTypes.any
}

export default Number
