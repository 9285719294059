import React from 'react'
import PropTypes from 'prop-types'

import Form from 'piconetworks/pkg-form'
import ChooseNewsletter from 'piconetworks/pkg-choose-newsletter'

const Newsletters = ({
    fieldContainerProps,
    defaultValue,
    linkColor,
    newsletterFilter,
    newsletters = [],
    previewMode,
    showMore,
} = {}) => (
    <div style={{ position: 'relative' }}>
        <Form.Field
            {...fieldContainerProps}
            style={{
                marginBottom: 0
            }}
        >
            <ChooseNewsletter
                currentProductOptionId={null}
                newsletters={newsletters}
                selectedTier={null}
                groupTier={null}
                containerType={null}
                disableGroup={null}
                newsletterFilter={newsletterFilter}
                onHasNewsletters={() => { }}
                onHasNoNewsletters={() => { }}
                isSingleColumn={true}
                isReadyToMount={true}
                linkColor={linkColor}
                defaultValue={defaultValue}
                previewMode={previewMode}
                showMore={showMore}
            />
        </Form.Field>
    </div>
)

Newsletters.propTypes = {
    newsletters: PropTypes.arrayOf(
        PropTypes.shape({
            classification: PropTypes.string,
            access_tier_ids: PropTypes.object
        })
    ),
    newsletterFilter: PropTypes.string,
    defaultValue: PropTypes.array,
    linkColor: PropTypes.string,
    previewMode: PropTypes.bool,
}


export default Newsletters
