import PropTypes from 'prop-types'
import React from 'react'
import cx from 'classnames'
import styles from './PageLink.module.scss'

const PageLink = React.forwardRef(({
    className,
    image,
    subtitle,
    title,
    url,
    ...props
}, ref) => {
    const classes = cx(
        styles.pageLink,
        className
    )

    return (
        <a className={classes} {...props} ref={ref} href={url} target="_blank" rel="noopener noreferrer">
            {image && <div className={styles.imageContainer}>
                <div className={styles.imageWrapper} style={{ backgroundImage: `url(${image})` }}>
                </div>
            </div>}
            <div
                className={cx(
                    styles.textWrapper,
                    [image && 'block-border-radius'],
                    // ...(!image && 'block-border-radius')
                )}
            >
                {title && <h3>{title}</h3>}
                {subtitle && <p>{subtitle}</p>}
            </div>
        </a>
    )
})

PageLink.propTypes = {
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    image: PropTypes.oneOfType([PropTypes.boolean, PropTypes.string]),
    subtitle: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string.isRequired,
}

PageLink.displayName = 'PageLink'

export default PageLink
