import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import moment from 'moment'
import 'react-dates/initialize'
import { DateRangePicker } from 'react-dates'

import Icon from 'piconetworks/pkg-icon'
import Form from 'piconetworks/pkg-form'
import style from './Date.module.scss'

const leftArrow = (
    <Icon.Default.ArrowLeft height={24} width={24} color="#7A34FF" />
)

const rightArrow = (
    <Icon.Default.ArrowRight height={24} width={24} color="#7A34FF" />
)

class DateRange extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            startDate: moment(props.input && props.input.value[0]).startOf(
                'day'
            ),
            endDate: moment(props.input && props.input.value[1]).endOf('day'),
            focusedInput: null
        }
    }

    componentDidMount() {
        if (!Array.isArray(this.props.input.value)) {
            const startDate = moment().startOf('day')
            const endDate = moment().endOf('day')
            this.props.input.onChange([new Date(startDate), new Date(endDate)])
        }
    }

    handleChangeValue = (startDate, endDate) => {
        const { input } = this.props
        this.setState(
            {
                startDate,
                endDate
            },
            () => {
                if (input) {
                    const start = moment(startDate).startOf('day')
                    const end = moment(endDate).endOf('day')
                    input.onChange([new Date(start), new Date(end)])
                }
            }
        )
    }

    render() {
        const { startDate, endDate, focusedInput } = this.state
        const {
            meta,
            label,
            required,
            endDateId,
            startDateId,
            formFieldClassName,
            className,
            disabled,
            datePickerProps,
            fieldProps,
            input,
            ...args
        } = this.props

        return (
            <Form.Field
                label={label}
                required={required}
                meta={meta}
                input={input}
                className={formFieldClassName}
                {...fieldProps}
            >
                <div
                    className={cx(
                        style.datePicker,
                        { [style.error]: meta && meta.error },
                        className
                    )}
                    {...args}
                >
                    <DateRangePicker
                        navPrev={leftArrow}
                        navNext={rightArrow}
                        displayFormat={'MM / DD / YYYY'}
                        dayAriaLabelFormat={'MM / DD / YYYY'}
                        isOutsideRange={() => false}
                        hideKeyboardShortcutsPanel
                        disabled={disabled}
                        customArrowIcon={
                            <Icon.Default.ArrowRight2
                                height={18}
                                width={18}
                                fill={
                                    meta && meta.error && focusedInput === null
                                        ? '#FF4440'
                                        : '#120A20'
                                }
                            />
                        }
                        customInputIcon={
                            <Icon.Default.Calendar2 width={24} height={24} />
                        }
                        numberOfMonths={1}
                        startDate={startDate}
                        startDateId={startDateId}
                        endDate={endDate}
                        endDateId={endDateId}
                        onDatesChange={({ startDate, endDate }) =>
                            this.handleChangeValue(startDate, endDate)
                        }
                        focusedInput={focusedInput}
                        onFocusChange={(focusedInput) =>
                            this.setState({ focusedInput })
                        }
                        {...datePickerProps}
                    />
                </div>
            </Form.Field>
        )
    }
}

DateRange.defaultProps = {
    disabled: false,
    endDateId: 'End_date_unique_id',
    startDateId: 'Start_date_unique_id'
}

DateRange.propTypes = {
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    datePickerProps: PropTypes.object,
    disabled: PropTypes.oneOfType([
        PropTypes.oneOf(['startDate', 'endDate']),
        PropTypes.bool
    ]),
    endDateId: PropTypes.string,
    fieldProps: PropTypes.object,
    /** This will be deprecated eventually. Instead pass className to the fieldProps */
    formFieldClassName: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    input: PropTypes.object,
    label: PropTypes.node,
    meta: PropTypes.object,
    required: PropTypes.bool,
    startDateId: PropTypes.string
}

export default DateRange
