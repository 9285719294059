import React from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'

import Form from 'piconetworks/pkg-form'

const Dropdown = ({
    fieldContainerProps,
    description = null,
    required,
    contrastColor,
    themeColor,
    ...rest
} = {}) => (
    <Form.Field
        {...fieldContainerProps}
    >
        <p>{description}</p>
        <Field
            component={Form.Dropdown}
            contrastColor={contrastColor}
            themeColor={themeColor}
            type="text"
            {...(required && {
                validate: value => (value ? undefined : 'Required')
            })}
            {...rest}
        />
    </Form.Field>
)

Dropdown.propTypes = {
    fieldContainerProps: PropTypes.shape({
        label: PropTypes.string,
        required: PropTypes.boolean
    }),
    description: PropTypes.string,
    required: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.boolean
    ]),
    contrastColor: PropTypes.string,
    themeColor: PropTypes.string
}

export default Dropdown
