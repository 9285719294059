import React from 'react'
import PropTypes from 'prop-types'
import { H3, H4 } from 'piconetworks/pkg-heading'
import styles from './scss/Summary.module.scss'
import Label from './lib/Label'

const GiftExistingConvert = (props) => {
    return (
        <>
            {!!props.labels?.length && (
                <div className={styles.labels}>
                    {props.labels.map((label, i) => (
                        <Label key={i} {...label} />
                    ))}
                </div>
            )}

            <div className={styles.summary}>
                <div>
                    <div className={styles.productDetails}>
                        <H3>{props.productTitle}</H3>
                        <H4>{props.optionTypeAndInterval}</H4>
                        <p>Renews {props.renewalDate}</p>
                    </div>

                    <div className={styles.priceDetails}>
                        <H3>{props.optionPrice}</H3>
                        {props.optionInterval && (
                            <p>per {props.optionInterval}</p>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

GiftExistingConvert.propTypes = {
    labels: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            color: PropTypes.oneOf(['gray', 'green'])
        })
    ),

    productTitle: PropTypes.string,
    optionTypeAndInterval: PropTypes.string,
    renewalDate: PropTypes.string,

    optionPrice: PropTypes.string,
    optionInterval: PropTypes.string
}

export default GiftExistingConvert
