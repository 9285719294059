import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Boutton from 'piconetworks/pkg-boutton'
import Form from 'piconetworks/pkg-form'
import Spacing from 'piconetworks/pkg-spacing'
import Otp from 'piconetworks/pkg-field-otp'
import SocialLogin from 'piconetworks/pkg-field-social-login'
import SocialLoginButton from 'piconetworks/pkg-social-login-button'

import { PICO_API_URL } from 'piconetworks/pkg-endpoints'

import style from '../scss/EmailPasswordSocialNewslettersLogin.module.scss'

class ErrorBoundary extends React.Component {

    static getDerivedStateFromError(error) {
        console.log('getDerivedStateFromError', error)
        return { hasError: true }
    }

    constructor(props){
        super(props)
        this.state = { hasError: false }
    }

    componentDidCatch(error, errorInfo) {
        console.error(error.message, errorInfo)
    }

    render() {
        const { hasError } = this.state
        const { children, RenderError = () => (<div className='errorBoundaree'></div>) } = this.props

        if (hasError) {
            return (
                <RenderError />
            )
        }

        return children
    }
}

const testEmail = (email) => {
    if (!email) {
        return false
    }

    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(email)
}

const fieldValidations = {
    email: (value) =>
        value &&
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value.trim())
            ? 'Invalid email address'
            : undefined
}

const composeValidators =
    (...validators) =>
        (value) =>
            validators.reduce(
                (error, validator) => error || validator(value),
                undefined
            )

const invalidCustomFieldsError = 'Please fill out the required fields above.'

const EmailPasswordSocialNewslettersLogin = ({
    access_token,
    authenticationMethods = [],
    checkEmail,
    confirmCode,
    contrastColor = '',
    isCustomFieldsValid = true,
    values,
    email,
    fieldContainerProps,
    isPaymentLandingPage,
    isNewRegistration,
    isReturningUser,
    linkColor = '',
    name,
    oneTimeCodeError,
    oneTimeCodeSent = false,
    onSocialLogin,
    onSubmitCallback = () => { },
    origin,
    popupId,
    previewMode = false,
    publisherId,
    resendOneTimeCode,
    resetOneTimeCode,
    showError = false,
    showTerms = false,
    onSkipOtp,
    updateAccountError = {},
    userId,
    username,
    utm,
    setFlow,
    errorMessage: _errorMessage,
    getErrorText = (e) => e,
    isAuthenticated,
} = {}) => {
    const [errorMessage, setErrorMessage] = useState(_errorMessage)
    const [selectedSSO, setSelectedSSO] = useState(null)
    const [enterEmail, setEnterEmail] = useState(!oneTimeCodeSent || !email)

    const emailInputRef = useRef(null)
    const requestNewCode = useCallback(() => {
        setEnterEmail(true)
        resetOneTimeCode()
    }, [])

    useEffect(() => {
        setErrorMessage(_errorMessage)
    }, [_errorMessage])

    useEffect(() => {
        if (selectedSSO) {
            setErrorMessage(null)
        }
    }, [selectedSSO])

    useEffect(() => {
        if (updateAccountError.message) {
            setErrorMessage(updateAccountError.message)
        }
    }, [updateAccountError])

    const processEmail = async (email) => {
        email = email && email.trim()

        try {
            if (isCustomFieldsValid || isReturningUser) {
                setFlow({ flow: 'email' })
                if (testEmail(email)) {
                    await checkEmail({
                        email,
                        customProperties: values?.customFields,
                        selectedNewsletters: values?.newsletters || [],
                        shouldUpdate: isAuthenticated
                    })
                }
                setEnterEmail(false)
            } else {
                setErrorMessage(invalidCustomFieldsError)
            }
        } catch (error) {
            setErrorMessage(error.message)
        }
    }

    const onEnterEmail = (e, emailAddress) => {
        if (errorMessage) {
            setErrorMessage(null)
        }
        if (e.key === 'Enter' && !previewMode) {
            e.preventDefault()
            processEmail(emailAddress)
        }
    }

    const emailInputOnFocus = () => {
        setSelectedSSO(null)
    }

    const handleDummySSOClick = (provider) => {
        setSelectedSSO(provider)
    }

    const renderSignupButton = () => (
        <Boutton
            variant="primary"
            onClick={(e) => {
                e.preventDefault()
                const emailValue = emailInputRef?.current?.value
                if (!previewMode) {
                    if (!isReturningUser) {
                        onSubmitCallback({
                            end_user_email: emailValue,
                            sign_up_method: selectedSSO || 'email'
                        })
                    }
                    processEmail(emailValue)
                }
            }}
            disabled={
                (!previewMode &&
                    (typeof emailInputRef?.current?.value === 'undefined' ||
                        emailInputRef?.current?.value === '') &&
                    !selectedSSO)
            }
            
            className={cx(style.signupButton, {
                [style.loginButton]: isReturningUser
            })}
        >
            {!isReturningUser
                ? `Sign up${selectedSSO ? ` with ${selectedSSO}` : ''}`
                : 'Log in'}
        </Boutton>
    )

    const socialLoginProps = {
        authenticationMethods,
        fieldContainerProps,
        origin,
        params: {
            custom_properties: values?.customFields,
            form_id: popupId,
            newsletter_ids: values?.newsletters,
            utmParams: utm,
            is_logging_in: isReturningUser,
        },
        name,
        onLogin: (response, meta) => {
            setErrorMessage(null)
            onSocialLogin(response, meta)
        },
        publisherId,
        userId,
        access_token,
        scope: 'user',
        linkColor,
    }

    const hasEmail = authenticationMethods.includes('email')
    const showEmailInput = previewMode || enterEmail
    const allowSkipOtp = isNewRegistration && isPaymentLandingPage

    return (
        <ErrorBoundary>
            <section
                className={cx({ [style.isPreview]: previewMode })}
                data-testid="email-signup"
            >
                <>
                    <div className={style.container}>
                        {hasEmail && (
                            <>
                                {showEmailInput && (
                                    <div className={style.field}>
                                        <Field
                                            {...fieldContainerProps}
                                            name={`${name}.emailAddress`}
                                            type="text"
                                            placeholder="name@domain.com"
                                            validate={composeValidators(
                                                fieldValidations.email
                                            )}
                                        >
                                            {({ meta, input, ...rest }) => (
                                                <div className={style.emailInput}>
                                                    <Form.Input
                                                        {...rest}
                                                        meta={meta}
                                                        ref={emailInputRef}
                                                        input={input}
                                                        onFocus={emailInputOnFocus}
                                                        onKeyDown={(e) =>
                                                            onEnterEmail(
                                                                e,
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </Field>
                                    </div>
                                )}
                                {!showEmailInput && (
                                    <Otp
                                        checkEmail={checkEmail}
                                        confirmCode={confirmCode}
                                        contrastColor={contrastColor}
                                        customProperties={values?.customFields}
                                        email={email}
                                        fieldContainerProps={fieldContainerProps}
                                        isNewRegistration={isNewRegistration}
                                        linkColor={linkColor}
                                        newsletters={values?.newsletters || []}
                                        oneTimeCodeError={oneTimeCodeError}
                                        oneTimeCodeSent={oneTimeCodeSent}
                                        onSkipOtp={onSkipOtp}
                                        requestNewCode={requestNewCode}
                                        resendOneTimeCode={resendOneTimeCode}
                                        allowSkipOtp={allowSkipOtp}
                                    />
                                )}
                            </>
                        )}
                        <div
                            className={style.signup_button}
                        >
                            {selectedSSO ? (
                                <SocialLoginButton
                                    {...socialLoginProps}
                                    type={selectedSSO.toLowerCase()}
                                    api_url={PICO_API_URL()}
                                    button={renderSignupButton()}
                                    onClick={() => {
                                        if (!isCustomFieldsValid) {
                                            setErrorMessage(invalidCustomFieldsError)
                                        } else {
                                            setErrorMessage(null)
                                        }
                                        !isReturningUser &&
                                            onSubmitCallback({
                                                sign_up_method: selectedSSO
                                            })
                                    }}
                                    previewMode={previewMode || !isCustomFieldsValid}
                                    onFailure={(error) => error?.error && setErrorMessage(error.error)}
                                    isReturningUser={isReturningUser}
                                />
                            ) : showEmailInput ? (
                                <>
                                    {!!errorMessage && showError && (
                                        <div className={style.error}>{getErrorText(errorMessage)}</div>
                                    )}
                                    {renderSignupButton()}
                                </>
                            ) : null}
                        </div>
                        {showEmailInput &&
                            !!authenticationMethods.filter((method) =>
                                method !== 'email' && method !== 'sms'
                            ).length
                            && (
                                <div className={style.social}>
                                    <Spacing.HorizontalDivider withLine className={style.loginDivider}>
                                        {hasEmail && 'OR '}
                                        {isReturningUser ? 'LOG IN ' : 'SIGN UP '}
                                        WITH
                                    </Spacing.HorizontalDivider>
                                    {selectedSSO && showError && !!errorMessage && (
                                        <div className={style.error}>{getErrorText(errorMessage)}</div>
                                    )}
                                    <SocialLogin
                                        {...fieldContainerProps}
                                        {...socialLoginProps}
                                        handleClick={handleDummySSOClick}
                                        previewMode={
                                            !isReturningUser || previewMode
                                        }
                                        selected={selectedSSO}
                                        onFailure={(error) => error?.error && setErrorMessage(error.error)}
                                    />
                                </div>
                            )
                        }
                        {showTerms && (
                            <div className={style.terms}>
                                By signing up, you agree to{' '}
                                <a href="https://hype.co/terms/user-agreement" target='_blank' rel='noopener noreferrer'>Hype&rsquo;s Terms</a>
                                {' '} and acknowledge the <a href="https://hype.co/terms/privacy-policy" target='_blank' rel='noopener noreferrer'>Privacy Policy</a>.
                                {' '} You also agree that {username} may send you marketing emails.
                            </div>
                        )}
                    </div>
                </>
            </section >
        </ErrorBoundary >
    )
}

EmailPasswordSocialNewslettersLogin.propTypes = {
    access_token: PropTypes.string,
    authenticationMethods: PropTypes.array,
    checkEmail: PropTypes.func,
    confirmCode: PropTypes.func,
    contrastColor: PropTypes.string,
    email: PropTypes.string,
    errorMessage: PropTypes.string,
    fieldContainerProps: PropTypes.any,
    getErrorText: PropTypes.func,
    hasUserLoader: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    isCustomFieldsValid: PropTypes.bool,
    isNewRegistration: PropTypes.bool,
    isPaymentLandingPage: PropTypes.bool,
    isReturningUser: PropTypes.bool,
    linkColor: PropTypes.string,
    name: PropTypes.string,
    newsletters: PropTypes.array,
    oneTimeCodeError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    oneTimeCodeSent: PropTypes.bool,
    onSkipOtp: PropTypes.func,
    onSocialLogin: PropTypes.func,
    onSubmitCallback: PropTypes.func,
    origin: PropTypes.string,
    popupId: PropTypes.string,
    previewMode: PropTypes.bool,
    publisherId: PropTypes.string,
    required: PropTypes.bool,
    resendOneTimeCode: PropTypes.func,
    resetOneTimeCode: PropTypes.func,
    setFlow: PropTypes.func,
    showError: PropTypes.bool,
    showTerms: PropTypes.bool,
    updateAccountError: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    userId: PropTypes.string,
    username: PropTypes.string,
    utm: PropTypes.string,
    values: PropTypes.object
}


const MemoizedEmailPasswordSocialNewslettersLogin = React.memo(EmailPasswordSocialNewslettersLogin)

MemoizedEmailPasswordSocialNewslettersLogin.displayName = 'FieldSignupEmailPasswordSocialNewslettersLogin'

export default MemoizedEmailPasswordSocialNewslettersLogin
