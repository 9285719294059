import React from 'react'
import { Field } from 'react-final-form'
import classNames from 'classnames'

import { H3 } from 'piconetworks/pkg-heading'
import { P } from 'piconetworks/pkg-text'
import { format } from 'piconetworks/pkg-currency'
import { RemoteJsonText } from 'piconetworks/pkg-remote-markdown'
import { selectors } from 'piconetworks/pkg-module-loader'
import Form from 'piconetworks/pkg-form'

import style from './scss/SelectPass.module.scss'


const SelectPass = (props) => {
    const {
        fieldContainerProps,
        required,
        popup,
        name,
        linkColor = '',
        contrastColor = '',
        isGift = false,
        product = {},
        className,
        giftCta = () => { },
        customFeaturesUrl = '',
        ...rest
    } = props

    const title = product?.title
    const formattedOptions = product?.options
        ?.reduce((selections, {
            id: value,
            interval_quantity,
            interval,
            price: amount,
            currency: currencyCode,
        }) => {
            const price = format({
                currencyCode,
                amount,
                symbol: true,
            })

            const text = isGift
                ? `Gift the first ${interval}`
                : 'Buy Pass'

            selections['pass'] = {
                value,
                text,
                price,
                amount,
                interval_quantity,
                interval
            }

            return selections
        }, {}) || []

    const selectedPlan = formattedOptions?.pass

    const text = selectedPlan?.text
    const price = selectedPlan?.price
    const value = selectedPlan?.value

    return (
        <Form.Field
            {...fieldContainerProps}
        >
            <div className={classNames(style.container, className)}>
                {title && (
                    <div className={style.header}>
                        <H3 className={style.title}>{title}</H3>
                        <div className={style.priceContainer}>
                            <H3 className={style.price}>{price}</H3>
                            <small>for {selectedPlan.interval_quantity} {selectedPlan.interval}</small>
                        </div>
                    </div>
                )}
                <div className={style.dflex}>
                    <div className={style.col6}>
                        <div className={style.features}>
                            {customFeaturesUrl && <RemoteJsonText url={customFeaturesUrl} style={style} linkColor={linkColor} />}
                        </div>
                    </div>
                    <div className={style.col6}>
                        <div className={style.actions}>

                            <div className={style.buttons}>
                                {!isGift && (
                                    <P
                                        className={classNames(style['link-text'], style.gift)}
                                        onClick={() => giftCta(value)}
                                    >
                                        <span className="icon-icon_gift"></span>
                                        <span>Gift</span>
                                    </P>
                                )}

                                <Field
                                    {...(required && {
                                        validate: value => (value ? undefined : 'Required')
                                    })}
                                    name={name}
                                    render={(props) => (
                                        <Form.Radio
                                            {...props}
                                            className={classNames('btn',
                                                style.btn, style.hidden
                                            )}
                                            fieldProps={{
                                                className: classNames('btn',
                                                    style.btn,
                                                )
                                            }}
                                        />
                                    )}
                                    text={text}
                                    value={value}
                                    type="radio"
                                    {...rest}
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Form.Field>
    )
}

export default SelectPass
