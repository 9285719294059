import React from 'react'
import PropTypes from 'prop-types'
import { H3, H4 } from 'piconetworks/pkg-heading'
import styles from './scss/Summary.module.scss'
import Label from './lib/Label'

const PassNewRegular = (props) => {
    return (
        <>
            {!!props.labels?.length && (
                <div className={styles.labels}>
                    {props.labels.map((label, i) => (
                        <Label key={i} {...label} />
                    ))}
                </div>
            )}

            <div className={styles.summary}>
                <div>
                    <div className={styles.productDetails}>
                        <H3>{props.productTitle}</H3>
                        <H4>{props.passDuration}</H4>
                    </div>

                    <div className={styles.priceDetails}>
                        {props.passPrice && (
                            <>
                                <H3>
                                    {props.oldPassPrice && (
                                        <s>{props.oldPassPrice}</s>
                                    )}
                                    {props.passPrice}
                                </H3>
                                {props.passDuration && (
                                    <p>for {props.passDuration}</p>
                                )}
                            </>
                        )}

                        {props.passPrice && props.optionPrice && (
                            <div className={styles.separator} />
                        )}

                        {props.optionPrice && (
                            <>
                                <H3>
                                    {props.passPrice && <span>then</span>}
                                    {props.oldOptionPrice && (
                                        <s>{props.oldOptionPrice}</s>
                                    )}
                                    {props.optionPrice}
                                </H3>
                                {props.optionInterval && (
                                    <p>per {props.optionInterval}</p>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

PassNewRegular.propTypes = {
    labels: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            color: PropTypes.oneOf(['gray', 'green'])
        })
    ),

    productTitle: PropTypes.string,
    passDuration: PropTypes.string,
    optionPrice: PropTypes.string,
    optionTypeAndInterval: PropTypes.string,
    renewalDate: PropTypes.string,
    optionInterval: PropTypes.string,
    oldOptionPrice: PropTypes.string,
    passPrice: PropTypes.string,
    oldPassPrice: PropTypes.string
}

export default PassNewRegular
