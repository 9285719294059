import React, { useEffect, useState, useRef } from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'
import cx from 'classnames'
import intlTelInput from 'intl-tel-input'

import Boutton from 'piconetworks/pkg-boutton'
import Form from 'piconetworks/pkg-form'

import styles from './TextSignup.module.scss'

const PhoneField = ({
    checkPhone,
    contrastColor,
    enterOTP,
    fieldContainerProps,
    isReturningUser,
    oneTimeCodeError,
    onSubmitCallback = () => { },
    previewMode,
    userPhoneMismatchError,
}) => {
    const [errorMessage, setErrorMessage] = useState(null)
    const [phoneInputError, setPhoneInputError] = useState(null)

    const phoneInputRef = useRef(null)

    useEffect(() => {
        if (phoneInputRef?.current)  {
            const iti = intlTelInput(phoneInputRef.current, {
                onlyCountries: ['us', 'ca'],
                preferredCountries: ['us'],
                utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.3/build/js/utils.js',
            })

            window.iti = iti
        }
    }, [phoneInputRef.current])

    useEffect(() => {
        setErrorMessage(oneTimeCodeError)
    }, [oneTimeCodeError])

    useEffect(() => {
        setPhoneInputError(userPhoneMismatchError)
    }, [userPhoneMismatchError])

    const error = phoneInputError || errorMessage

    const number = window?.iti?.getNumber() || null

    const shouldShowError = (phoneInput) => {
        // intl-input number validates numbers with letters if it fits the country's
        // number length, so we must check the raw input as well as the formatted input
        // they provide. shouldShowError is true if intl-input number validation does not pass
        // or input value contains anything other than +-() and numbers
        return (number && !window.iti.isValidNumber()) || !/^(?=.*[0-9])[- +()0-9]+$/.test(phoneInput)
    }

    const resolvePhoneInputError = () => {
        switch (window.iti.getValidationError()) {
            case 1:
                return 'Invalid Country Code'
            case 2:
                return 'Number is too short'
            case 3:
                return 'Number is too long'
            case 5:
                return 'Number length is invalid'
            default:
                return 'Invalid number'
        }
    }

    const handlePhoneErrorReset = () => {
        if (error) {
            setErrorMessage(null)
            setPhoneInputError(null)
        }
    }

    const processNumber = async (phone) => {
        phone = phone && phone.trim()

        if (shouldShowError(phone)) {
            const error = resolvePhoneInputError()
            setPhoneInputError(error)
        } else {
            try {
                await checkPhone({
                    phone: number || phone,
                })
                enterOTP()
            } catch (error) {
                setErrorMessage(error.message)
            }
        }
    }

    const onEnterPhone = (e, phone) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            processNumber(phone)
        }
    }

    const disabled = !!error

    const renderSignupButton = () => (
        <Boutton
            variant="primary"
            onClick={(e) => {
                e.preventDefault()
                const phoneValue = phoneInputRef?.current?.value
                if (!previewMode) {
                    if (!isReturningUser) {
                        onSubmitCallback({
                            end_user_phone: phoneValue,
                            sign_up_method: 'sms',
                        })
                    }
                    processNumber(phoneValue)
                }
            }}
            disabled={(
                !previewMode &&
                ((typeof phoneInputRef?.current?.value === 'undefined' ||
                phoneInputRef?.current?.value === '')) || disabled
            )}
            styles={{
                color: contrastColor
            }}
            className={cx(styles.signupButton, {
                [styles.loginButton]: isReturningUser,
            })}
        >
            Continue
        </Boutton>
    )

    return (
        <>
            <div className={styles.field}>
                <label className={styles.label}>
                    Enter your phone
                </label>
                <Field
                    {...fieldContainerProps}
                    name="phone"
                    id="phone"
                    type="tel"
                >
                    {({ meta, input, ...rest }) => (
                        <div className={styles[`phoneInput${error ? '--error' : ''}`]}>
                            <Form.Input
                                {...rest}
                                meta={meta}
                                ref={phoneInputRef}
                                input={input}
                                onClick={() => {
                                    handlePhoneErrorReset()
                                }}
                                onChange={() => {
                                    handlePhoneErrorReset()
                                }}
                                onKeyDown={(e) => onEnterPhone(e, e.target.value)}
                            />
                            {error && <span className={styles.phoneError}>{error}</span>}
                        </div>

                    )}
                </Field>
            </div>
            <div>
                {renderSignupButton()}
            </div>
        </>
    )}

PhoneField.propTypes = {
    checkPhone: PropTypes.func,
    contrastColor: PropTypes.string,
    enterOTP: PropTypes.func,
    fieldContainerProps: PropTypes.any,
    isReturningUser: PropTypes.bool,
    onSubmitCallback: PropTypes.func,
    previewMode: PropTypes.bool,
    userPhoneMismatchError: PropTypes.string,
}

const MemoizedPhoneField = React.memo(PhoneField)

export default MemoizedPhoneField
