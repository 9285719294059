import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from '../scss/Summary.module.scss'

const Label = ({ title, color }) => (
    <div className={classNames(styles.label, styles[`label-${color}`])}>
        <span>{title}</span>
    </div>
)

Label.propTypes = {
    title: PropTypes.string,
    color: PropTypes.string,
}

export default Label

