import React from 'react'
import PropTypes from 'prop-types'
import styles from '../scss/Summary.module.scss'

const OrderSummaryContainer = ({ children }) => (
    <div className={styles.container}>{children}</div>
)

OrderSummaryContainer.propTypes = {
    children: PropTypes.node.isRequired
}

export default OrderSummaryContainer
