import React, { useState } from 'react'
import { Field } from 'react-final-form'
import classNames from 'classnames'

import Form from 'piconetworks/pkg-form'
import { H3 } from 'piconetworks/pkg-heading'
import { P } from 'piconetworks/pkg-text'
import { format } from 'piconetworks/pkg-currency'
import { AnnualPercentDiscount } from 'piconetworks/pkg-functions'

import style from './scss/SelectTrial.module.scss'

import { RemoteJsonText } from 'piconetworks/pkg-remote-markdown'
import { selectors } from 'piconetworks/pkg-module-loader'

const SelectTrial = (props) => {
    const {
        fieldContainerProps,
        required,
        popup,
        name,
        linkColor = '',
        contrastColor = '',
        isGift = false,
        product = {},
        className,
        giftCta = () => { },
        customFeaturesUrl = '',
        currentProductOptionId,
        trialIneligible,
        ...rest
    } = props

    const { priceText } = selectors()

    const pluralizedTrialInterval = (trialInterval, trialQuantity) =>
        `${trialInterval}${(trialQuantity === 0 || trialQuantity > 1) ? 's' : ''}`

    const title = product?.title
    let defaultIsAnnualChecked = true
    const formattedOptions = product?.options
        ?.reduce((selections, {
            id: value,
            interval,
            price: amount,
            trial_quantity: trialQuantity,
            trial_interval: trialInterval,
            trial_price: trialAmount,
            currency: currencyCode,
            default_option: defaultOption,
        }) => {
            const alreadySubscribed = currentProductOptionId === value

            const price = format({
                currencyCode,
                amount,
                symbol: true,
            })

            const trialPrice = format({
                currencyCode,
                amount: trialAmount,
                symbol: true,
            })

            const text = isGift
                ? `Gift The first ${interval}`
                : alreadySubscribed
                    ? 'Already subscribed'
                    : trialIneligible 
                        ? `1 ${pluralizedTrialInterval(interval, 1)} for ${price}`
                        : !trialAmount && !!trialInterval
                            ? `Try ${trialQuantity} ${pluralizedTrialInterval(trialInterval, trialQuantity)} for Free`
                            : `Try ${trialQuantity} ${pluralizedTrialInterval(trialInterval, trialQuantity)} for ${trialPrice}`

            selections[interval] = {
                value,
                text,
                price,
                amount,
                interval,
                trialPrice,
                trialAmount,
                trialQuantity,
                trialInterval,
            }

            if (interval === 'month' && defaultOption) {
                defaultIsAnnualChecked = false
            }

            return selections
        }, {}) || []

    const [isAnnualChecked, toggleAnnualChecked] = useState(defaultIsAnnualChecked)

    const showPlanIntervalSwitch = Object.keys(formattedOptions).length > 1

    const selectedPlan = showPlanIntervalSwitch
        ? isAnnualChecked
            ? formattedOptions.year
            : formattedOptions.month
        : formattedOptions[Object.keys(formattedOptions)[0]]

    const text = selectedPlan?.text
    const value = selectedPlan?.value
    const alreadySubscribed = currentProductOptionId === value
    const price = selectedPlan?.price
    const trialPrice = selectedPlan?.trialPrice
    const trialAmount = selectedPlan?.trialAmount
    const trialQuantity = selectedPlan?.trialQuantity
    const trialInterval = selectedPlan?.trialInterval
    const interval = selectedPlan?.interval

    let savings
    if (showPlanIntervalSwitch) {
        const monthlyPrice = formattedOptions?.month?.amount
        const annualPrice = formattedOptions?.year?.amount
        savings = (annualPrice < monthlyPrice * 12) && AnnualPercentDiscount(monthlyPrice, annualPrice)
    }

    return (
        <Form.Field
            {...fieldContainerProps}
        >
            <div className={classNames(style.container, className)}>
                {title && (
                    <div className={style.header}>
                        <H3 className={style.title}>{title}</H3>
                        <div className={style.priceContainer}>
                            {!trialIneligible && (<>
                                <H3 className={style.price}>{!trialAmount ? 'Free' : trialPrice}</H3>
                                <small>for {trialQuantity} {pluralizedTrialInterval(trialInterval, trialQuantity)}</small>
                                <small className={style.then}>then</small>
                            </>)}
                            <H3 className={style.price}>{price}</H3>
                            <small>per {interval}</small>
                        </div>
                    </div>
                )}
                <div className={style.dflex}>
                    <div className={style.col6}>
                        <div className={style.features}>
                            {customFeaturesUrl && <RemoteJsonText url={customFeaturesUrl} style={style} linkColor={linkColor} />}
                        </div>
                        {showPlanIntervalSwitch && (
                            <div className={style.radios}>
                                <div onClick={() => toggleAnnualChecked(false)} className={classNames(style.tab, { [style.checked]: interval === 'month' })}>
                                    <p>Monthly</p>
                                </div>
                                <div onClick={() => toggleAnnualChecked(true)} className={classNames(style.tab, { [style.checked]: interval === 'year' })}>
                                    <p> {`Annual${savings ? ` (Save ${savings}%)` : ''}`}</p>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={style.col6}>
                        <div className={style.actions}>
                            <div className={style.buttons}>
                                {!isGift && (
                                    <P
                                        className={classNames(style['link-text'], style.gift)}
                                        onClick={() => giftCta(value)}
                                    >
                                        <span className="icon-icon_gift"></span>
                                        <span>Gift</span>
                                    </P>
                                )}
                                <Field
                                    {...(required && {
                                        validate: value => (value ? undefined : 'Required')
                                    })}
                                    name={name}
                                    render={(props) => (
                                        <Form.Radio
                                            {...props}
                                            disabled={alreadySubscribed}
                                            className={classNames('btn',
                                                style.btn,
                                                style.hidden,
                                            )}
                                            style={{
                                                color: contrastColor,
                                            }}
                                            fieldProps={{
                                                className: classNames('btn',
                                                    style.btn,
                                                    alreadySubscribed && style.disabled,
                                                )
                                            }}
                                        />
                                    )}
                                    text={text}
                                    value={value}
                                    type="radio"
                                    {...rest}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Form.Field>
    )
}

export default SelectTrial
