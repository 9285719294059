import React, { useState, useEffect } from 'react'
import { Field, useForm } from 'react-final-form'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { H3 } from 'piconetworks/pkg-heading'
import { P } from 'piconetworks/pkg-text'
import { format } from 'piconetworks/pkg-currency'
import Form from 'piconetworks/pkg-form'
import Picon from 'piconetworks/pkg-picon'
import { AnnualPercentDiscount } from 'piconetworks/pkg-functions'

import style from './scss/SelectPledge.module.scss'

import { RemoteJsonText } from 'piconetworks/pkg-remote-markdown'
import { selectors } from 'piconetworks/pkg-module-loader'

const SelectPledge = (props) => {
    const {
        fieldContainerProps,
        required,
        name,
        linkColor = '',
        contrastColor = '',
        isGift = false,
        product = {},
        className,
        giftCta = () => { },
        customFeaturesUrl = '',
        setCustomPrice = () => { },
        ...rest
    } = props

    const [isAnnualChecked, toggleAnnualChecked] = useState(true)
    const [isCustomFieldAction, toggleCustomField] = useState(false)
    const { priceText } = selectors()
    const form = useForm()

    useEffect(() => {
        const annual = product.options.some(option => option.default_option && option.interval === 'year')
        toggleAnnualChecked(annual)
    }, Array.isArray(product.options) ? product.options : [])

    const title = product?.title
    const formattedOptions = product?.options
        ?.reduce((selections, {
            id: value,
            interval,
            price: amount,
            currency: currencyCode,
        }) => {
            const price = priceText(format({
                currencyCode,
                amount,
                symbol: false,
            }), currencyCode)

            const text = isGift
                ? `Gift the first ${interval}`
                : 'Pledge'

            selections[interval] = {
                value,
                text,
                price,
                amount,
                interval,
                currencyCode,
            }

            return selections
        }, {}) || []
    const showPlanIntervalSwitch = Object.keys(formattedOptions).length > 1
    const selectedPlan = showPlanIntervalSwitch
        ? isAnnualChecked
            ? formattedOptions.year
            : formattedOptions.month
        : formattedOptions[Object.keys(formattedOptions)[0]]

    const text = selectedPlan?.text
    const price = selectedPlan?.price
    const value = selectedPlan?.value
    const amount = selectedPlan?.amount
    const interval = selectedPlan?.interval

    const [pledgePrice, setPledgePrice] = useState()
    const planCustomPrice = `customPrice_${value}`
    const selectedCustomPrice = form.getState().values?.[planCustomPrice]

    useEffect(() => {
        toggleCustomField(false)
        setCustomPrice(amount)
        setPledgePrice((amount / 100).toFixed(2))
    }, [JSON.stringify(selectedPlan)])

    let savings
    if (showPlanIntervalSwitch) {
        const monthlyPrice = formattedOptions?.month?.amount
        const annualPrice = formattedOptions?.year?.amount
        savings = (annualPrice < monthlyPrice * 12) && AnnualPercentDiscount(monthlyPrice, annualPrice)
    }

    return (
        <Form.Field
            {...fieldContainerProps}
        >
            <div className={classNames(style.container, className)}>
                {title && (
                    <div className={style.header}>
                        <H3 className={style.title}>{title}</H3>
                        <div className={style.priceContainer}>
                            <H3 className={style.price}>{price}</H3>
                            <small>per {interval}</small>
                        </div>
                    </div>
                )}
                <div className={style.dflex}>
                    <div className={style.col6}>
                        {customFeaturesUrl && <RemoteJsonText url={customFeaturesUrl} style={style} linkColor={linkColor}/>}
                    </div>
                    <div className={style.col6}>
                        <div>
                            {showPlanIntervalSwitch && (
                                <>
                                    <Form.Radio
                                        type="radio"
                                        text={'Monthly'}
                                        input={{
                                            onChange: () => {
                                                toggleAnnualChecked(false)
                                            },
                                            checked: interval === 'month',
                                            type: 'radio',
                                        }}
                                    />
                                    <Form.Radio
                                        type="radio"
                                        text={`Annual${savings ? ` (Save ${savings}%)` : ''}`}
                                        input={{
                                            onChange: () => {
                                                toggleAnnualChecked(true)
                                            },
                                            checked: interval === 'year',
                                            type: 'radio',
                                        }}
                                    />
                                </>
                            )}
                        </div>
                        <div
                            className={style.dflex}
                            onClick={(e) => {
                                if (!isCustomFieldAction) {
                                    e.preventDefault()
                                    e.stopPropagation()

                                    toggleCustomField(!isCustomFieldAction)
                                }
                            }}
                        >
                            {isCustomFieldAction && (
                                <Field
                                    name={`customPrice_${selectedPlan.value}`}
                                    validate={(value, allValues, meta) => {
                                        return (Number(value) < amount && meta.active)
                                            ? `Amount must be at least ${price}.`
                                            : undefined
                                    }}
                                >
                                    {({ input, meta }) => (
                                        <div className={style.passwordInput}>
                                            <Form.FormatInput
                                                meta={meta}
                                                symbol={false}
                                                formatType="currency"
                                                label=""
                                                inputPrefix="$"
                                                placeholder="0.00"
                                                customPrice="true"
                                                value={pledgePrice}
                                                initialValue={pledgePrice}
                                                input={{
                                                    customPrice: true,
                                                    defaultValue: pledgePrice,
                                                    onChange:(el) => {
                                                        if(typeof el === 'object'){
                                                            const value = el?.target?.value
                                                            input.onChange({
                                                                ...el,
                                                                target: {
                                                                    ...el.target,
                                                                    value: Number(value.split(',').join('').split('.').join(''))
                                                                }
                                                            })
                                                            setPledgePrice(value)
                                                        }else{
                                                            setPledgePrice(el)
                                                        }
                                                    }
                                                }}

                                            />
                                        </div>
                                    )}
                                </Field>
                            )}
                            <Field
                                {...(required && {
                                    validate: value => (value ? undefined : 'Required')
                                })}
                                name={name}
                                render={(props) => (
                                    <Form.Radio
                                        {...props}
                                        className={classNames('btn',
                                            style.btn,
                                            { [style.fix]: isCustomFieldAction }
                                        )}
                                        style={{
                                            color: contrastColor,
                                        }}
                                        fieldProps={{
                                            className: classNames('btn',
                                                style.btn,
                                                { [style.fix]: isCustomFieldAction }
                                            )
                                        }}
                                    />
                                )}
                                value={value}
                                text={
                                    isCustomFieldAction ? (
                                        <Picon
                                            icon='arrow-right'
                                            color='white'
                                            size='25'
                                        />
                                    ) : (
                                        text
                                    )
                                }
                                type="radio"
                                disabled={selectedCustomPrice < amount}
                                {...rest}
                            />
                        </div>

                        {!isGift && (
                            <P
                                className={style['link-text']}
                                onClick={() => giftCta(value)}
                            >
                                <span className="icon-icon_gift"></span>
                                <span>Gift</span>
                            </P>
                        )}
                    </div>
                </div>
            </div>
        </Form.Field>
    )
}


SelectPledge.propTypes = {
    className: PropTypes.string,
    contrastColor: PropTypes.string,
    customFeaturesUrl: PropTypes.string,
    description: PropTypes.string,
    fieldContainerProps: PropTypes.shape({
        label: PropTypes.string,
        required: PropTypes.bool
    }),
    giftCta: PropTypes.func,
    isGift: PropTypes.bool,
    linkColor: PropTypes.string,
    name: PropTypes.string,
    product: PropTypes.object,
    required: PropTypes.bool,
    setCustomPrice: PropTypes.func,
    themeColor: PropTypes.string,
}

export default SelectPledge
