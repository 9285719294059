import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'lib/RouterAdapterDecorator'
import PropTypes from 'prop-types'
import qs from 'qs'

import EmailPassWordSocialNewslettersLogin from 'piconetworks/pkg-field-email-password-social-newsletters-login'

import { getSignupPaymentUrl } from 'lib/helpers'
import observers from 'lib/observers'
import Account from 'modules/account'
import Auth from 'modules/auth'
import Client from 'modules/client'
import FeatureFlagsModule from 'modules/feature-flags'
import LandingPage from 'modules/landing-page'
import Me from 'modules/me'
import logger from 'lib/logger'

const EmailPassWordSocialNewslettersLoginComponent = ({
    authenticationMethods = [],
    children,
    customFieldsValid,
    customProperties,
    description,
    isPaymentLandingPage,
    isReturningUser,
    label,
    name,
    newsletterFilter,
    newsletters = [],
    onAccountCreated = () => { },
    onLogin = () => { },
    onSubmitCallback = () => { },
    onPasswordLogin = () => { },
    popupId,
    required,
    shouldUpdateEmail,
    showError,
    showTerms,
    onSkipOtp,
    subtitle,
    title,
    setAuthFlow = () => { },
    authFlow,
    setShouldSubmitForm,
}) => {
    const dispatch = useDispatch()
    const router = useRouter()

    const wizardName = router?.query?.id
    const shortCode = router?.query?.short_code

    const queryString = qs.stringify({
        ...(wizardName && {
            id: wizardName,
        }),
        ...(shortCode && {
            short_code: shortCode,
        }),
    })

    const linkColor = useSelector(Client.selectors.linkColor)
    const contrastColor = useSelector(Client.selectors.contrastColor)
    const publisherId = useSelector(Client.selectors.id)
    const username = useSelector(Client.selectors.username)
    const userId = useSelector((state) => state?.loader?.guest?.user?.id)
    const access_token = useSelector((state) => state?.loader?.guest?.token)
    const utm = LandingPage.selectors.getUTM()
    const updateAccountError = useSelector(Account.selectors.updateAccountError)
    const isNewRegistration = useSelector(Account.selectors.isNewRegistration)
    const oneTimeCodeSent = useSelector(Account.selectors.oneTimeCodeSent)
    const oneTimeCodeError = useSelector(Account.selectors.oneTimeCodeError)
    const userEmail = useSelector(Me.selectors.userEmail)
    const enteredEmail = useSelector(Account.selectors.email)
    const emailOtpEnabled = useSelector(FeatureFlagsModule.selectors.emailOtp)
    const publisherName = useSelector(Client.selectors.name)

    const origin = getSignupPaymentUrl({ shortCode, username })
    const originUrl = typeof window !== 'undefined' && window?.location?.origin
    const defaultRedirectPath = `/@${username}/login/token?${queryString}`
    const loggedInUserRedirectPath = `/@${username}`
    const isLoggedInUser = !queryString
    const redirectUrlPath = isLoggedInUser
        ? loggedInUserRedirectPath
        : defaultRedirectPath
    const redirectUrl = `${originUrl}${redirectUrlPath}`

    const resetOneTimeCode = useCallback(() => {
        dispatch(
            Account.creators.resetOneTimeCode(),
        )
    }, [dispatch])

    const requestOneTimeCode = useCallback(async ({ email, publisherId, redirectUrl, origin, popupId, lpUtm }) => {
        try {
            await observers.requestOneTimeCode({
                dispatch,
                payload: {
                    email,
                    publisherId,
                    redirectUrl,
                    origin,
                    popupId,
                    utm: lpUtm,
                },
            })
        } catch (error) {
            logger.error('requestOneTimeCode', {}, error)
            if (error?.message) {
                throw error
            } else {
                throw new Error('an error has occurred')
            }
        }
    }, [dispatch])

    const checkEmail = useCallback(
        async ({ email, customProperties: customProps, selectedNewsletters }) => {
            const lpUtm = LandingPage.selectors.getUTM()
            setAuthFlow('email')
            if (!email) {
                throw new Error('no email provided')
            }

            const query = {
                ...(wizardName && {
                    id: wizardName,
                }),
                ...(shortCode && {
                    short_code: shortCode,
                }),
            }

            const { company_slug } = router?.query || {}

            if (shouldUpdateEmail) {
                if (userEmail && userEmail !== email) {
                    throw new Error(
                        'Please use the email that is linked to your Hype account.',
                    )
                }

                if (emailOtpEnabled) {
                    await requestOneTimeCode({ email, publisherId, redirectUrl, origin, popupId, lpUtm })
                }

                if (!emailOtpEnabled) {
                    const updateEmail = await observers.updateUser({
                        dispatch,
                        payload: {
                            email,
                            publisherId,
                            redirectUrl,
                        },
                    })

                    if (updateEmail.emailDataUpdated && userEmail !== email) {
                        router.push(
                            {
                                pathname:
                                    '/[company_slug]/login/link-confirmation',
                                query,
                            },
                            {
                                pathname: `/${company_slug}/login/link-confirmation`,
                                query,
                            },
                        )
                    }

                    return {}
                }
            }

            const getHasPassword = await observers.getHasPassword({
                dispatch,
                payload: {
                    email,
                    publisherId,
                },
            })

            if (getHasPassword?.hasPassword === false) {
                if (emailOtpEnabled) {

                    if (getHasPassword?.isNewRegistration === true) {

                        if (!isPaymentLandingPage) {
                            await requestOneTimeCode({ email, publisherId, redirectUrl, origin, popupId, lpUtm })
                        }

                        await observers.requestLoginLink({
                            dispatch,
                            payload: {
                                customProperties: customProps,
                                email,
                                emailOtpEnabled: true,
                                newsletters: selectedNewsletters,
                                publisherId,
                                redirectUrl,
                                origin,
                                popupId,
                                // referer,
                                utm: lpUtm,
                            },
                        })
                        await observers.updateUserNewsletters({
                            dispatch,
                            payload: {
                                publisherId,
                                newsletters: selectedNewsletters,
                            },
                        })

                        await observers.updateUserProperties({
                            dispatch,
                            payload: {
                                publisherId,
                                customProperties: customProps,
                            },
                        })


                        localStorage.setItem('isNewRegistration', getHasPassword?.isNewRegistration)

                    } else {

                        await requestOneTimeCode({ email, publisherId, redirectUrl, origin, popupId, lpUtm })
                        await observers.requestLoginLink({
                            dispatch,
                            payload: {
                                customProperties: customProps,
                                email,
                                emailOtpEnabled: true,
                                newsletters: selectedNewsletters,
                                publisherId,
                                redirectUrl,
                                origin,
                                popupId,
                                // referer,
                                utm: lpUtm,
                            },
                        })
                        await observers.updateUserNewsletters({
                            dispatch,
                            payload: {
                                publisherId,
                                newsletters: selectedNewsletters,
                            },
                        })

                        await observers.updateUserProperties({
                            dispatch,
                            payload: {
                                publisherId,
                                customProperties: customProps,
                            },
                        })
                    }
                }

                if (!emailOtpEnabled) {
                    try {
                        const requestLoginLink = await observers.requestLoginLink({
                            dispatch,
                            payload: {
                                customProperties: customProps,
                                email,
                                newsletters: selectedNewsletters,
                                publisherId,
                                redirectUrl,
                                origin,
                                popupId,
                                // referer,
                                utm: lpUtm,
                            },
                        })

                        if (requestLoginLink?.isNewRegistration) {
                            onAccountCreated('email')
                            return {
                                loggedIn: true,
                            }
                        }

                        router.push(
                            {
                                pathname:
                                    '/[company_slug]/login/link-confirmation',
                                query,
                            },
                            {
                                pathname: `/${company_slug}/login/link-confirmation`,
                                query,
                            },
                        )

                        return {
                            magicLink: true,
                        }
                    } catch (error) {
                        logger.error('requestLoginLink', {}, error)
                        if (error?.message) {
                            throw error
                        } else {
                            throw new Error('an error has occurred')
                        }
                    }
                }
            }

            if (getHasPassword?.hasPassword === true) {
                dispatch(
                    Account.creators.setLoginEmail({
                        email,
                    }),
                )
                onPasswordLogin({ selectedNewsletters, customProperties, company_slug })

                return {
                    email,
                    hasPassword: true,
                }
            }

            return {}
        },
        [customProperties, dispatch, emailOtpEnabled, isPaymentLandingPage, onAccountCreated, onPasswordLogin, origin, popupId, publisherId, redirectUrl, requestOneTimeCode, router, setAuthFlow, shortCode, shouldUpdateEmail, userEmail, wizardName],
    )

    const resendOneTimeCode = useCallback(
        async ({ email }) => {
            const lpUtm = LandingPage.selectors.getUTM()

            if (!email) {
                throw new Error('no email provided')
            }

            await requestOneTimeCode({ email, publisherId, redirectUrl, origin, popupId, lpUtm })

            return {}
        },
        [origin, popupId, publisherId, redirectUrl, requestOneTimeCode],
    )

    const confirmCode = useCallback(
        async ({
            code, customProperties: customProps, email, selectedNewsletters,
        }) => {
            if (!code) {
                throw new Error('No code provided')
            }
            const lpUtm = LandingPage.selectors.getUTM()

            try {
                const confirmOneTimeCode = await observers.confirmOneTimeCode({
                    dispatch,
                    payload: {
                        code,
                        customProperties: customProps,
                        email,
                        newsletters: selectedNewsletters,
                        origin,
                        popupId,
                        publisherId,
                        redirectUrl,
                        // referer,
                        utm: lpUtm,
                    },
                })

                if (confirmOneTimeCode?.isNewRegistration) {
                    onAccountCreated('email')
                }

                // If we have a claim, get tokens
                if (confirmOneTimeCode.claim !== null) {
                    const response = await observers.exchangeClaim({
                        dispatch,
                        payload: {
                            claim: confirmOneTimeCode.claim,
                            scope: 'user',
                            publisherId,
                        },
                    })

                    dispatch(
                        Auth.creators.tokenLogin({
                            accessToken: response?.tokens?.token,
                            refreshToken: response?.tokens?.refresh_token,
                        }),
                    )

                    dispatch(
                        Me.creators.getCurrentUser({
                            payload: {
                                publisherId,
                            },
                        }),
                    )
                }
            } catch (error) {
                logger.error('confirmCode', {}, error)
                if (error?.message) {
                    throw new Error(error?.message)
                } else {
                    throw new Error('an error has occurred')
                }
            }
        },
        [dispatch, onAccountCreated, origin, popupId, publisherId, redirectUrl],
    )

    return (
        <EmailPassWordSocialNewslettersLogin
            authFlow={authFlow}
            title={title}
            subtitle={subtitle}
            fieldContainerProps={{
                label,
                required,
            }}
            name={name}
            email={enteredEmail}
            userId={userId}
            access_token={access_token}
            isNewRegistration={isNewRegistration}
            isReturningUser={isReturningUser}
            isPaymentLandingPage={isPaymentLandingPage}
            utm={utm}
            authenticationMethods={authenticationMethods}
            newsletterFilter={newsletterFilter}
            newsletters={newsletters}
            customFieldsValid={customFieldsValid}
            description={description}
            required={required}
            linkColor={linkColor}
            contrastColor={contrastColor}
            customProperties={customProperties}
            popupId={popupId}
            oneTimeCodeSent={oneTimeCodeSent}
            oneTimeCodeError={oneTimeCodeError}
            origin={origin}
            publisherId={publisherId}
            resetOneTimeCode={resetOneTimeCode}
            resendOneTimeCode={resendOneTimeCode}
            username={publisherName}
            shouldUpdateEmail={shouldUpdateEmail}
            showError={showError}
            showTerms={showTerms}
            updateAccountError={updateAccountError}
            onSkipOtp={onSkipOtp}
            setAuthFlow={setAuthFlow}
            onSocialLogin={async (response = {}, meta = {}) => {
                if (response.is_new_user) {
                    onAccountCreated(meta.social_type)
                } else {
                    onLogin(meta.social_type)
                }

                if (response.claim) {
                    const { tokens } = {} = await observers.exchangeClaim({
                        dispatch,
                        payload: {
                            claim: response.claim,
                            publisherId,
                            scope: 'user',
                        },
                    })

                    dispatch(
                        Auth.creators.tokenLogin({
                            accessToken: tokens?.token,
                            refreshToken: tokens?.refresh_token,
                        }),
                    )
                } else {
                    dispatch(
                        Auth.creators.socialLogin({
                            accessToken: response.token,
                            refreshToken: response.refresh_token,
                        }),
                    )
                }

                dispatch(
                    Me.creators.getCurrentUser({
                        payload: {
                            publisherId,
                        },
                    }),
                )

                setShouldSubmitForm(true)
            }}
            checkEmail={checkEmail}
            confirmCode={confirmCode}
            onSubmitCallback={onSubmitCallback}
        >
            {children}
        </EmailPassWordSocialNewslettersLogin>
    )
}

EmailPassWordSocialNewslettersLoginComponent.defaultProps = {
    authenticationMethods: [],
    children: null,
    customFieldsValid: false,
    customProperties: {},
    description: '',
    isPaymentLandingPage: false,
    isReturningUser: false,
    label: '',
    name: '',
    newsletterFilter: '',
    newsletters: [],
    onAccountCreated: () => { },
    onSkipOtp: () => { },
    onLogin: () => { },
    onSubmitCallback: () => { },
    popupId: '',
    required: false,
    shouldUpdateEmail: false,
    showError: false,
    showTerms: false,
    subtitle: '',
    title: '',
    onPasswordLogin: () => { },
}

EmailPassWordSocialNewslettersLoginComponent.propTypes = {
    authenticationMethods: PropTypes.array,
    children: PropTypes.node,
    customFieldsValid: PropTypes.bool,
    customProperties: PropTypes.object,
    description: PropTypes.string,
    isPaymentLandingPage: PropTypes.bool,
    isReturningUser: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    newsletterFilter: PropTypes.string,
    newsletters: PropTypes.array,
    onAccountCreated: PropTypes.func,
    onLogin: PropTypes.func,
    onSkipOtp: PropTypes.func,
    onSubmitCallback: PropTypes.func,
    popupId: PropTypes.string,
    required: PropTypes.bool,
    shouldUpdateEmail: PropTypes.bool,
    showError: PropTypes.bool,
    showTerms: PropTypes.bool,
    subtitle: PropTypes.string,
    title: PropTypes.string,
    onPasswordLogin: PropTypes.func,
}

export default React.memo(EmailPassWordSocialNewslettersLoginComponent)
