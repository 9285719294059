import React from 'react'
import { Field } from 'react-final-form'

import Form from 'piconetworks/pkg-form'

const GiftForm = ({
    name,
    fieldContainerProps,
    description = null,
    required,
    defaultValue,
    ...rest
} = {}) => {
    return (
        <Form.Field
            {...fieldContainerProps}
        >
            <p>{description}</p>
            <Form.Field>
                <Field
                    label="Your Name"
                    required={required}
                    name={`${name}.sender_name`}
                    component={Form.Input}
                    type="text"
                    {...(required && {
                        validate: value => (value ? undefined : 'Required')
                    })}
                    {...rest}
                />
                <Field
                    label="Recipient's Name"
                    required={required}
                    name={`${name}.recipient_first_name`}
                    component={Form.Input}
                    type="text"
                    {...(required && {
                        validate: value => (value ? undefined : 'Required')
                    })}
                    {...rest}
                />
                <Field
                    label="Recipient's Email"
                    required={required}
                    name={`${name}.recipient_email`}
                    component={Form.Input}
                    type="email"
                    {...(required && {
                        validate: value => (value ? undefined : 'Required')
                    })}
                    {...rest}
                />
                <Field
                    label="Gift Note"
                    name={`${name}.note`}
                    component={Form.TextArea}
                    type="text"
                />
            </Form.Field>
        </Form.Field>
    )
}

export default GiftForm
