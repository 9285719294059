import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import cx from 'classnames'

import PageLink from 'piconetworks/pkg-page-link'

import Form from 'piconetworks/pkg-form'

import styles from './scss/Link.module.scss'

const IframelyRichMedia = React.memo(({
    html,
}) => {
    useEffect(() => {
        window?.iframely?.load()
    }, [])
    return (
        <div dangerouslySetInnerHTML={{ __html: html }} />
    )
})

const FieldLinks = ({
    linkBlocks = [],
    richMediaData = {},
    linkBlockClassName,
    handleClick = () => { },
    fieldContainerProps,
    onLinkClink = () => { },
    ...rest
}) => {
    const sortByOrder = (a, b) => a.order - b.order

    const LinksField = [...linkBlocks].sort(sortByOrder).map(({
        links
    }, index) => (
        <div
            className={cx(styles.linkBlockContainer, linkBlockClassName)}
            key={index}
            onClick={() => handleClick(index)}
        >
            {[...links].sort(sortByOrder).map(({
                id,
                title = '',
                url,
                image = '',
                description = '',
                social_type,
                display = true,
                image_preview,
                is_rich,
                format_type,
            }, index) => {
                const richMedia = richMediaData?.[url] || null
                const isTextOnly = format_type === 'text-only'
                const isRich = richMedia && is_rich

                return display && (
                    <div
                        className={styles.linkBlockContainer_link}
                        key={index}
                        onClick={() => onLinkClink({
                            link_id: id,
                            link_url: url,
                            link_social_type: social_type,
                            link_title: title,
                            link_description: description,
                            link_type: 'link_block',
                        })}
                    >{isRich
                            ? <IframelyRichMedia
                                html={richMedia.html}
                            /> :
                            <PageLink
                                subtitle={description}
                                title={title || url}
                                url={url}
                                image={!isTextOnly && (image_preview || image)}
                            />}
                    </div>
                )
            })}
        </div>
    ))

    return (
        <Form.Field
            {...fieldContainerProps}
        >
            <Field
                component={() => LinksField}
                {...rest}
            />
        </Form.Field>
    )
}

FieldLinks.propTypes = {
    linkBlocks: PropTypes.array,
    fieldContainerProps: PropTypes.any,
    linkBlockClassName: PropTypes.string,
    handleClick: PropTypes.func,
    trackLinkClick: PropTypes.func,
}

export default FieldLinks
