import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import TimePicker from 'react-time-picker/dist/entry.nostyle'

import Form from 'piconetworks/pkg-form'
import Icon from 'piconetworks/pkg-icon'

import style from './Time.module.scss'

class Time extends React.Component {
    state = {
        date: null,
        format: 'am'
    }

    onChange = (time) => {
        this.setState({ time })
        this.props.input.onChange(time)
    }

    render() {
        let icon
        const { className, dontShowAmPm, formFieldClassName, label, type, input, required, fieldProps, ...args } = this.props

        switch (type) {
            case 'clock':
                icon = <Icon.Default.Clock height={24} width={24} />
                break
            case 'timer':
                icon = <Icon.Default.Timer height={24} width={24} />
                break
            default:
                icon = null
        }

        return (
            <Form.Field label={label} required={required} input={input} className={formFieldClassName} {...fieldProps}>
                <TimePicker
                    clockIcon={icon}
                    className={cx(style.timePicker, className)}
                    onChange={this.onChange}
                    value={this.state.date}
                    name={input.name}
                    {...args}
                />
                {type === 'clock' &&
                !dontShowAmPm && (
                    <Form.Dropdown
                        formFieldClassName={cx(style.dropdownForm)}
                        options={[ { label: 'AM', value: 'am' }, { label: 'PM', value: 'PM' } ]}
                        input={{ value: this.state.format, onChange: (val) => this.setState({ format: val }) }}
                        meta={{}}
                    />
                )}
            </Form.Field>
        )
    }
}

Time.defaultProps = {
    dontShowAmPm: true,
    type: 'clock',
    clearIcon: null
}
Time.propTypes = {
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    dontShowAmPm: PropTypes.bool,
    fieldProps: PropTypes.object,
    /** This will be deprecated eventually. Instead pass className to the fieldProps */
    formFieldClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    input: PropTypes.object,
    label: PropTypes.node,
    meta: PropTypes.object,
    required: PropTypes.bool,
    type: PropTypes.oneOf([ 'timer', 'clock', null ]),
}

export default Time
