import React from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'

import Form from 'piconetworks/pkg-form'

import styles from './scss/text.module.scss'

const Text = ({
    fieldContainerProps,
    description = null,
    required,
    previewMode = false,
    input,
    ...rest
} = {}) => {
    const TextField = (
        <Form.Input
            input={{
                ...input,
                ...(previewMode && { readOnly: true })
            }}
        />
    )

    return (
        <div className={styles.fieldTextContainer}>
            <Form.Field>
                <Field
                    component={!previewMode ? Form.Input : () => TextField}
                    type="text"
                    {...(required && {
                        validate: (value) => (value ? undefined : 'Required')
                    })}
                    {...fieldContainerProps}
                    {...rest}
                    description={description}
                />
            </Form.Field>
        </div>
    )
}

Text.propTypes = {
    fieldContainerProps: PropTypes.any,
    description: PropTypes.string,
    required: PropTypes.bool,
    previewMode: PropTypes.bool,
    input: PropTypes.any
}

export default Text
