import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Otp from 'piconetworks/pkg-field-otp'
import PhoneField from './PhoneField'

import styles from './scss/TextSignup.module.scss'

class ErrorBoundary extends React.Component {

    static getDerivedStateFromError(error) {
        console.log('getDerivedStateFromError', error)
        return { hasError: true }
    }

    constructor(props){
        super(props)
        this.state = { hasError: false }
    }

    componentDidCatch(error, errorInfo) {
        console.error(error.message, errorInfo)
    }

    render() {
        const { hasError } = this.state
        const { children, RenderError = () => (<div className='errorBoundaree'></div>) } = this.props

        if (hasError) {
            return (
                <RenderError />
            )
        }

        return children
    }
}

const TextSignup = ({
    checkPhone,
    confirmCode,
    contrastColor,
    fieldContainerProps,
    isReturningUser,
    linkColor = '',
    oneTimeCodeError,
    oneTimeCodeSent = false,
    onSubmitCallback = () => { },
    phoneNumber,
    previewMode = false,
    username,
    userPhoneMismatchError,
    isCustomFieldsValid = true,
    values = {},
    setFlow,
    defaultType,
    resetOneTimeCode = () => { },
    errorMessage,
    getErrorText,
    loginPhone,
    isAuthenticated,
} = {}) => {
    const [enterPhone, setEnterPhone] = useState(!loginPhone)

    useEffect(() => {
        if (oneTimeCodeSent) {
            setEnterPhone(false)
        }
    }, [oneTimeCodeSent])

    const requestNewCode = () => {
        setEnterPhone(true)
        resetOneTimeCode()
    }

    const enterOTP = () => {
        setEnterPhone(false)
    }

    const showPhoneInput = !!userPhoneMismatchError || previewMode || enterPhone

    return (
        <ErrorBoundary>
            <section className={cx({ [styles.isPreview]: previewMode })} data-testid='sms-signup'>
                <>
                    <div className={styles.container}>
                        <div>
                            <div style={{ display: `${showPhoneInput ? 'block' : 'none'}` }}>
                                <PhoneField
                                    checkPhone={checkPhone}
                                    contrastColor={contrastColor}
                                    enterOTP={enterOTP}
                                    enterPhone={enterPhone}
                                    fieldContainerProps={fieldContainerProps}
                                    isReturningUser={isReturningUser}
                                    oneTimeCodeError={oneTimeCodeError}
                                    onSubmitCallback={onSubmitCallback}
                                    previewMode={previewMode}
                                    userPhoneMismatchError={userPhoneMismatchError}
                                    isCustomFieldsValid={isCustomFieldsValid}
                                    setFlow={setFlow}
                                    values={values}
                                    errorMessage={errorMessage}
                                    getErrorText={getErrorText}
                                    isAuthenticated={isAuthenticated}
                                />
                            </div>
                            <div style={{ display: `${showPhoneInput ? 'none' : 'block'}` }}>
                                <Otp
                                    checkPhone={checkPhone}
                                    confirmCode={confirmCode}
                                    contrastColor={contrastColor}
                                    enterOTP={enterOTP}
                                    fieldContainerProps={fieldContainerProps}
                                    linkColor={linkColor}
                                    oneTimeCodeError={oneTimeCodeError}
                                    oneTimeCodeSent={oneTimeCodeSent}
                                    phoneNumber={phoneNumber}
                                    requestNewCode={requestNewCode}
                                    customProperties={defaultType?.customFields}
                                    newsletters={defaultType?.newsletters}
                                />
                            </div>
                        </div>
                        <div className={styles.terms}>
                            By clicking &ldquo;Continue,&rdquo; you agree to <a href="https://hype.co/terms/user-agreement" target='_blank' rel='noopener noreferrer'>Hype&rsquo;s Terms</a>{' '}
                            and acknowledge the <a href="https://hype.co/terms/privacy-policy" target='_blank' rel='noopener noreferrer'>Privacy Policy</a>.{' '}
                            You also agree that {username} may contact the phone number provided with marketing materials by text message or phone (including via an automated system).
                            Consent is not a condition of purchase. Message & data rates may apply.
                        </div>
                    </div>
                </>
            </section>
        </ErrorBoundary>
    )
}

TextSignup.propTypes = {
    checkPhone: PropTypes.func,
    confirmCode: PropTypes.func,
    contrastColor: PropTypes.string,
    defaultType: PropTypes.object,
    description: PropTypes.string,
    errorMessage: PropTypes.string,
    fieldContainerProps: PropTypes.any,
    getErrorText: PropTypes.func,
    isAuthenticated: PropTypes.bool,
    isCustomFieldsValid: PropTypes.bool,
    isReturningUser: PropTypes.bool,
    linkColor: PropTypes.string,
    loginPhone: PropTypes.any,
    oneTimeCodeError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    oneTimeCodeSent: PropTypes.bool,
    onSubmitCallback: PropTypes.func,
    phoneNumber: PropTypes.string,
    previewMode: PropTypes.bool,
    resetOneTimeCode: PropTypes.func,
    setFlow: PropTypes.func,
    subtitle: PropTypes.string,
    title: PropTypes.string,
    username: PropTypes.string,
    userPhoneMismatchError: PropTypes.string,
    values: PropTypes.object,
}

const MemoizedTextSignup = React.memo(TextSignup)

MemoizedTextSignup.displayName = 'FieldSignupTextSignup'

export default MemoizedTextSignup
