import React from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'

import Form from 'piconetworks/pkg-form'

const Checkbox = ({
    fieldContainerProps,
    description = null,
    required,
    options = [],
    ...rest
} = {}) => {
    return (
        <Form.Field {...fieldContainerProps}>
            <p>{description}</p>
            {options.map(({ label }, idx) => {
                return (
                    <Field
                        component={Form.Checkbox}
                        type="checkbox"
                        text={label}
                        value={label}
                        key={idx}
                        {...rest}
                    />
                )
            })}
        </Form.Field>
    )
}

Checkbox.propTypes = {
    fieldContainerProps: PropTypes.any,
    description: PropTypes.string,
    options: PropTypes.array,
    required: PropTypes.bool
}

export default Checkbox
