import React, { useCallback, useState, useContext } from 'react'
import { goToMenuRouteAndOpen } from 'piconetworks/pkg-menu-middleware'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'lib/RouterAdapterDecorator'
import PropTypes from 'prop-types'
import qs from 'qs'

import Signup from 'piconetworks/pkg-field-signup'
import { GO_TO_MENU_ROUTE_AND_OPEN } from 'piconetworks/pkg-menu-middleware'

import { getSignupPaymentUrl } from 'lib/helpers'
import observers from 'lib/observers'
import Account from 'modules/account'
import Auth from 'modules/auth'
import Client from 'modules/client'
import LandingPage from 'modules/landing-page'
import Me from 'modules/me'
import logger from 'lib/logger'

import AppContainerContext from 'containers/AppContainer/AppContainerContext'

const SignupWrapper = ({
    authenticationMethods = [],
    children,
    customFieldsValid = () => true,
    description,
    isPaymentLandingPage,
    isReturningUser,
    label,
    name,
    newsletterFilter,
    newsletters = [],
    onAccountCreated = () => { },
    onLogin = () => { },
    onSubmitCallback = () => { },
    onPasswordLogin = () => { },
    values,
    popupId,
    required,
    showError,
    showTerms,
    onSkipOtp,
    subtitle,
    title,
    customFields,
    defaultType,
    setFlow,
    isAuthed,
    linkColor,
    errorMessage,
    setErrorMessage,
    markDown,
}) => {
    const dispatch = useDispatch()
    const router = useRouter()

    const { setIsReturningUser } = useContext(AppContainerContext)

    const [userPhoneMismatchError, setUserPhoneMismatchError] = useState(null)
    const wizardName = router?.query?.id
    const shortCode = router?.query?.short_code

    const queryString = qs.stringify({
        ...(wizardName && {
            id: wizardName,
        }),
        ...(shortCode && {
            short_code: shortCode,
        }),
    })

    const contrastColor = useSelector(Client.selectors.contrastColor)
    const publisherId = useSelector(Client.selectors.id)
    const username = useSelector(Client.selectors.username)
    const company_slug = router?.query?.company_slug || `@${username}`
    const clientName = useSelector(Client.selectors.name)
    const userId = useSelector((state) => state?.loader?.guest?.user?.id)
    const access_token = useSelector((state) => state?.loader?.guest?.token)
    const utm = LandingPage.selectors.getUTM()
    const updateAccountError = useSelector(Account.selectors.updateAccountError)
    const isNewRegistration = localStorage.getItem('isNewRegistration') === 'true' ? true : false
    const oneTimeCodeSent = useSelector(Account.selectors.oneTimeCodeSent)
    const oneTimeCodeError = useSelector(Account.selectors.oneTimeCodeError)
    const enteredEmail = useSelector(Account.selectors.email)
    const origin = getSignupPaymentUrl({ shortCode, username })
    const originUrl = typeof window !== 'undefined' && window?.location?.origin
    const defaultRedirectPath = `/${company_slug}/login/token?${queryString}`
    const loggedInUserRedirectPath = `/${company_slug}`
    const phoneNumber = useSelector(Account.selectors.getPhoneNumber)

    const userEmail = useSelector(Me.selectors.userEmail)
    const userPhone = useSelector(Me.selectors.userPhone)
    const loginPhone = useSelector(Account.selectors.loginPhone)

    const isLoggedInUser = userEmail || userPhone

    const redirectUrlPath = isLoggedInUser
        ? loggedInUserRedirectPath
        : defaultRedirectPath

    const redirectUrl = `${originUrl}${redirectUrlPath}`

    const thankYouText = !isReturningUser
        ? JSON.stringify({
            component: 'You\'ve successfully signed up! Click {here} to create a password for your account.',
            link: '/account/edit-password',
            custom: true
        })
        : JSON.stringify({
            component: 'Welcome back! Click {here} to create a password for a faster login experience.',
            link: '/account/edit-password',
            custom: true,
        })

    const resetOneTimeCode = useCallback(() => {
        dispatch(
            Account.creators.resetOneTimeCode(),
        )
    }, [])

    const loginRedirect = (type = 'login') => {
        console.log('loginRedirect', type)
        if (isPaymentLandingPage) {
            const query = {
                ...(wizardName && {
                    id: wizardName,
                }),
                ...(shortCode && {
                    short_code: shortCode,
                }),
            }
            const { company_slug } = router?.query || {}
            router.push({
                pathname: '/[company_slug]/login',
                query: {
                    ...query,
                    isRegister: false,
                }
            }, {
                pathname: `/${company_slug}/login`,
                query: {
                    ...query,
                    isRegister: false,
                }
            })
            setIsReturningUser(type === 'login')
        } else {
            dispatch({
                type: GO_TO_MENU_ROUTE_AND_OPEN,
                payload: {
                    menuRoute: '/login'
                },
            })
            window.scrollTo(0, 0)
        }
    }

    const checkEmail = useCallback(
        async ({ email, customProperties: customProps, selectedNewsletters }) => {

            const lpUtm = LandingPage.selectors.getUTM()

            if (!email) {
                throw new Error('no email provided')
            }

            const { company_slug } = router?.query || {}

            const getHasPassword = await observers.getHasPassword({
                dispatch,
                payload: {
                    email,
                    publisherId,
                },
            })

            const { hasPassword, isNewRegistration } = getHasPassword || {}

            if (isReturningUser && isNewRegistration) {
                const errorMsg = 'Account not found. Please sign up instead.'
                setErrorMessage({
                    ...errorMessage,
                    email: errorMsg,
                })
                throw new Error(errorMsg)
            }

            if (!isReturningUser && !isNewRegistration) {
                const errorMsg = `An account with this email address already exists.${isLoggedInUser ? '' : ' Please log in instead.'}`
                setErrorMessage({
                    ...errorMessage,
                    email: errorMsg,
                })
                throw new Error(errorMsg)
            }

            if (hasPassword === false) {
                if (isNewRegistration) {
                    localStorage.setItem('isNewRegistration', true)

                    await observers.requestLoginLink({
                        dispatch,
                        payload: {
                            customProperties: customProps,
                            email,
                            emailOtpEnabled: true,
                            newsletters: selectedNewsletters,
                            publisherId,
                            redirectUrl,
                            origin,
                            popupId,
                            // referer,
                            utm: lpUtm,
                        },
                    })

                    onAccountCreated('email')

                    await observers.updateUserNewsletters({
                        dispatch,
                        payload: {
                            publisherId,
                            newsletters: selectedNewsletters,
                        },
                    })

                    await observers.updateUserProperties({
                        dispatch,
                        payload: {
                            publisherId,
                            customProperties: customProps,
                        },
                    })

                    if (isPaymentLandingPage) {
                        setShouldSubmitForm(true)
                    }

                    if (isReturningUser) {
                        resetOneTimeCode()
                    }
                } else {
                    localStorage.setItem('isNewRegistration', false)

                    await observers.requestOneTimeCode({
                        dispatch,
                        payload: {
                            email,
                            publisherId,
                            redirectUrl,
                            origin,
                            popupId,
                            utm: lpUtm,
                        },
                    })
                }
            }

            if (hasPassword === true) {
                try {
                    localStorage.setItem('isNewRegistration', false)

                    dispatch(
                        Account.creators.setLoginEmail({
                            email,
                        }),
                    )

                    onPasswordLogin({ selectedNewsletters, customProperties: customProps, company_slug })

                    return {
                        email,
                        hasPassword: true,
                    }
                } catch (error) {
                    logger.error('checkEmail error', {}, error)
                }
            }

            return {}
        },
        [],
    )

    const checkPhone = useCallback(
        async ({
            phone,
        }) => {
            if (!phone) {
                throw new Error('No phone number provided')
            }

            const getHasPassword = await observers.getHasPassword({
                dispatch,
                payload: {
                    phone,
                    publisherId,
                },
            })

            const { isNewRegistration, hasPassword } = getHasPassword || {}

            if (isReturningUser && isNewRegistration) {
                const errorMsg = 'Account not found. Please sign up instead.'
                setErrorMessage({
                    ...errorMessage,
                    phone: errorMsg,
                })
                return
            }

            if (!isReturningUser && !isNewRegistration) {
                const errorMsg = `An account with this phone number already exists.${isLoggedInUser ? '' : ' Please log in instead.'}`
                setErrorMessage({
                    ...errorMessage,
                    phone: errorMsg,
                })
                return
            }

            const lpUtm = LandingPage.selectors.getUTM()

            if (userPhone && userPhone !== phone) {
                setUserPhoneMismatchError('Please use the phone number that is linked to your Hype account.')
                return
            }

            // Todo: (Ert) This is legacy code stays here from consolidated signup block which is not exist anymore

            // if (hasPassword) {
            //     localStorage.setItem('isNewRegistration', false)

            //     dispatch(
            //         Account.creators.setLoginPhone({
            //             phone,
            //         })
            //     )

            //     return {
            //         phone,
            //         hasPassword: true,
            //     }
            // }

            try {
                await observers.requestOneTimeCode({
                    dispatch,
                    payload: {
                        phone,
                        publisherId,
                        redirectUrl,
                        origin,
                        popupId,
                        utm: lpUtm,
                    },
                })

                setUserPhoneMismatchError(null)
            } catch (error) {
                logger.error('requestOneTimeCode', {}, error)
                if (error?.message) {
                    throw error
                } else {
                    throw new Error('an error has occurred')
                }
            }
        },
        [dispatch, errorMessage, isLoggedInUser, isReturningUser, origin, popupId, publisherId, redirectUrl, setErrorMessage, userPhone]
    )

    const resendOneTimeCode = useCallback(
        async ({ email }) => {
            const lpUtm = LandingPage.selectors.getUTM()

            if (!email) {
                throw new Error('no email provided')
            }

            try {
                await observers.requestOneTimeCode({
                    dispatch,
                    payload: {
                        email,
                        publisherId,
                        redirectUrl,
                        origin,
                        popupId,
                        utm: lpUtm,
                    },
                })
            } catch (error) {
                logger.error('requestOneTimeCode', {}, error)
                if (error?.message) {
                    throw error
                } else {
                    throw new Error('an error has occurred')
                }
            }

            return {}
        },
        [dispatch, origin, popupId, publisherId, redirectUrl],
    )

    const confirmCode = useCallback(
        async ({
            code,
            email,
            phone,
            customProperties,
            selectedNewsletters,
        }) => {
            if (!code) {
                throw new Error('No code provided')
            }

            const lpUtm = LandingPage.selectors.getUTM()

            try {
                const confirmOneTimeCode = await observers.confirmOneTimeCode({
                    dispatch,
                    payload: {
                        code,
                        email,
                        phone,
                        origin,
                        popupId,
                        publisherId,
                        redirectUrl,
                        utm: lpUtm,
                        customProperties,
                        newsletters: selectedNewsletters,
                    },
                })

                const authType = email ? 'email' : 'sms'

                // If we have a claim, get tokens
                if (confirmOneTimeCode.claim && !isLoggedInUser) {
                    if (email || phone) {
                        if (confirmOneTimeCode?.isNewRegistration) {
                            onAccountCreated(authType)
                        } else {
                            onLogin(authType)

                        }
                    }

                    const response = await observers.exchangeClaim({
                        dispatch,
                        payload: {
                            claim: confirmOneTimeCode.claim,
                            scope: 'user',
                            publisherId,
                        },
                    })

                    dispatch(
                        Auth.creators.tokenLogin({
                            accessToken: response?.tokens?.token,
                            refreshToken: response?.tokens?.refresh_token,
                        }),
                    )

                    if (confirmOneTimeCode?.isNewRegistration) {
                        await observers.updateUserProperties({
                            dispatch,
                            payload: {
                                publisherId,
                                customProperties: values.customFields,
                            }
                        })

                        await observers.updateUserNewsletters({
                            dispatch,
                            payload: {
                                publisherId,
                                newsletters: values.newsletters,
                            }
                        })
                    }
                }

                if (confirmOneTimeCode.claim) {
                    dispatch(
                        Me.creators.getCurrentUser({
                            payload: {
                                publisherId,
                            },
                        }),
                    )

                    resetOneTimeCode()

                    if (!isLoggedInUser) {
                        dispatch(goToMenuRouteAndOpen({
                            menuRoute: '/notification',
                            text: thankYouText,
                            backRoute: '/',
                        }))
                    }
                }
            } catch (error) {
                logger.error('confirmOneTimeCode', {}, error)
                if (error?.message) {
                    throw new Error(error?.message)
                } else {
                    throw new Error('an error has occurred')
                }
            }
        },
        [dispatch, isLoggedInUser, onAccountCreated, onLogin, origin, popupId, publisherId, redirectUrl, resetOneTimeCode, thankYouText, values.customFields, values.newsletters],
    )

    return (
        <Signup
            title={title}
            subtitle={subtitle}
            fieldContainerProps={{
                label,
                required,
            }}
            defaultType={defaultType}
            setFlow={setFlow}
            name={name}
            email={enteredEmail}
            userId={userId}
            access_token={access_token}
            isNewRegistration={isNewRegistration}
            isReturningUser={isReturningUser}
            isPaymentLandingPage={isPaymentLandingPage}
            markDown={markDown}
            utm={utm}
            authenticationMethods={authenticationMethods}
            newsletterFilter={newsletterFilter}
            newsletters={newsletters}
            customFieldsValid={customFieldsValid}
            description={description}
            required={required}
            linkColor={linkColor}
            contrastColor={contrastColor}
            popupId={popupId}
            oneTimeCodeSent={oneTimeCodeSent}
            oneTimeCodeError={oneTimeCodeError}
            origin={origin}
            publisherId={publisherId}
            resetOneTimeCode={resetOneTimeCode}
            resendOneTimeCode={resendOneTimeCode}
            username={clientName}
            showError={showError}
            showTerms={showTerms}
            errorMessage={errorMessage}
            phoneNumber={phoneNumber}
            updateAccountError={updateAccountError}
            onSkipOtp={onSkipOtp}
            customFields={customFields}
            onSocialLogin={async (response = {}, meta = {}) => {
                if (!isReturningUser) {
                    localStorage.setItem('isNewRegistration', true)
                    onAccountCreated(meta.social_type)
                } else {
                    localStorage.setItem('isNewRegistration', false)
                    onLogin(meta.social_type)
                }

                if (response.claim) {
                    const { tokens } = {} = await observers.exchangeClaim({
                        dispatch,
                        payload: {
                            claim: response.claim,
                            publisherId,
                            scope: 'user',
                        },
                    })

                    dispatch(
                        Auth.creators.tokenLogin({
                            accessToken: tokens?.token,
                            refreshToken: tokens?.refresh_token,
                        }),
                    )
                } else {
                    dispatch(
                        Auth.creators.socialLogin({
                            accessToken: response.token,
                            refreshToken: response.refresh_token,
                        }),
                    )
                }

                dispatch(
                    Me.creators.getCurrentUser({
                        payload: {
                            publisherId,
                        },
                    }),
                )

                if (!isLoggedInUser) {
                    dispatch(goToMenuRouteAndOpen({
                        menuRoute: '/notification',
                        text: thankYouText,
                        backRoute: '/',
                    }))
                }
            }}
            checkEmail={checkEmail}
            confirmCode={confirmCode}
            onSubmitCallback={onSubmitCallback}
            userPhoneMismatchError={userPhoneMismatchError}
            isAuthed={isAuthed}
            loginRedirect={loginRedirect}
            loginPhone={loginPhone}
            checkPhone={checkPhone}
            userEmail={userEmail}
            userPhone={userPhone}
            manageAccount={() => dispatch(
                goToMenuRouteAndOpen({
                    menuRoute: '/',
                    back: '/',
                })
            )}
        >
            {children}
        </Signup >
    )
}

SignupWrapper.defaultProps = {
    authenticationMethods: [],
    children: null,
    customFieldsValid: () => true,
    description: '',
    errorMessage: null,
    isPaymentLandingPage: false,
    isReturningUser: false,
    label: '',
    name: '',
    newsletterFilter: '',
    newsletters: [],
    onAccountCreated: () => { },
    onSkipOtp: () => { },
    onLogin: () => { },
    onSubmitCallback: () => { },
    popupId: '',
    required: false,
    showError: false,
    showTerms: false,
    subtitle: '',
    title: '',
    onPasswordLogin: () => { },
}

SignupWrapper.propTypes = {
    authenticationMethods: PropTypes.array,
    children: PropTypes.node,
    customFieldsValid: PropTypes.func,
    description: PropTypes.string,
    errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isPaymentLandingPage: PropTypes.bool,
    isReturningUser: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    newsletterFilter: PropTypes.string,
    newsletters: PropTypes.array,
    onAccountCreated: PropTypes.func,
    onLogin: PropTypes.func,
    onSkipOtp: PropTypes.func,
    onSubmitCallback: PropTypes.func,
    popupId: PropTypes.string,
    required: PropTypes.bool,
    showError: PropTypes.bool,
    showTerms: PropTypes.bool,
    subtitle: PropTypes.string,
    title: PropTypes.string,
    onPasswordLogin: PropTypes.func,
}

export default React.memo(SignupWrapper)
