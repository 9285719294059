import React from 'react'
import Newsletters from 'piconetworks/pkg-field-newsletters'
import styles from '../scss/Newsletters.module.scss'

// type NewsletterType = 'free' | 'paid'

// type Newsletter = {
//     created_at: string
//     description: string
//     updated_at: string
//     enabled: boolean
//     id: string
//     integration_label: string
//     name: string
//     newsletter_order: number
//     type: NewsletterType
// }

// interface NewslettersBlockProps {
//     linkColor: string
//     previewMode: boolean
//     newsletters: Array<Newsletter>
// }

class ErrorBoundary extends React.Component {

    static getDerivedStateFromError(error) {
        console.log('getDerivedStateFromError', error)
        return { hasError: true }
    }

    constructor(props){
        super(props)
        this.state = { hasError: false }
    }

    componentDidCatch(error, errorInfo) {
        console.error(error.message, errorInfo)
    }

    render() {
        const { hasError } = this.state
        const { children, RenderError = () => (<div className='errorBoundaree'></div>) } = this.props

        if (hasError) {
            return (
                <RenderError />
            )
        }

        return children
    }
}

const NewslettersBlock = ({
    linkColor,
    newsletters,
    previewMode
}) => {
    const selectedNewsletters = newsletters.reduce((acc, newsletter) => {
        if (newsletter.checked) {
            acc.push(newsletter.id)
        }
        return acc
    }, [])

    return (
        <ErrorBoundary>
            <div className={styles.container}>
                <label className={styles.label}>Newsletters</label>
                <Newsletters
                    showMore
                    name={'newsletters'}
                    linkColor={linkColor}
                    newsletterFilter={'preselected'}
                    newsletters={newsletters}
                    previewMode={previewMode}
                    defaultValue={selectedNewsletters}
                />
            </div>
        </ErrorBoundary>
    )
}

NewslettersBlock.displayName = 'FieldSignupNewslettersBlock'

export default NewslettersBlock
