import React, { useEffect, useState } from 'react'
import { Field } from 'react-final-form'
import classNames from 'classnames'

import { format, normalize, symbol as currencySymbol } from 'piconetworks/pkg-currency'
import Form from 'piconetworks/pkg-form'
import { H3 } from 'piconetworks/pkg-heading'

import style from './scss/SelectDonation.module.scss'

import { RemoteJsonText } from 'piconetworks/pkg-remote-markdown'

const SelectDonation = (props) => {
    const {
        fieldContainerProps,
        required,
        popup,
        name,
        linkColor = '',
        contrastColor = '',
        product = {},
        setProductOptionId,
        setCustomPrice,
        className,
        currentPurchaseOptionId,
        customFeaturesUrl = '',
        isGroupMember,
        currentSubscriptionType,
        isSwitch,
        contributionLanguage = 'tip',
        ...rest
    } = props
    const [monthlyDonationCustomPrice, setMonthlyDonationCustomPrice] = useState(0)
    const [yearlyDonationCustomPrice, setYearlyDonationCustomPrice] = useState(0)
    const [singleDonationCustomPrice, setSingleDonationCustomPrice] = useState(0)
    const [toggleState, changeToggleState] = useState(null)

    const [monthlyDonationChoice, setMonthlyDonationChoice] = useState(null)
    const [yearlyDonationChoice, setYearlyDonationChoice] = useState(null)
    const [singleDonationChoice, setSingleDonationChoice] = useState(null)

    const [sortedMonthlyDonations, setSortedMonthlyDonations] = useState([])
    const [sortedYearlyDonations, setSortedYearlyDonations] = useState([])
    const [sortedSingleDonations, setSortedSingleDonations] = useState([])

    // people already on a current subscription can only make one time donations
    const isCurrentSubscriber = currentSubscriptionType && currentSubscriptionType === 'subscription'

    const onlyShowOneTimeDonations = isGroupMember || isCurrentSubscriber

    useEffect(() => {
        getChoices()
    }, [product])

    const title = product?.title

    const getChoices = () => {
        const mappedProducts = product?.options
            ?.reduce((productResults, {
                id,
                currency: currencyCode,
                price: amount,
                type,
                default_option: defaultOption,
                interval,
            }) => {
                const donationFrequency = interval || type

                if (!productResults[donationFrequency]) {
                    productResults[donationFrequency] = []
                }

                const price = amount && format({
                    currencyCode,
                    amount,
                    symbol: true,
                })

                // Check to see if an option with a null price already exists in productResults[type].
                const optionHasNullPrice = (option) => option.price === null
                const addNullOption =
                    price === null && !productResults[donationFrequency].some(optionHasNullPrice)

                // If option price is not null and productResults does not already have an option with a
                // null price value, add to productResults. This prevents display of multiple Option fields.
                if (price !== null || addNullOption) {
                    productResults[donationFrequency].push({
                        amount,
                        price,
                        id,
                        defaultOption
                    })
                }

                return productResults
            }, {})

        const sortedSingleDonations = mappedProducts?.['single-donation']?.sort(({ amount: a }, { amount: b }) => a - b) || []
        const hasSingleDonations = sortedSingleDonations.length > 0
        hasSingleDonations && sortedSingleDonations.push(sortedSingleDonations.shift())

        const sortedMonthlyDonations = mappedProducts?.['month']?.sort(({ amount: a }, { amount: b }) => a - b) || []
        const hasMonthlyDonations = sortedMonthlyDonations.length > 0
        hasMonthlyDonations && sortedMonthlyDonations.push(sortedMonthlyDonations.shift())

        const sortedYearlyDonations = mappedProducts?.['year']?.sort(({ amount: a }, { amount: b }) => a - b) || []
        const hasYearlyDonations = sortedYearlyDonations.length > 0
        hasYearlyDonations && sortedYearlyDonations.push(sortedYearlyDonations.shift())

        const defaultOptionMonthlyDonations = sortedMonthlyDonations.filter(donation => donation.defaultOption)
        const defaultOptionYearlyDonations = sortedYearlyDonations.filter(donation => donation.defaultOption)
        const defaultOptionSingleDonation = sortedSingleDonations.filter(donation => donation.defaultOption)

        setSortedMonthlyDonations(sortedMonthlyDonations)
        setSortedYearlyDonations(sortedYearlyDonations)
        setSortedSingleDonations(sortedSingleDonations)

        changeToggleState(
            onlyShowOneTimeDonations
                ? 'single-donation'
                : defaultOptionMonthlyDonations.length
                    ? 'month'
                    : defaultOptionYearlyDonations.length
                        ? 'year'
                        : 'single-donation'
        )

        setMonthlyDonationChoice(
            (defaultOptionMonthlyDonations?.length
                ? defaultOptionMonthlyDonations
                : sortedMonthlyDonations
            )[0]?.id
        )
        setYearlyDonationChoice(
            (defaultOptionYearlyDonations?.length
                ? defaultOptionYearlyDonations
                : sortedYearlyDonations
            )[0]?.id
        )
        setSingleDonationChoice(
            (defaultOptionSingleDonation?.length
                ? defaultOptionSingleDonation
                : sortedSingleDonations
            )[0]?.id
        )
    }

    const intendedChoice = (() => {
        let selectedChoice = null
        let selectedCustomPrice = null
        let donationType = null

        if (toggleState === 'single-donation') {
            selectedChoice = singleDonationChoice
            selectedCustomPrice = singleDonationCustomPrice
            donationType = 'single-donation'
        } else if (toggleState === 'month') {
            selectedChoice = monthlyDonationChoice
            selectedCustomPrice = monthlyDonationCustomPrice
            donationType = 'month'
        } else if (toggleState === 'year') {
            selectedChoice = yearlyDonationChoice
            selectedCustomPrice = yearlyDonationCustomPrice
            donationType = 'year'
        }

        if (!selectedChoice) {
            return {}
        }

        const chosenOption = product?.options?.find(({ id }) => selectedChoice === id)

        if (!chosenOption) {
            return {}
        }

        const id = chosenOption.id
        const defaultOptionAmount = chosenOption.price
        const currencyCode = chosenOption?.currency
        const amount = chosenOption.price || selectedCustomPrice || 0

        const symbol = currencySymbol(currencyCode)
        const price = selectedCustomPrice || format({
            currencyCode,
            amount,
            symbol: false,
        })

        return {
            id,
            amount,
            amountFormatted: format({
                currencyCode,
                amount,
                symbol: true,
            }),
            price: `${symbol}${price}`,
            symbol,
            donationType,
            currencyCode,
            defaultOptionAmount,
        }
    })()


    const mapDonations = ({
        donationsArray = [],
        donationChoice = null,
        setDonationChoice = () => { },
        donationName = '',
        changeCustomPrice = () => { },
    }) => {
        const filteredDonationsArray = donationsArray.filter(({ id, price }) =>
            id !== currentPurchaseOptionId || !price
        )
        return (
            <div className={classNames(style.radioList, { [style[`item-${filteredDonationsArray?.length - 1}`]]: true })}>

                {filteredDonationsArray.map(({ id, price } = {}) =>
                    (onlyShowOneTimeDonations && price)
                        ? null
                        : (
                            <div
                                onClick={() => {
                                    setDonationChoice(id)
                                }}
                                key={id}
                                className={classNames(
                                    style.donationOption,
                                    {
                                        [style.customDonationOption]: !price,
                                        [style.active]: id === donationChoice
                                    }
                                )}
                            >
                                <label>
                                    {price ? <span>{price}</span> :
                                        <Form.FormatInput
                                        label=""
                                        inputPrefix="$"
                                        customPrice="true"
                                        symbol={false}
                                        className={style.customInput}
                                        input={{
                                                placeholder: '0.00',
                                                min: 0,
                                                name: 'other',
                                                onChange: (value) => {
                                                    if (typeof value === 'object') return false

                                                    const customPriceNormalized = normalize({
                                                        currencyCode: intendedChoice.currencyCode,
                                                        amount: value.split(',').join('')
                                                    })

                                                    changeCustomPrice(customPriceNormalized)
                                                }
                                            }}
                                        />
                                    }
                                </label>
                            </div>
                        ))}
                {isSwitch && donationName === 'single-donation' && (
                    <small className={style.donationWarning}>Please note you are making an additional one-time donation.</small>
                )}
            </div>
        )
    }

    const resetCustomPrice = () => {
        setMonthlyDonationCustomPrice(null)
        setYearlyDonationCustomPrice(null)
        setSingleDonationCustomPrice(null)
    }

    return (
        <Form.Field {...fieldContainerProps}>
            <div className={classNames(style.container, className)}>
                <div className={style.dflex}>
                    <div className={style.col6}>
                        {title && <H3 className={style.mb0}>{title}</H3>}
                        <div className={style.features}>
                            {customFeaturesUrl && <RemoteJsonText url={customFeaturesUrl} style={style} linkColor={linkColor} />}
                        </div>
                    </div>
                    <div className={style.col6}>
                        <div className={style.switch}>
                            {sortedMonthlyDonations.length && !onlyShowOneTimeDonations ? (
                                <div
                                    className={classNames(
                                        style.switchItem,
                                        {
                                            [style.active]:
                                                toggleState ===
                                                'month'
                                        }
                                    )}
                                    onClick={() => {
                                        resetCustomPrice()
                                        changeToggleState('month')
                                    }}
                                >
                                    Monthly
                                </div>
                            ) : null}
                            {sortedYearlyDonations.length && !onlyShowOneTimeDonations ? (
                                <div
                                    className={classNames(
                                        style.switchItem,
                                        {
                                            [style.active]:
                                                toggleState ===
                                                'year'
                                        }
                                    )}
                                    onClick={() => {
                                        resetCustomPrice()
                                        changeToggleState('year')
                                    }}
                                >
                                    Yearly
                                </div>
                            ) : null}
                            {sortedSingleDonations.length ? (
                                <div
                                    className={classNames(
                                        style.switchItem,
                                        {
                                            [style.active]:
                                                toggleState ===
                                                'single-donation' || onlyShowOneTimeDonations
                                        }
                                    )}
                                    onClick={() => {
                                        resetCustomPrice()
                                        changeToggleState('single-donation')
                                    }}
                                >
                                    One-Time
                                </div>
                            ) : null}
                        </div>
                        {toggleState === 'month' && (
                            mapDonations({
                                donationsArray: sortedMonthlyDonations,
                                donationChoice: monthlyDonationChoice,
                                setDonationChoice: setMonthlyDonationChoice,
                                donationName: 'month',
                                changeCustomPrice: setMonthlyDonationCustomPrice,
                            })
                        )}
                        {toggleState === 'year' && (
                            mapDonations({
                                donationsArray: sortedYearlyDonations,
                                donationChoice: yearlyDonationChoice,
                                setDonationChoice: setYearlyDonationChoice,
                                donationName: 'year',
                                changeCustomPrice: setYearlyDonationCustomPrice,
                            })
                        )}
                        {toggleState === 'single-donation' && (
                            mapDonations({
                                donationsArray: sortedSingleDonations,
                                donationChoice: singleDonationChoice,
                                setDonationChoice: setSingleDonationChoice,
                                donationName: 'single-donation',
                                changeCustomPrice: setSingleDonationCustomPrice,
                            })
                        )}
                        <Field
                            name={'submit'}
                            component={(props) => (
                                <button
                                    type='submit'
                                    {...props}
                                    disabled={!intendedChoice.amount}
                                    className={classNames(
                                        'btn',
                                        style.btn,
                                    )}
                                >
                                    {contributionLanguage === 'tip' ? 'Tip' : 'Donate'}
                                </button>
                            )}
                            type="submit"
                            value="test"
                            onClick={(e) => {
                                e.preventDefault()

                                const productOptionId = intendedChoice.id
                                const customPriceNormalized = intendedChoice.amount
                                const defaultOptionAmount = intendedChoice.defaultOptionAmount

                                const submissionPayload = {}

                                if (productOptionId) {
                                    submissionPayload.productOptionId = productOptionId
                                }

                                if (!defaultOptionAmount && customPriceNormalized) {
                                    submissionPayload.customPrice = customPriceNormalized
                                }

                                if (submissionPayload.customPrice) {
                                    setCustomPrice(submissionPayload.customPrice)
                                } else {
                                    setCustomPrice(null)
                                }

                                if (submissionPayload.productOptionId) {
                                    setProductOptionId(submissionPayload.productOptionId)
                                }
                            }}
                            {...rest}
                        />
                    </div>
                </div>
            </div>
        </Form.Field>
    )
}

export default SelectDonation
