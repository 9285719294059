import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

import Form from 'piconetworks/pkg-form'
import SocialLinks from 'piconetworks/pkg-social-links'

import styles from './scss/SocialLink.module.scss'

const FieldSocialLinks = ({
    fieldContainerProps,
    ...rest
}) => (
    <Form.Field
        {...fieldContainerProps}
        className={styles.container}
    >
        <div className={styles.container_content}>
            <Field
                component={SocialLinks}
                {...rest}
            />
        </div>
    </Form.Field>
)

FieldSocialLinks.propTypes = {
    links: PropTypes.array,
    fieldContainerProps: PropTypes.shape({
        label: PropTypes.string,
        required: PropTypes.bool
    }),
}

export default FieldSocialLinks
