import React, { useRef, useState } from 'react'
import classNames from 'classnames'

import PlaidDecorator from 'piconetworks/pkg-plaid-decorator'
import Form from 'piconetworks/pkg-form'
import { H3 } from 'piconetworks/pkg-heading'
import CardBrandImage from 'piconetworks/pkg-card-brand-image'

import styles from './scss/Payment.module.scss'

const RadioOption = ({
    linkColor,
    name,
    value,
    children,
    onChange,
    radioGroupValue,
}) => (
    <Form.Radio
        checked={radioGroupValue === value}
        color={linkColor}
        input={{
            checked: radioGroupValue === value,
            name: name,
            onChange: (e) => {
                onChange(e)
            },
            type: 'radio',
            value: value,
        }}
        name={name}
        text={children}
        type="radio"
        value={value}
    />
)

const ExistingPaymentMethods = ({
    paymentMethods = [],
    onChange,
    radioGroupValue,
    linkColor,
    deleteCard = () => {},
}) => (
    <>
        {
            paymentMethods
                .map(({
                    name,
                    cardType,
                    id,
                    exp_month: expirationMonth,
                    exp_year: expirationYear,
                    last4,
                }) => (
                    <div key={id} className={styles.savedPayment}>
                        <RadioOption
                            key={id}
                            linkColor={linkColor}
                            name={name}
                            value={id}
                            onChange={onChange}
                            radioGroupValue={radioGroupValue}
                        >
                            <div className={styles.cardDetails}>
                                <CardBrandImage brand={cardType ? cardType.toLowerCase().replace(/\s/g, '').trim() : ''} />
                                <div className={styles.cardDescription}>
                                    <div className={styles.cardNumber}>
                                        <div>{cardType}</div>
                                        <div><span className={styles.cardMask}>{'\u2022'}{'\u2022'}{'\u2022'}{'\u2022'}</span>{last4}</div>
                                    </div>
                                    <div className={styles.cardExpiration}>Expires {expirationMonth}/{expirationYear}</div>
                                </div>
                            </div>
                        </RadioOption>
                        <svg
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
    
                                deleteCard(id)
                            }}
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><title> icon_delete</title><g fill="none"><g fill="#FF4440"><path d="M6 18.5C6 19.6 6.9 20.5 8 20.5L16 20.5C17.1 20.5 18 19.6 18 18.5L18 9.5 6 9.5 6 18.5ZM17 4.5L15.5 4.5 15.5 4.5 15.1 4.1C14.7 3.7 14.2 3.5 13.7 3.5L10.3 3.5C9.8 3.5 9.3 3.7 8.9 4.1L8.5 4.5 8.5 4.5 7 4.5C5.9 4.5 5 5.4 5 6.5L5 7.5 5 7.5 19 7.5 19 6.5C19 5.4 18.1 4.5 17 4.5Z"/></g></g></svg>
                    </div>

                ))
        }
    </>
)

const AddNewBankAccount = ({
    PlaidLink,
    onChange,
    style,
    radioGroupValue,
}) => (
    <RadioOption
        name={'paymentMethods'}
        value={'add_bank_account'}
        onChange={onChange}
        style={style}
        radioGroupValue={radioGroupValue}
    >
        <PlaidLink>
            Add new bank account
        </PlaidLink>
    </RadioOption>
)

const AddNewCard = ({
    CardElement,
    linkColor,
    onChange,
    style,
    radioGroupValue,
    value = 'add_card',
    onComplete = () => {},
    onError = () => {},
    onIncomplete = () => {},
}) => {
    const showStripeElement = radioGroupValue === value

    const cardRef = useRef(null)

    function changeBorderColor(ref, linkColor) {
        const el = ref.current
        if (!el) { return }
        el.style.borderColor = linkColor ? linkColor : '#dddddd'
    }

    const inputItemOptions = {
        style: {
            base: {
                color: '#444444',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#DDDCE0',
                },
            },
            invalid: {
                color: '#FF5E5B',
                iconColor: '#FF5E5B',
            },
        },
    }

    return (
        <RadioOption
            name={'paymentMethods'}
            value={value}
            onChange={onChange}
            style={style}
            radioGroupValue={radioGroupValue}
            linkColor={linkColor}
        >
            Add new card
            {showStripeElement && (
                <div className={styles.paymentFormArea}>
                    <div className={classNames(styles.groupContainer)}>
                        <div className={styles.inputItem}>
                            <div ref={cardRef} className={styles.inputContainer}>
                                <CardElement
                                    onReady={(e) => {
                                        e.focus()
                                        onIncomplete()
                                    }}
                                    onChange={(event) => {
                                        if (event.complete) {
                                            onComplete()
                                        } else if (event.error) {
                                            onError(event.error)
                                        } else {
                                        }
                                    }}
                                    options={inputItemOptions}
                                    onFocus={() => changeBorderColor(cardRef, linkColor)}
                                    onBlur={() => changeBorderColor(cardRef)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </RadioOption>
    )
}

const Payment = ({
    existingPaymentMethods = [],
    changeCompletionStatus = () => {},
    isComplete,
    linkColor,
    publisherName,
    defaultPaymentMethod = false,
    CardElement = () => null,
    enableStripe = true,
    enablePlaid = false,
    sendExternalLink,
    deleteCard = () => {},
}) => {
    const [
        {
            selectedPaymentMethod,
            selectedPaymentMethodData,
        },
        selectPaymentMethod,
    ] = useState({
        selectedPaymentMethod: defaultPaymentMethod || 'add_card',
    })

    const [stripeComplete , setStripeComplete] = useState(false)

    changeCompletionStatus({
        isComplete: (
            (selectedPaymentMethod === 'add_card' && stripeComplete)
            || (selectedPaymentMethod !== 'add_card' && !!selectedPaymentMethod)
        ),
        selectedPaymentMethod,
        selectedPaymentMethodData,
    })

    const stripe_link = 'http://www.stripe.com'

    return (
        <section className={styles.paymentForm}>
            <div className={styles.paymentHeader}>
                <H3>Payment</H3>
                <a
                    onClick={(e) => {
                        e.preventDefault()
                        if (sendExternalLink) {
                            sendExternalLink(stripe_link)
                        } else {
                            window.open(stripe_link)
                        }
                    }} target="blank" href={stripe_link} className={styles.stripeLink}>
                    <svg className={styles.iconLock} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><title>icon_lock</title><g fill="none"><g fill="#28BF6F"><path d="M17.1 10C18.2 10 19 10.8 19 11.8L19 11.8 19 20.2C19 21.2 18.2 22 17.1 22L17.1 22 6.9 22C5.8 22 5 21.2 5 20.2L5 20.2 5 11.8C5 10.8 5.8 10 6.9 10L6.9 10ZM12 12.5C10.9 12.5 10 13.4 10 14.5 10 15.3 10.5 16.1 11.3 16.4L11.3 18.8C11.3 19.2 11.6 19.5 12 19.5 12.4 19.5 12.8 19.2 12.8 18.8L12.8 18.8 12.8 16.4C13.5 16.1 14 15.3 14 14.5 14 13.4 13.1 12.5 12 12.5ZM13 2C15.2 2 17 3.8 17 6L17 10 15 10 15 6C15 4.9 14.2 4.1 13.1 4L13 4 11 4C9.9 4 9 4.9 9 6L9 6 9 10 7 10 7 6C7 3.8 8.8 2 11 2L13 2Z"/></g></g></svg>
                    <span>Processed securely by </span>
                    <svg title="Stripe" icon="M28 6.02c0-1.991-.964-3.562-2.808-3.562-1.85 0-2.97 1.57-2.97 3.546 0 2.342 1.321 3.524 3.22 3.524.925 0 1.625-.21 2.154-.506V7.467c-.53.264-1.136.427-1.906.427-.754 0-1.423-.264-1.509-1.182h3.803c0-.1.016-.505.016-.692zm-3.842-.739c0-.879.536-1.244 1.026-1.244.475 0 .98.365.98 1.244zm-4.94-2.823c1.362 0 2.645 1.229 2.653 3.484 0 2.466-1.268 3.586-2.66 3.586-.684 0-1.097-.288-1.377-.49l-.007 2.2-1.945.413V2.582h1.711l.101.482a2.197 2.197 0 0 1 1.525-.606zm-.466 5.359c.716 0 1.198-.778 1.198-1.82 0-1.02-.49-1.813-1.198-1.813-.443 0-.723.156-.925.382l.007 2.885c.187.202.46.366.918.366zm-5.545-5.818V.412L15.159 0v1.579zm0 .591h1.952v6.806h-1.952zm-2.093.576c.46-.84 1.37-.67 1.618-.576v1.789c-.24-.086-1.019-.195-1.478.404v4.613H9.31V2.59h1.68zM7.226.902L7.218 2.59h1.478v1.657H7.218v2.76c0 1.152 1.229.794 1.478.693v1.579c-.257.14-.724.257-1.362.257-1.15 0-2.014-.848-2.014-2l.008-6.23zM1.968 4.566c0 .87 2.963.458 2.97 2.776 0 1.392-1.111 2.194-2.73 2.194-.668 0-1.4-.133-2.122-.444v-1.85c.653.357 1.477.621 2.123.621.435 0 .747-.116.747-.474C2.956 6.463 0 6.806 0 4.659c0-1.377 1.05-2.201 2.629-2.201a5.13 5.13 0 0 1 1.929.358v1.827a4.338 4.338 0 0 0-1.93-.497c-.411 0-.66.116-.66.42z" color="#BBB" display="inline-block" height="12px" width="28px" cursor="pointer" className="clickable" viewBox="0 0 28 12" role="img" aria-labelledby="svg-title">
                        <title id="svg-title">Stripe</title>
                        <path d="M28 6.02c0-1.991-.964-3.562-2.808-3.562-1.85 0-2.97 1.57-2.97 3.546 0 2.342 1.321 3.524 3.22 3.524.925 0 1.625-.21 2.154-.506V7.467c-.53.264-1.136.427-1.906.427-.754 0-1.423-.264-1.509-1.182h3.803c0-.1.016-.505.016-.692zm-3.842-.739c0-.879.536-1.244 1.026-1.244.475 0 .98.365.98 1.244zm-4.94-2.823c1.362 0 2.645 1.229 2.653 3.484 0 2.466-1.268 3.586-2.66 3.586-.684 0-1.097-.288-1.377-.49l-.007 2.2-1.945.413V2.582h1.711l.101.482a2.197 2.197 0 0 1 1.525-.606zm-.466 5.359c.716 0 1.198-.778 1.198-1.82 0-1.02-.49-1.813-1.198-1.813-.443 0-.723.156-.925.382l.007 2.885c.187.202.46.366.918.366zm-5.545-5.818V.412L15.159 0v1.579zm0 .591h1.952v6.806h-1.952zm-2.093.576c.46-.84 1.37-.67 1.618-.576v1.789c-.24-.086-1.019-.195-1.478.404v4.613H9.31V2.59h1.68zM7.226.902L7.218 2.59h1.478v1.657H7.218v2.76c0 1.152 1.229.794 1.478.693v1.579c-.257.14-.724.257-1.362.257-1.15 0-2.014-.848-2.014-2l.008-6.23zM1.968 4.566c0 .87 2.963.458 2.97 2.776 0 1.392-1.111 2.194-2.73 2.194-.668 0-1.4-.133-2.122-.444v-1.85c.653.357 1.477.621 2.123.621.435 0 .747-.116.747-.474C2.956 6.463 0 6.806 0 4.659c0-1.377 1.05-2.201 2.629-2.201a5.13 5.13 0 0 1 1.929.358v1.827a4.338 4.338 0 0 0-1.93-.497c-.411 0-.66.116-.66.42z" fill="#BBB" />
                    </svg>
                </a>
            </div>
            <div className={styles.paymentDetails}>
                <div className={styles.existingPaymentMethods}>
                    <ExistingPaymentMethods
                        paymentMethods={existingPaymentMethods}
                        name='paymentMethods'
                        onChange={({ target: { value }}) => {
                            selectPaymentMethod({
                                selectedPaymentMethod: selectedPaymentMethod === value ? null : value,
                            })
                        }}
                        radioGroupValue={selectedPaymentMethod}
                        linkColor={linkColor}
                        deleteCard={deleteCard}
                    />
                </div>
                {enablePlaid && <PlaidDecorator
                    env={process.env.REACT_APP_PLAID_ENV}
                    publicKey={process.env.REACT_APP_PLAID_PUBLIC_KEY}
                    clientName={publisherName}
                    onSuccess={
                        (
                            public_token,
                            { account_id } = {},
                        ) => {
                            if (!public_token || !account_id) {
                                return
                            }

                            const token = {
                                account_id,
                                public_token,
                            }

                            selectPaymentMethod({
                                selectedPaymentMethod: 'add_bank_account',
                                selectedPaymentMethodData: token,
                            })
                        }}
                >
                    <AddNewBankAccount
                        onChange={({ target: { value }}) => {
                            selectPaymentMethod({
                                selectedPaymentMethod: selectedPaymentMethod === value ? null : value,
                            })
                        }}
                        radioGroupValue={selectedPaymentMethod}
                    />
                </PlaidDecorator>}
                {enableStripe && <AddNewCard
                    style={{
                        width: '100%',
                    }}
                    linkColor={linkColor}
                    onChange={({ target: { value }}) => {
                        selectPaymentMethod({
                            selectedPaymentMethod: selectedPaymentMethod === value ? null : value,
                        })
                    }}
                    radioGroupValue={selectedPaymentMethod || 'add_card'}
                    CardElement={CardElement}
                    onComplete={() => {
                        setStripeComplete(true)
                    }}
                    onError={() => {
                        setStripeComplete(false)
                    }}
                    onIncomplete={() => {
                        setStripeComplete(false)
                    }}
                />}
                <input
                    checked={isComplete}
                    type="hidden"
                    onChange={({ target: { checked } }) => {
                        changeCompletionStatus({
                            isComplete: checked,
                            selectedPaymentMethod,
                            selectedPaymentMethodData,
                        })
                    }}
                />
            </div>
        </section>
    )
}

export default Payment
