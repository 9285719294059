import React from 'react'

export const SMSToggle = () => (
    <>
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M21.25 11.5C21.25 16.1944 17.2206 20 12.25 20C11.7192 20 11.1991 19.9566 10.6935 19.8734L6.92276 21.9337C6.58957 22.1158 6.18301 21.8747 6.18301 21.495V17.7784C4.38069 16.2241 3.25 13.9867 3.25 11.5C3.25 6.80558 7.27944 3 12.25 3C17.2206 3 21.25 6.80558 21.25 11.5ZM12.25 13C13.0784 13 13.75 12.3284 13.75 11.5C13.75 10.6716 13.0784 10 12.25 10C11.4216 10 10.75 10.6716 10.75 11.5C10.75 12.3284 11.4216 13 12.25 13ZM18.25 11.5C18.25 12.3284 17.5784 13 16.75 13C15.9216 13 15.25 12.3284 15.25 11.5C15.25 10.6716 15.9216 10 16.75 10C17.5784 10 18.25 10.6716 18.25 11.5ZM7.75 13C8.57843 13 9.25 12.3284 9.25 11.5C9.25 10.6716 8.57843 10 7.75 10C6.92157 10 6.25 10.6716 6.25 11.5C6.25 12.3284 6.92157 13 7.75 13Z" fill="#120A20"/>
        </svg>
        Phone
    </>
)

export const SMSIconToggle = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="65"
        height="64"
        fill="none"
        viewBox="0 0 65 64"
    >
        <path
            fill="#28BF6F"
            fillRule="evenodd"
            d="M50.5 31c0 9.389-8.06 17-18 17-1.062 0-2.102-.087-3.113-.253l-7.542 4.12a1 1 0 01-1.48-.877v-7.433C16.762 40.448 14.5 35.973 14.5 31c0-9.389 8.058-17 18-17 9.94 0 18 7.611 18 17zm-18 3a3 3 0 100-6 3 3 0 000 6zm12-3a3 3 0 11-6 0 3 3 0 016 0zm-21 3a3 3 0 100-6 3 3 0 000 6z"
            clipRule="evenodd"
        ></path>
        <path
            fill="#fff"
            fillRule="evenodd"
            d="M23.5 34a3 3 0 100-6 3 3 0 000 6zm12-3a3 3 0 11-6 0 3 3 0 016 0zm9 0a3 3 0 11-6 0 3 3 0 016 0z"
            clipRule="evenodd"
        ></path>
    </svg>
)

export const EmailToggle = () => (
    <>
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.25 6.75C7.04086 6.75 5.25 8.54086 5.25 10.75V14C5.25 16.2091 7.04086 18 9.25 18H16.25C18.4591 18 20.25 16.2091 20.25 14V10.75C20.25 8.54086 18.4591 6.75 16.25 6.75H9.25ZM6.98498 9.16155C6.88545 9.09312 6.75 9.16438 6.75 9.28515V14.5C6.75 15.6046 7.64543 16.5 8.75 16.5H16.75C17.8546 16.5 18.75 15.6046 18.75 14.5V9.28515C18.75 9.16438 18.6145 9.09312 18.515 9.16155L13.8831 12.346C13.2006 12.8152 12.2994 12.8152 11.6169 12.346L6.98498 9.16155Z" fill="#120A20"/>
        </svg>
        Email
    </>
)

export const EmailIconToggle = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="41"
        height="38"
        fill="none"
        viewBox="0 0 41 38"
    >
        <path
            fill="#7EC2FA"
            fillRule="evenodd"
            d="M.5 15.2L18.017 1.332a4 4 0 014.965 0L40.5 15.2V34a4 4 0 01-4 4h-32a4 4 0 01-4-4V15.2z"
            clipRule="evenodd"
        ></path>
        <path
            fill="#fff"
            fillRule="evenodd"
            d="M8.306 4.433a2 2 0 00-2 2v13.364l11.71 9.27a4 4 0 004.966 0l11.711-9.27V6.433a2 2 0 00-2-2H8.306z"
            clipRule="evenodd"
        ></path>
        <path
            fill="#7EC2FA"
            fillRule="evenodd"
            d="M10.153 8.233a1.267 1.267 0 100 2.534h20.693a1.267 1.267 0 100-2.534H10.153zm0 5.067a1.267 1.267 0 100 2.533h20.693a1.267 1.267 0 100-2.533H10.153zm-1.266 6.333c0-.7.567-1.266 1.266-1.266h20.693a1.267 1.267 0 110 2.533H10.153c-.7 0-1.266-.567-1.266-1.267zm11.613 11.4L17.3 28.5h6.4l-3.2 2.533zm6.4-5.066H14.1l-3.2-2.534h19.2l-3.2 2.534z"
            clipRule="evenodd"
        ></path>
        <path
            fill="#2899F7"
            fillRule="evenodd"
            d="M.5 15.2l20 15.833 20-15.833V34a4 4 0 01-4 4h-32a4 4 0 01-4-4V15.2z"
            clipRule="evenodd"
        ></path>
        <path
            fill="#53ADF8"
            fillRule="evenodd"
            d="M40.5 15.2V34a4 4 0 01-4 4H11.708l28.79-22.8z"
            clipRule="evenodd"
        ></path>
    </svg>
)

