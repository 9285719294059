import React from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'

import Form from 'piconetworks/pkg-form'

const Boolean = ({
    fieldContainerProps,
    description = null,
    required,
    previewMode = false,
    input,
    ...rest
} = {}) => {

    return (
        <Form.Field {...fieldContainerProps}>
            <p>{description}</p>
            <Field
                component={Form.Switch}
                type="text"
                {...(required && {
                    validate: (value) => (value ? undefined : 'Required')
                })}
                {...rest}
            />
        </Form.Field>
    )
}

Boolean.propTypes = {
    fieldContainerProps: PropTypes.any,
    description: PropTypes.string,
    required: PropTypes.bool,
    previewMode: PropTypes.bool,
    input: PropTypes.any
}

export default Boolean
