import React, { useState } from 'react'
import { Field, useForm } from 'react-final-form'

import Form from 'piconetworks/pkg-form'

import countries from './countries'

const Address = ({
    name,
    fieldContainerProps,
    description = null,
    required,
    defaultValue,
    ...rest
} = {}) => {
    const form = useForm()
    const [country, changeCountry] = useState(null)

    const countryData = countries[country]
    const AddressFields = countryData?.Component || (() => null)

    const fieldName = `${name}.country`

    return (
        <Form.Field
            {...fieldContainerProps}
        >
            <p>{description}</p>
            <Form.Field
                label="Country"
                required={required}
            >
                <Field
                    name={fieldName}
                    component={(props) => (
                        <Form.Dropdown
                            {...props}
                            input={{
                                value: country,
                                onChange: (val) => {
                                    changeCountry(val)
                                    form.change(fieldName, val)
                                },
                                onBlur: () => {},
                            }}
                        />
                    )}
                    value={country}
                    type="text"
                    {...(required && {
                        validate: value => (value ? undefined : 'Required')
                    })}
                    {...rest}
                    options={
                        Object.entries(countries)
                            .filter(([key, { name, Component }]) => (
                                !!name
                                && !!Component
                            ))
                            .map(([key, { name }]) => ({
                                label: name,
                                value: key,
                            }))
                    }
                />
            </Form.Field>
            <AddressFields
                name={name}
                required={required}
                {...rest}
            />
        </Form.Field>
    )
}

export default Address
