import React from 'react'
import PropTypes from 'prop-types'
import { H3, H4 } from 'piconetworks/pkg-heading'
import styles from './scss/Summary.module.scss'
import Label from './lib/Label'
import classNames from 'classnames'

const DonationExistingSwitch = (props) => {
    const {
        labels,
        oldOptionPrice,
        optionInterval,
        optionPrice,
        optionTypeAndInterval,
        productTitle,
        prorateAmount,
        renewalDate,
        switchNow: {
            canSwitchPlanNow,
            switchNowStatus,
            changeSwitchNowStatus = () => { }
        } = {},
    } = props || {}

    return (
        <>
            {canSwitchPlanNow && (
                <div
                    className={classNames(styles.switch, {
                        [styles.true]: switchNowStatus
                    })}
                >
                    <label
                        className={classNames(styles.input, {
                            [styles.active]: switchNowStatus
                        })}
                        htmlFor="now"
                    >
                        <input
                            checked={switchNowStatus}
                            onChange={() =>
                                changeSwitchNowStatus(true)
                            }
                            type="radio"
                            name="switchNow"
                            id="now"
                            value="now"
                        />
                        <span>Switch Now</span>
                    </label>
                    <label
                        className={classNames(styles.input, {
                            [styles.active]: !switchNowStatus
                        })}
                        htmlFor="later"
                    >
                        <input
                            checked={!switchNowStatus}
                            onChange={() =>
                                changeSwitchNowStatus(false)
                            }
                            type="radio"
                            name="switchLater"
                            id="later"
                            value="later"
                        />
                        <span>Switch Later</span>
                    </label>
                </div>
            )}
            {!!labels?.length && (
                <div className={styles.labels}>
                    {labels.map((label, i) => (
                        <Label key={i} {...label} />
                    ))}
                </div>
            )}

            <div className={styles.summary}>
                <div>
                    <div className={styles.productDetails}>
                        <H3>{productTitle}</H3>
                        <H4>{optionTypeAndInterval}</H4>
                        <p>Renews {renewalDate}</p>
                    </div>

                    <div className={styles.priceDetails}>
                        <H3>
                            {oldOptionPrice && (
                                <s>{oldOptionPrice}</s>
                            )}
                            {optionPrice}
                        </H3>
                        {optionInterval && (
                            <p>per {optionInterval}</p>
                        )}
                        {prorateAmount && switchNowStatus && (
                            <>
                                <H3 className={styles.proratedPrice}>{prorateAmount}</H3>
                                <p>due today</p>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

DonationExistingSwitch.propTypes = {
    labels: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            color: PropTypes.oneOf(['gray', 'green'])
        })
    ),
    oldOptionPrice: PropTypes.string,
    optionInterval: PropTypes.string,
    optionPrice: PropTypes.string,
    optionTypeAndInterval: PropTypes.string,
    productTitle: PropTypes.string,
    prorateAmount: PropTypes.string,
    renewalDate: PropTypes.string,
    switchNow: PropTypes.object,
}

export default DonationExistingSwitch
